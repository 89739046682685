import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import InputString from '../UI/Control/InputString';
import InputNumber from '../UI/Control/InputNumber';
import Card from '../UI/Card/Card';
import classes from '../TrialstatNoto.module.css'
import customStyles from '../customStyles';
import NoData from '../NoData';

const Dashboard = (props) => {


    const EDCUserID = useSelector(state => state.auth.gValue01);

    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [searchColumn, setSearchColumn] = useState(["ProtocolStudy", "ReadType", "ProcedureID"]);
    const [readsAssigned, setReadsAssigned] = useState([]);

    const columns = [
        {
            name: "ReadAssignmentID",
            selector: "ReadAssignmentID",
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Action",
            button: true,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
                'width': '7%'
            },
            cell: (row) => (
                <div>
                     <button onClick={e => props.onClickSrcOpener(row.TaskID, row.StudyParadigmCRFID, row.ReadAssignmentID)} className="btn btn-sm btn-success"> Read </button>
                </div>
            )
        },
        {
            name: "TaskID",
            selector: "TaskID",
            sortable: true,
            center: true,
            width: "7%"
        },
        {
            name: "StudyParadigmCRFID",
            selector: "StudyParadigmCRFID",
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Study",
            selector: "Study",
            sortable: true,
            center: true,
            width: "12%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Study}</div>
            )
        },
        {
            name: "Read Type",
            selector: "ReadType",
            sortable: true,
            center: true,
            width: "6%"
        },
        {
            name: "Procedure ID",
            selector: "ProcedureID",
            sortable: true,
            center: true,
            width: "9%"
        },
        {
            name: "Assigned Date",
            selector: "AssignedDate",
            sortable: true,
            width: "10%",
            center: true,
            cell: (row) => (
                <div className={classes.textWrap}>{row.AssignedDate}</div>
            )
        },
        {
            name: "Due",
            selector: "DueDate",
            sortable: true,
            width: "10%",
            center: true,
            cell: (row) => (
                <div className={classes.textWrap}>{row.DueDate}</div>
            )
        },
        {
            name: "Hours Remaining",
            selector: "HrsRemaining",
            sortable: true,
            width: "9%",
            center: true
        },
        {
            name: "Priority",
            selector: "Priority",
            center: true,
            width: "7%"
        },
        {
            name: "Disease",
            selector: "Disease",
            width: "9%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Disease}</div>
            )
        },
        {
            name: "Info",
            selector: "Info",
            center: true,
            width: "14%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Info}</div>
            )
        }
    ];

    //Search by all columns
    //function search(rows) {        
    //    if (q != 'All')
    //        return rows.filter(row =>
    //            row.ProtocolStudy.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
    //            row.ReadType.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
    //            row.ProcedureID.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
    //            row.AssignmentDate.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
    //            row.Due.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
    //            row.HoursRemaining.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
    //            row.Priority.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
    //            row.Disease.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
    //            row.Info.toLowerCase().indexOf(q.toLowerCase()) > -1)
    //    else
    //        return rows;
    //}

    //Search by specified columns 
    //function search(rows) {      
    //    if (q != '')
    //        return rows.filter((row) => searchColumn.some(
    //            (column) => row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1))
    //    else
    //        return rows;
    //}    

    const fetchReadsAssigned = () => {
        fetch('api/Site/GetLRForSiteUser?UserID=' + EDCUserID)
            .then(response => response.json())
            .then(data => {
                setReadsAssigned(data);
            });
    }

    useEffect(() => {
        //fetchReadsAssigned();
    }, []);

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != 'All')
            return rows.filter((row) => columns.some(
                (column) => row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1))
        else
            return rows;
    }
    const readTypeChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal("");
        document.getElementById("ddlDisease").selectedIndex = 0;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
        document.getElementById("ddlDisease").selectedIndex = 0;
        document.getElementById("ddlReadyType").selectedIndex = 0;
    }
    const diseaseChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal("");
        document.getElementById("ddlReadyType").selectedIndex = 0;
    }
    const conditionalRowStyles = [
        {
            when: row => row.Priority === 'Y',
            style: {
                color: 'Red'
            },
        }
    ];

    const readTypes = [
        {
            Name: "All (Default)",
            Value: "All"
        },
        {
            Name: "New",
            Value: "New"
        },
        {
            Name: "Started",
            Value: "Started"
        },
        {
            Name: "Query",
            Value: "Query"
        },
        {
            Name: "Re-Read",
            Value: "Re-Read"
        }]
    const disease = [
        {
            Name: "All (Default)",
            Value: "All"
        },
        {
            Name: "UC",
            Value: "UC"
        },
        {
            Name: "CD",
            Value: "CD"
        },
        {
            Name: "Bowel Prep",
            Value: "BowP"
        },
        {
            Name: "IBD",
            Value: "IBD"
        },
        {
            Name: "EOE",
            Value: "EOE"
        },
        {
            Name: "EOG",
            Value: "EOG"
        },
        {
            Name: "EOD",
            Value: "EOD"
        },
        {
            Name: "NASH",
            Value: "NASH"
        },
        {
            Name: "Pouchitis",
            Value: "POU"
        },
        {
            Name: "Other",
            Value: "Other"

        },

    ]
    return (
        <Card>
            <Row className={classes.gridSearchPanel}>
                <Col md={3}>
                    <div className="input-group" >
                        <lable style={{ padding: "10px" }}> Read Type:</lable>
                        <SelectList id='ddlReadyType' dataSource={readTypes} onChangeHandler={readTypeChangeHandler} style={{ width: "200px" }} />
                    </div>
                </Col>
                <Col md={3} className="form-inline" ><div className="input-group" >
                    <lable style={{ padding: "10px" }}>Disease:</lable>
                    <SelectList id='ddlDisease' dataSource={disease} onChangeHandler={diseaseChangeHandler} style={{ width: "200px" }} />
                </div>
                </Col>
                <Col md={6} className="form-inline" >
                    <div style={{ float: "right" }}>
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} style={{ width: "400px" }} placeholder="Search" />
                    </div>
                </Col>
            </Row>
            <div className="tableContainer" style={{ "border": "1px" }}>
                {search(readsAssigned).length > 0 ? <DataTable
                    columns={columns}
                    data={search(readsAssigned)}
                    className="table table-striped table-bordered table-hover"
                    conditionalRowStyles={conditionalRowStyles}
                    customStyles={customStyles}
                    striped
                    pagination
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                /> : <NoData headers={columns}></NoData>}
            </div>
        </Card>
    );
};

export default Dashboard;
