import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Row, Col } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import InputContainer from '../UI/Container/InputContainer'
import Card from '../UI/Card/Card';
import SelectList from '../UI/Control/SelectList';
import MarkUrgent from './MarkUrgent';
import TestVideo from './TestVideo';
import VariabilityRead from './VariabilityRead';
import TaskReRead from './TaskReRead';
import AssignAdjuRead from './AssignAdjuRead';
import AssignRead from './AssignRead';
import SiteQuery from './SiteQuery';
import QCComplete from './QCComplete';
import IssueWithVideo from './IssueWithVideo';
import AddNoteToTask from './AddNoteToTask';
import TaskHistory from './TaskHistory';
import Readers from './Readers';
import QueryAnswered from './QueryAnswered';
import MarkNonUrgent from './MarkNonUrgent';
import AssignReview from './AssignReview';
import Reviewers from './Reviewers';
import ClosedTask from './ClosedTask';
import QueryHeaderDetails from './QueryHeaderDetails';
import AddQueryByTaskID from './AddQueryByTaskID';
import AssignedCRTaskList from './AssignedCRTaskList';
import MoveTaskModal from './MoveTaskModal';

const TaskDetails = (props) => {
    const location = useLocation();
    const { taskId } = location.state;  
    const navigate = useNavigate();
    const [taskAuditData, setTaskAuditData] = useState([{}]);
    const [actionType, setActionType] = useState('');
    const [taskDetails, setTaskDetails] = useState({});
    const [notoEDCDetails, setNotoEDCDetails] = useState({});
    const [actionByTaskStage, setActionByTaskStage] = useState([{}]);
    const [readStage, setReadStage] = useState(0);
    const [readAssignmentID, setReadAssignmentID] = useState(0);
    const { UserPermission } = location.state;
    const { canUnlok } = location.state;
    const [edcFormNav, setEDCFormNav] = useState();
    const [stageLabel, setStageLabel] = useState();  
    const { locNav } = location.state;
    const [imgNav, setImgNav] = useState();
    const [reviewStage, setReviewStage] = useState(0);
    const [userRole, setUserRole] = useState('');
    const [isQuerySubmitted, setIsQuerySubmitted] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');
    const [reviewerNote, setReviewerNote] = useState('');
    
    //const [seed, setSeed] = useState(1);  
    let ReadByUserData = [
        {
            ReadID: 1,
            ReadHeader: "Read 1",
            AssignedReader: "Dr. John Smith",
            CRInstitution: "Ottawa Hospital",
            ReaderType: "Endo",
            DueInHours: 68,
            CurrentReadQueue: 60,
            ReadPosition: 48,
            AvgReadPerHour: 5,
            OverallProjectReadAvg: 41,
            CRProjectReadTotal: 38,
            CRProjectReadCompleted: 29,
            SystemRiskAssessment: "LOW",
            BackgroundColor: "green",
            UpcomingSchedule: [
                {
                    Date: "22-JAN-2022",
                    Hours: "5 Hours",
                    BackgroundColor: "green"
                },
                {
                    Date: "23-JAN-2022",
                    Hours: "",
                    BackgroundColor: "red"
                },
                {
                    Date: "24-JAN-2022",
                    Hours: "3 Hours",
                    BackgroundColor: "green"
                }
            ]
        }
    ];

    const [arrReadByUserData, setReadByUserData] = useState(ReadByUserData);
    const [arrReviewByUserData, setReviewByUserData] = useState(ReadByUserData);

    const CloseHandler = () => {
        // Closed task Dashboard
        if (UserPermission == "RW" || canUnlok)
            RemoveTaskLock()
        if (locNav == 'ctd') {
            navigate('/ClosedTaskDashboard');
        }
        else {
            navigate('/Dashboard');
        }
    };
    const reloadActionTypeforQC = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };
    const reloadActionTypeforEditRead = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };
    const reloadActionTypeforCreateQuery = () => {
        //var NewSeed = seed + 1;
        //setSeed(NewSeed);
        setIsQuerySubmitted(true)
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };
    const reloadActionTypeforAssRead = () => {
        fetchActions();
        setActionType('Assign Read');
        document.getElementById("ddlActionType").value = 1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforSiteQuery = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };
    const reloadActionTypeforAddNotes = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };
    const reloadActionTypeforMarkUrgent = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforQueryAnswered = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforIssueVideo = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforTestVideo = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforReRead = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforVariabilityRead = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforClosedComplete = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforMarkNonUrgent = () => {
        fetchActions();
        setActionType('--Select Action Type--');
        document.getElementById("ddlActionType").value = -1;
        fetchTaskActionLogAll();
    };

    const reloadActionTypeforAssReview = (IsAssignReviewComplete) => {
        fetchActions();
        if (IsAssignReviewComplete == "yes") {
            setActionType('--Select Action Type--');
            setReviewerNote('');
        }
        else {
            setActionType('--Select Action Type--');
            let actType = document.getElementById("ddlActionType");
            var selectedText = actType.options[actType.selectedIndex].text;
            var selectedValue = actType.options[actType.selectedIndex].value;

            document.getElementById("ddlActionType").value = selectedValue;
            setSelectedAction(selectedText);
            setActionType(selectedText);
        }
        fetchTaskActionLogAll();
    };

    const ChangeReaderHandler = () => {
        setActionType("ChangeReader")
    }

    const ChangeReviewerHandler = () => {
        setActionType("ChangeReviewer")
    }

    const onActChangeHandler = () => {
        let actType = document.getElementById("ddlActionType");
        var selectedText = actType.options[actType.selectedIndex].text;
        if (selectedText == 'Create a Query') {
            setIsQuerySubmitted(false);
        }
        setSelectedAction(selectedText);
        setActionType(selectedText);
    }

    const fetchActions = async () => {
        fetch('api/ImageService/GetActionByTaskID?taskId=' + taskId)
            .then(response => response.json())
            .then(data => {
                setActionByTaskStage(data);
            });
    }

    const fetchImgFUrl = async () => {
        const response = await fetch('/api/AppSettings/Img_APIUrl_Endo?TaskID=' + taskId);
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError);
        }
        const imgAPIUrl = await response.text();
        setImgNav(imgAPIUrl);
    };

    const fetcheCRFUrl_FrmList = () => {
        fetch('api/ImageService/EDCFormNavUrl?TaskID=' + taskId)
            .then(response => response.text())
            .then(data => {
                setEDCFormNav(data);
            });
    }
    const fetchTaskDetails = () => {
        fetch('api/ImageService/GetTicketDetails?taskId=' + taskId)
            .then(response => response.json())
            .then(data => {
                fetcheCRFUrl_FrmList();
                fetchImgFUrl();
                setTaskDetails(data);
                setStageLabel(data.StageLabel);
                setNotoEDCDetails(data.NotoEDCDetails);
                fetchActions();
            });
    }

    const fetchTicketStageByTaskID = () => {
        fetch('api/ImageService/GetTicketStageByTaskID?taskId=' + taskId)
            .then(response => response.text())
            .then(data => {
                setStageLabel(data);
            });
    }

    const fetchTaskActionLogAll = () => {
        fetch('api/ImageService/GetTaskActionLogAll?taskId=' + taskId)
            .then(response => response.json())
            .then(data => {
                let arr = new Array();
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({
                        TaskActionLogID: data[iCount].TaskActionLogID,
                        TaskID: data[iCount].TaskID,
                        TaskActionTypeID: data[iCount].TaskActionTypeID,
                        UserID: data[iCount].Username,
                        Date: data[iCount].CurrentDateTime,
                        TaskAudit: data[iCount].TaskAudit,
                        Comment: data[iCount].TaskComments
                    });
                }
                setTaskAuditData(arr);
                fetchTicketStageByTaskID();
            });
    }

    useEffect(() => {
        getUserNameAndRoleByID();
        fetchTaskDetails();   
        fetchTaskActionLogAll();
        getLockTime();
    }, []);

    const getLockTime = () => {
        fetch('api/ImageService/GetTaskLockTime?TaskID=' + taskId)
            .then(response => response.text())
            .then(data => {
                if (data != "")
                {
                    document.getElementById('btnUnlockTask').hidden = false;
                    document.getElementById('btnUnlockTask').innerHTML = 'Unlock Task-' + data;
                }
                else {
                    document.getElementById('btnUnlockTask').hidden = true;
                    document.getElementById('btnUnlockTask').innerHTML = '';
                }
            });
    }

    const OnRemoveTask = (e) => {

        fetch('api/ImageService/RemoveImageServiceTaskById?TaskID=' + taskId, {
            method: 'POST',
            headers: { 'Content-type': 'application/json' }
        }).then(r => r.json()).then(res => {            
            if (res == "success") {
                alert('Task details deleted successfully.');
                if (locNav == 'ctd') {
                    navigate('/ClosedTaskDashboard');
                }
                else {
                    navigate('/Dashboard');
                }
            }//else if (res.startsWith("error") || res == "notfound" || res == "") {}            
            else {
                alert('Task not removed, Something went wrong.');
            }
        });
    };

    const UnlockHandler = () => {
        RemoveTaskLock()
    }
    const RemoveTaskLock = () => {
        fetch('api/ImageService/RemoveTaskLock?TaskID=' + taskId, { method: "POST" })
            .then(response => response.json())
            .then(data => {
                document.getElementById('btnUnlockTask').disabled = true;
                document.getElementById('btnUnlockTask').innerHTML = "Unlocked";
            });
    }

    const getUserNameAndRoleByID = () => {
        fetch('api/Users/getUserNameAndRoleByID')
            .then(response => response.json())
            .then(data => {
                setUserRole(data.RoleLabel);
            });
    };

    return (
        <Card>
            <Row className="mb-2">
                <Col md={2}>                    
                    <Button className="btn-success" onClick={CloseHandler} style={{ "float": "left" }}> Return to Dashboard </Button>
                </Col>
                <Col md={7}>
                    {taskDetails.DeployedOnProd == 'N' && <div className="alert alert-warning" style={{ paddingTop: "7px", paddingBottom: "7px", marginBottom: "0px" }}>
                        <strong>Warning!</strong> "{taskDetails.StudyName}" is not deployed on production server!
                    </div>}
                </Col>              
                <Col md={3} style={{ "justify-content": "space-between" }}>
                    {userRole == 'Super User' && taskDetails.StageLabel == 'Closed' &&
                        <MoveTaskModal taskId={taskDetails.TaskID} subjectId={notoEDCDetails.SubjectID} siteId={notoEDCDetails.SiteName} visit={notoEDCDetails.VisitName} style={{ "float": "right", "display": "inline-block", marginLeft: "10px" }} text="Move a Task"></MoveTaskModal>}
                    {userRole == 'Management, Image Services' &&
                        <Button id="btnDeleteTask" className="btn-danger" style={{ "float": "right", "display": "inline-block", marginLeft:"10px" }} onClick={OnRemoveTask}>Delete Task</Button>}
                    {(UserPermission == "RW" || canUnlok) &&
                        <Button id="btnUnlockTask" className="btn-danger" hidden="true" style={{ "float": "right", "display": "inline-block", marginLeft: "10px" }} onClick={UnlockHandler}>Unlock Task </Button>}
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <InputContainer Expended='true' header='Ticket Details' style={{ "text-align": "center","margin-bottom":"0px" }}>
                        <div style={{ "margin-left": "12px", "margin-right": "12px", "margin-top":"0px"}}>
                        <Row>
                            <Col md={1} className={classes.cellRLBBorder}><b>Task ID</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{taskDetails.TaskID}</Col>
                                <Col md={1} className={classes.cellRightBorder}><b>Video ID</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{taskDetails.VideoID}</Col>
                            <Col md={2} className={classes.cellRightBorder}><b>Date Received</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{taskDetails.ReceivedDate}</Col>
                            <Col md={1} className={classes.cellRightBorder}><b>Type</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{taskDetails.Type}</Col>
                        </Row>
                        <Row>
                                <Col md={1} className={classes.cellRLBBorder}><b>Ticket Stage</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{stageLabel}</Col>
                            <Col md={1} className={classes.cellRightBorder}><b>QC Complete</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{taskDetails.QCComplete}</Col>
                            <Col md={2} className={classes.cellRightBorder}><b>Noto EDC Generated</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{taskDetails.NotoEDCGenerated}</Col>
                            <Col md={1} className={classes.cellRightBorder}><b>Study</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{taskDetails.StudyName}</Col>
                        </Row>
                        <Row>                           
                            <Col md={1} className={classes.cellRightBorder}><b>Protocol</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{taskDetails.Protocol}</Col>
                                <Col md={1} className={classes.cellRightBorder}><b>Sponsor</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{taskDetails.Sponsor}</Col>
                                <Col md={2} className={classes.cellRightBorder}><b>Link </b></Col>
                                <Col md={5} className={classes.cellRightBorder}>{taskDetails.Link}</Col>
                            </Row>
                        </div>
                    </InputContainer>
                    <InputContainer Expended='true' header='Noto EDC Details' style={{ "text-align": "center", "margin-bottom": "0px", "margin-top": "15px"}}>
                        <div style={{ "margin-left": "12px", "margin-right": "12px", "margin-top": "0px" }}>
                            <Row>
                                <Col md={1} className={classes.cellRLBBorder}><b>Site ID</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{notoEDCDetails.SiteName}</Col>
                                <Col md={1} className={classes.cellRLBBorder}><b>Subject ID</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{notoEDCDetails.SubjectID}</Col>
                                <Col md={2} className={classes.cellRightBorder}><b>Visit</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{notoEDCDetails.VisitName}</Col>
                                <Col md={1} className={classes.cellRLBBorder}><b>Procedure Date</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{notoEDCDetails.ProcedureDate}</Col>
                            </Row>
                            <Row>
                                <Col md={1} className={classes.cellRLBBorder}><b>Hrs Remaining</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{notoEDCDetails.HourRemaining}</Col>
                                <Col md={8} className={classes.cellRLBBorder} />
                                <Col md={1} style={{ "border-left": "1px solid #e5e5e5", "border-bottom": "1px solid #e5e5e5" }} >
                                    {(taskDetails.StageLabel == 'Read Assigned' || taskDetails.StageLabel == 'Closed' ||
                                        taskDetails.StageLabel == 'Adjudication Required' || taskDetails.StageLabel == 'Adjudication Assigned' || taskDetails.StageLabel == 'CR Query') &&
                                <a style={{ "float": "Right" }} href={edcFormNav} target="_blank"><b><i>TrialStat EDC</i></b></a>
                                    }
                                </Col>
                                <Col md={1} className={classes.cellRightBorder} style={{ "text-align": "right" }}>{
                                    <a href={imgNav} target="_blank"><b><i>Noto Image</i></b></a>
                                }</Col>
                            </Row>                       
                        </div>
                    </InputContainer>
                </Col>
            </Row>
            <QueryHeaderDetails UserPermission={UserPermission} taskId={taskId} isQuerySubmitted={isQuerySubmitted}></QueryHeaderDetails>
            {UserPermission == "RW" &&
                <Row>
                    <Col md={12}>
                        <InputContainer Expended='true' header='Action' style={{ "text-align": "center", "margin-top": "15px" }}>
                            <Row className="mt-2 mb-2">
                                <Col md={1}>
                                    Action Type:
                            </Col>
                                <Col md={4}>
                                    <SelectList id='ddlActionType' onChangeHandler={onActChangeHandler} dataSource={actionByTaskStage} width="200px" />
                                </Col>
                        </Row>
                        <Row>
                            <Col>
                                {(selectedAction == 'Assign Read' || selectedAction == 'Edit Read')
                                    && notoEDCDetails.VisitName != 'Screening' && notoEDCDetails.VisitName != 'Baseline'
                                    && <AssignedCRTaskList subjectID={notoEDCDetails.SubjectID} taskId={taskId}/>}
                            </Col>
                        </Row>
                            <Row>
                                <Col>
                                {actionType === 'Test Video' && <TestVideo taskId={taskId} relaodAction={reloadActionTypeforTestVideo} />}
                                {actionType === 'Mark Urgent' && <MarkUrgent taskId={taskId} relaodAction={reloadActionTypeforMarkUrgent} />}
                                {actionType === 'Add Note' && <AddNoteToTask taskId={taskId} relaodAction={reloadActionTypeforAddNotes} />}
                                {actionType === 'Issue with Video' && <IssueWithVideo taskId={taskId} relaodAction={reloadActionTypeforIssueVideo} />}
                                {actionType === 'QC Complete' && <QCComplete taskId={taskId} relaodAction={reloadActionTypeforQC} />}
                                {actionType === 'Site Query' && <SiteQuery taskId={taskId} relaodAction={reloadActionTypeforSiteQuery} Type={taskDetails.Type} />}
                                {actionType === 'Query Answered' && <QueryAnswered taskId={taskId} relaodAction={reloadActionTypeforQueryAnswered} Type={taskDetails.Type} />}
                                {actionType === 'Assign Read' && <AssignRead ReadByUserData={arrReadByUserData} setActionType={setActionType}
                                    onChangeReader={ChangeReaderHandler} taskId={taskId} setReadStage={setReadStage} setReadAssignmentID={setReadAssignmentID}
                                    relaodAction={reloadActionTypeforEditRead} Type={taskDetails.Type} />}
                                    {actionType === 'Edit Read' && <AssignRead ReadByUserData={arrReadByUserData} setActionType={setActionType}
                                    onChangeReader={ChangeReaderHandler} taskId={taskId} setReadStage={setReadStage} setReadAssignmentID={setReadAssignmentID} relaodAction={reloadActionTypeforEditRead} Type={taskDetails.Type} />}
                                    {actionType === 'AssAdjRead' && <AssignAdjuRead />}
                                {actionType === 'Re-Read' && <TaskReRead taskId={taskId} relaodAction={reloadActionTypeforReRead} />}
                                {actionType === 'Variability Read' && <VariabilityRead taskId={taskId} relaodAction={reloadActionTypeforVariabilityRead} />}
                                {actionType === 'AssignReader' && <Readers taskId={taskId} setActionType={setActionType} 
                                    setReadByUserData={setReadByUserData} readStage={readStage} relaodAction={reloadActionTypeforAssRead} readAssignmentID="0"/>}
                                {actionType === 'ChangeReader' && <Readers taskId={taskId} setActionType={setActionType} setReadByUserData={setReadByUserData} relaodAction={reloadActionTypeforAssRead} readAssignmentID={readAssignmentID} />}
                                {actionType === 'Mark Non-Urgent' && <MarkNonUrgent taskId={taskId} relaodAction={reloadActionTypeforMarkNonUrgent} />}
                                {actionType === 'Assign Review' && <AssignReview ReviewByUserData={arrReviewByUserData} setActionType={setActionType}
                                    onChangeReviewer={ChangeReviewerHandler} taskId={taskId} setReviewStage={setReviewStage} setReviewAssignmentID={setReadAssignmentID}
                                    relaodAction={reloadActionTypeforAssReview} Type={taskDetails.Type} reviewerNote={reviewerNote} setReviewerNote={setReviewerNote}/>}
                                {(actionType === 'Re-review' || actionType === 'ChangeReviewer') && <AssignReview ReviewByUserData={arrReviewByUserData} setActionType={setActionType}
                                    onChangeReviewer={ChangeReviewerHandler} taskId={taskId} setReviewStage={setReviewStage} setReviewAssignmentID={setReadAssignmentID}
                                    relaodAction={reloadActionTypeforAssReview} Type={taskDetails.Type} reviewerNote={reviewerNote} setReviewerNote={setReviewerNote}/>}
                                {actionType === 'AssignReviewer' && <Reviewers taskId={taskId} setActionType={setActionType}
                                    setReviewByUserData={setReviewByUserData} reviewStage={reviewStage} relaodAction={reloadActionTypeforAssReview} reviewAssignmentID="0" />}
                                {/*actionType === 'ChangeReviewer' && <Reviewers taskId={taskId} setActionType={setActionType} setReviewByUserData={setReviewByUserData} relaodAction={reloadActionTypeforAssReview} reviewAssignmentID={readAssignmentID} />*/}
                                {actionType === 'Closed' && <ClosedTask taskId={taskId} relaodAction={reloadActionTypeforClosedComplete} />}
                                {actionType === 'Create a Query' && <AddQueryByTaskID queryid="0" relaodAction={reloadActionTypeforCreateQuery} TaskId={taskId} />}
                                </Col>
                            </Row>
                        </InputContainer>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <InputContainer Expended='true' header='Task History' style={{ "text-align": "center", "margin-bottom": "0px", "margin-top": "15px" }}>
                        <TaskHistory historyData={taskAuditData}/>
                    </InputContainer>
                </Col>
            </Row>
        </Card>
    );
}

export default TaskDetails