import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authAction } from '../../app/auth';

const UserDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [userLabel, setUserLabel] = useState('');
    const EDCUserID = useSelector(state => state.auth.gValue01);

    const handleChangePassword = (event) => {
        navigate('./ChangePassword');
    };

    useEffect(() => {
        fetchStudySiteDetails();
    }, [])

    const Logout = () => {
        fetch('api/Users/ClearSession', {
            method: 'POST'      
        })
        .then(response => response.text())
        .then(data => {
            dispatch(authAction.logout());
            navigate('./Login');
            });
       
    };

    function deleteEDCCookies(name) {
        var allCookies = document.cookie.split(';');
        for (var i = 0; i < allCookies.length; i++) {
            var match = allCookies[i].match(new RegExp('(^| )' + name + '=([^;]+)'));
            if (match) {
                document.cookie = allCookies[i] + "=;expires="
                    + new Date(0).toUTCString();
            }
        }
    }

    const fetchStudySiteDetails = () => {
        fetch('api/Users/GetUserByUserID?userID=' + EDCUserID)
            .then(response => response.json())
            .then(data => {
                setFullName(data.FullName);
                setEmail(data.Email);
                let arrUserNames = data.FullName.split(' ');
                let firstName = arrUserNames[0];
                let lastName = '';
                if (arrUserNames.length > 1) {
                    lastName = arrUserNames[1];
                    setUserLabel(firstName.charAt(0) + lastName.charAt(0));
                }
                else {
                    setUserLabel(firstName.charAt(0));
                }
            });
    }

    const popoverBottom = (
        <Popover id="popover-positioned-bottom" style={{ "max-width": "100%", "z-index": "10101" }} >
            <div style={{ width: "300px", height: "160px", "margin": "6px" }}>
                <div className="input-group" style={{ "margin": "16px" }}>
                    <label style={{ float: "left" }}> <b>Noto</b> </label> <div style={{ "width": "120px" }}></div>
                    <a onClick={Logout} style={{ float: "right" }}> <b> Sign out </b></a>
                </div>
                <div className="row" style={{ "margin-top": "20px" }}>
                    <div className="col-sm-3" style={{ "margin-left": "0px", "margin-top": "20px" }}>
                        <div style={{
                            height: "70px", width: "65px", "background-color": "#5faed2", "border-radius": "50%",
                            "display": "inline-block", "font-size": "2.5em", "text-align": "center"
                        }}>
                            <label style={{ color: "white", "verticle-align": "center", "margin-top": "8px" }}>
                                {userLabel.toUpperCase()}
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-9">
                        <div style={{ "margin": "2px" }}>
                            <strong>{fullName}</strong>
                        </div>
                        <div style={{ "margin": "2px" }}>
                            <span>{email}</span>
                        </div>
                        <div style={{ "margin": "2px", display: "none" }}>
                            <a href="#" style={{ "text-decoration": "underline" }}>Edit profile</a>
                        </div>
                        <div style={{ "margin": "2px" }}>
                            <a style={{ "text-decoration": "underline", cursor: "pointer", color: "navy" }} onClick={handleChangePassword}>Change password</a>
                        </div>
                    </div>
                </div>

            </div>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" placement="left" overlay={popoverBottom} rootClose={true}>
            <div style={{
                height: "33px", width: "33px", "background-color": "#5faed2", "border-radius": "50%", "display":
                    "inline-block", "font-size": "2em", "text-align": "center", cursor: "pointer", "margin-top": "0px"
            }}>
                <label style={{ color: "white", "verticle-align": "center", "margin-top": "5px", cursor: "pointer" }}>
                    {userLabel.toUpperCase()}
                </label>
            </div>
        </OverlayTrigger>
    );
}

export default UserDetails;