import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputTextarea from '../UI/Control/InputTextarea';
import classes from './../TrialstatNoto.module.css';
import { format } from 'date-fns';
import EditIcon from '../../Images/Icons/edit.svg';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import SelectList from '../UI/Control/SelectList';

const NoticeModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [usrRole, setUsrRole] = useState('');
    const [show, setShow] = useState(false);
    const [arrStudies, setStudies] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        fillStudyListByEDCUserId();
        setShow(true);
    }

    const SaveValue = (event) => {

        let objNoticeStudy = document.getElementById('ddlNoticeStudy');
        //if (objNoticeStudy.value.trim() == -1) {
        //    alert('Please select study.')
        //    objNoticeStudy.focus();
        //    return false;
        //}

        let objNotice = document.getElementById('txtNotice');
        if (objNotice.value.trim() == '') {
            alert('Notice should not be empty.')
            objNotice.focus();
            return false;
        }

        let objNoticesModel = {
            NoticeID: props.noticeId,
            StudyID: objNoticeStudy.value,
            NoticeText: objNotice.value,
            CreationUser: EDCUserID,
            ModifyUser: EDCUserID
        };

        fetch('api/ImageService/SaveNotices', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objNoticesModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Notices not saved, Something went wrong.');
            }
            //if (res == 0) {
            //    alert('Notices already exists, Please try again.');
            //}
            if (res > 0) {
                props.fetchNotices(props.noticeStudyId);
                setShow(false);
            }
        })
        
    };

    const fetchNoticesDetails = () => {
        fetch('api/ImageService/GetNoticesDetails?noticeId=' + props.noticeId)
            .then(response => response.json())
            .then(data => {
                document.getElementById("ddlNoticeStudy").value = data.StudyID;
                document.getElementById("txtNotice").value = data.NoticeText;
            });
    }

    function getUserRole() {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setUsrRole(role);
            });
    }

    const fillStudyListByEDCUserId = () => {
        fetch('api/ImageService/fillStudyListByEDCUserId')
            .then(response => response.json())
            .then(data => {
                setStudies(data);
                if (props.noticeId > 0) {
                    getUserRole();
                    fetchNoticesDetails();
                }
            });
    }

    return (
        <>
            {props.btn === "true" && <Button variant="primary" className="btn btn-success" onClick={handleShow}> {props.text}  </Button>}
            {props.btn === "icon" && <img className={classes.act_icon} src={EditIcon} alt="Edit" title="Edit" onClick={handleShow} />}
            
            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Three} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>Notice<MandatoryLabel></MandatoryLabel></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectList id='ddlNoticeStudy' dataSource={arrStudies} style={{marginBottom : "10px", width:"40%"}} />
                    <InputTextarea id="txtNotice" rows="15" cols="75" placeholder="Notice" maxlength="999"></InputTextarea>
                </Modal.Body>
                <Modal.Footer>
                    {usrRole != "Read-Only" && <Button className="btn btn-md btn-success" variant="primary" onClick={SaveValue}> Save </Button>}
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default NoticeModal;