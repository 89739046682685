import React, { useState, useEffect } from 'react';

//import classes from './Card.module.css';
const emailChangeHandler = (event) => {
    //setEnteredEmail(event.target.value);
};

const validateEmailHandler = () => {
    //alert(Classes.login);
    //setEmailIsValid(enteredEmail.includes('@'));
};
const InputUsername = (props) => {
    const [enteredEmail, setEnteredEmail] = useState('');
    const [emailIsValid, setEmailIsValid] = useState();
    return (
        <input id="txtUserName" type="text" name="txtUserName" size="24" className="form-control" style={{ margin:"5px" }}
            autocomplete="off" placeholder="User ID"
            onChange={emailChangeHandler} onBlur={validateEmailHandler} />
    );
};

export default InputUsername;
