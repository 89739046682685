import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { globalDataAction } from '../../app/globaldata';
import { Button, Lable } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import Designer from './Designer';
import EditIcon from '../../Images/Icons/edit.svg';
const EDCProjectModal = (props) => {
    const studyProID = useSelector(state => state.globaldata.gblDt01);
    const [show, setShow] = useState(false);
    const [isError, setIsError] = useState(false);
    const [returnMsg, setReturnMsg] = useState('');
    //const [EDCProjID, setEDCProj] = useState('');
    const handleClose = () => {
        if (!isError)
            document.getElementById('btnCreateEDCProject').style.display = 'none';
        setShow(false);
        //props.CloseAdjPopup();
    };
    const handleShow = () => {
        setShow(true);
        setReturnMsg('');
        setIsError(false);
        //props.SaveAdjConfigAction();
        //getEDCProJID()
    }
    const getEDCProJID = () => {
        fetch("api/AdjudicationSettings/GetEDCProjID?studyProtocolID=" + studyProID)
            .then(response => response.text())
            .then(data => {               
                if (data == "0") {
                    setEDCProj(data);
                }
                else {
                    props.CloseAdjPopup();
                }
            })
    }

    const edcProjectHandler = async () => {
        let apiURL = 'api/ServiceCall/CreateStudy?studyprotocolID=' + studyProID;
        if (props.action == 'redeployment') {
            apiURL = 'api/ServiceCall/RedeploployStudy?studyprotocolID=' + studyProID;
        }
        const response = await fetch(apiURL,
            {
                method: "POST",
                mode: 'cors'
            }
        )
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const resMsg = await response.text();   
        if (resMsg == "\"ReqSent\"") {
            setReturnMsg("EDC project creation request was sent successfully.");
            setIsError(false);
        }
        else if (resMsg.indexOf("\"ProjCreated_") > -1) {
            var edcProjID = resMsg.replace("\"ProjCreated_", "");
            edcProjID = edcProjID.replace("\"", "");            
            const notoresponse = await fetch('api/Study/UpdateEDCProjID?edcProjID='+edcProjID+'&studyprotocolID='+studyProID,
                {
                    method: "POST"                   
                }
            )
            if (!notoresponse.ok) {
                throw new Error("Something went wrong, message :" + notoresponse.message + " \nHttp Error:" + notoresponse.httpError)
            }
            const resMsg1 = await notoresponse.text();
            if (resMsg1 === "success") {
                setReturnMsg("Project created successfully.");
            }
            else {
                setReturnMsg("The project was successfully created, but an error occurred while updating the EDC project ID in the Noto application.");
            }
            setIsError(false);
        }
        else if (resMsg === "\"ProjNameExist\"") {
            setReturnMsg("Project name already exists, please try a unique name.");
            setIsError(true);
        }
        else if (resMsg === "\"ProjShortNameExist\"") {
            setReturnMsg("Project short name already exists, please try a unique short name.");
            setIsError(true);
        }
        else if (resMsg === "nositeexist") {
            setReturnMsg("No sites exist.");
            setIsError(true);
        }         
        else {
            setReturnMsg("Internal server Error, please connect system Admin.");
            setIsError(true);
        }       
    }  

    return (
        <>
            <Button id="btnCreateEDCProject" alt="Create EDC Project" onClick={handleShow} className="btn btn-warning" style={{ "margin-right": "5px", float:"right" }}> {props.text} </Button>
            <Modal show={show} onHide={handleClose} onShow={edcProjectHandler} dialogClassName={classes.TSModalDailog_Nine} animation={false} backdrop="static" style={{ "margin-top": "250px" }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>EDC Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(returnMsg === "") && <label>Creating an EDC project...</label>}
                    {isError && <label style={{ "color": "red" }}>Error, {returnMsg}</label>}
                    {!isError && <label>{returnMsg}</label>}
                </Modal.Body>
            </Modal>
        </>
    );
}
export default EDCProjectModal;