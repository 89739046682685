import React, { useState, useEffect } from 'react';
import SelectList from '../UI/Control/SelectList';
import InputString from '../UI/Control/InputString';
import InputPassword from '../UI/Control/InputPassword';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import Button from '../UI/Button/Button';
import { useSelector } from 'react-redux'

const SecretQuestion = (props) => {
    const [arrNoOfQues, setNoOfQues] = useState([]);
    const [arrSecretQuestion, setSecretQuestion] = useState([]);
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [userName, setUername] = useState('');

    const fetchEDCSecretQues = async () => {
        const response = await fetch('api/Users/GetEDCSecretQues')
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const secQues = await response.text();
        let listQues = JSON.parse(secQues);
        const arrSecQue = new Array();
        arrSecQue.push({ Name: '', Value: 0 })
        for (var iCount = 0; iCount < listQues.length; iCount++) {
            arrSecQue.push({ Name: listQues[iCount].SecretQuestion, Value: listQues[iCount].SecretQuestionId })
        }

        let arr = new Array();
        for (var iCount = 0; iCount < 5; iCount++) {
            arr.push({
                sequence: (iCount+1)
            });
        }
        setNoOfQues(arr);
        setSecretQuestion(arrSecQue);
    }

    function getUserName() {
        fetch('api/Users/GetUserNameByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(username => {
                setUername(username);
            });
    }

    useEffect(() => {
        getUserName();
        fetchEDCSecretQues().catch((error) => {
            console.log(error.message)
        });

    }, [])

    const SaveSecretQuestion = (event) => {
        if (validateSecretQues() == false) {
            return false;
        }

        let objSecretQues = {
            EDCUserId: EDCUserID,
            UserName: userName,
            NewPassword: document.getElementById('txtNewPassword').value,
            listSecretQues: new Array()
        };

        let objSecQues = null;
        let objAns = null;
        for (var iCount = 0; iCount < arrNoOfQues.length; iCount++) {
            objSecQues = document.getElementById('ddlSecQues_' + arrNoOfQues[iCount].sequence);
            objAns = document.getElementById('txtAns_' + arrNoOfQues[iCount].sequence);
            objSecretQues.listSecretQues.push({ SecQuesID: objSecQues.value, AnsText: objAns.value })
        }

        fetch('api/Users/SecQues_ChangePass', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objSecretQues)
        }).then(res => res.text()).then(data => {
            if (data == 0 || data == -1) {
                alert('Secret question and change password not saved, Something went wrong.');
            }
            if (data > 0) {
                props.setSecQuesTrue();
            }
        });
    };

    const CancelSecretQuestion = (event) => {
        fetch('api/Users/ClearSession', {
            method: 'POST'
        })
        .then(response => response.text())
        .then(data => {
            props.dispatchLogout();
        });
    };

    function validateSecretQues() {
        let objSecQues = null;
        let objAns = null;
        let arrQuesId = [];
        let arrAnsTest = [];
        for (var iCount = 0; iCount < arrNoOfQues.length; iCount++) {
            objSecQues = document.getElementById('ddlSecQues_' + arrNoOfQues[iCount].sequence);
            if (objSecQues.value == 0) {
                alert('Please select your secret question ' + arrNoOfQues[iCount].sequence);
                objSecQues.focus();
                return false;
            }

            if (arrQuesId.indexOf(objSecQues.value) == -1) {
                arrQuesId.push(objSecQues.value);
            }
            else {
                alert('Your secret question ' + (iCount + 1) + ' is duplicate')
                objSecQues.focus();
                return false;
                break;
            }

            objAns = document.getElementById('txtAns_' + arrNoOfQues[iCount].sequence);
            if (objAns.value.trim() == '') {
                alert('Your answer for secret question ' + arrNoOfQues[iCount].sequence + ' should not be empty');
                objAns.focus();
                return false;
            }

            if (objAns.value.trim().length < 4) {
                alert('For security reasons your answer for secret question ' + arrNoOfQues[iCount].sequence + ' must be a minimum of 4 characters and are case sensitive, so please select your questions accordingly.');
                objAns.focus();
                return false;
            }

            if (arrAnsTest.indexOf(objAns.value) == -1) {
                arrAnsTest.push(objAns.value);
            }
            else {
                alert('Your answer for secret question ' + (iCount + 1) + ' is duplicate')
                objAns.focus();
                return false;
                break;
            }
        }

       

        const objNewPassword = document.getElementById('txtNewPassword');
        if (objNewPassword.value.trim() == '') {
            alert('New password should not be empty.');
            objNewPassword.focus();
            return false;
        }


        if (objNewPassword.value.length < 8) {
            alert('New password should be minimum 8 chracter.');
            objNewPassword.focus();
            return false;
        }

        const objConfirmPassword = document.getElementById('txtConfirmPassword');
        if (objConfirmPassword.value.trim() == '') {
            alert('Password again should not be empty.');
            objConfirmPassword.focus();
            return false;
        }

        if (objNewPassword.value != objConfirmPassword.value) {
            alert('New password and password again should be match.');
            objConfirmPassword.focus();
            return false;
        }

        return true;
    }


    return (
        <div className="container" style={{ "margin-top": "3%", "margin-bottom": "3%", "border": "solid", "border-color": "silver", "border-width": "thin" }}>
            <div className="row" style={{ "border": "1px solid Silver" }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ "background-color": "#CCDDFF", "border": "1px solid Silver", "border-bottom-color": "silver", "border-bottom-style": "solid", "border-bottom-width": "1px", "text-align": "center", "border-bottom": "none", "padding": "7px" }} >
                    <b>Welcome to Alimentiv Noto</b>
                </div>
            </div>
            <div className="row" style={{ "border": "1px solid Silver", "border-top": "none" }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pwdsecqinfo" style={{ "background-color": "#efefef", "text-align": "center", "padding-top": "4px", "padding-bottom": "4px" }}>
                    <p>You are almost ready to begin using the system. For password recovery reasons we ask that you now select five questions from the list below and provide answers to them that you will remember.
		        In the event that you forget your password, TrialStat EDC will ask you some of your secret questions to validate your identity before helping you reset your password.</p><p>Why are the questions necessary?  The information stored in your TrialStat EDC project may be highly sensitive. As such, it is important that access to your account be carefully controlled to prevent access by unauthorized persons.
                TrialStat’s password management protocols conform to international privacy and security regulations and GCP requirements regarding subject confidentiality and unauthorized system access.</p>
                </div>
            </div>
            {
                arrNoOfQues.map(item =>
                    <div>
            <div className="row" style={{ "border": "1px solid Silver", "border-top": "none" }}>
                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 lblcol" style={{ "background-color": "#EFF3F8", "padding": "3px", "border-right": "1px solid Silver" }}>
                                Your Secret Question {item.sequence} <MandatoryLabel></MandatoryLabel>
                </div>
                            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 fldcol" style={{ "background-color": "#FFFFFF", "padding-top": "4px", "padding-bottom": "4px" }}>
                                <SelectList dataSource={arrSecretQuestion} id={"ddlSecQues_" + item.sequence} style={{width:"95%"}}></SelectList>
                </div>
            </div>
            <div className="row" style={{ "border": "1px solid Silver", "border-top": "none" }}>
                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 lblcol" style={{ "background-color": "#EFF3F8", "padding": "3px", "border-right": "1px solid Silver" }}>
                                Your Answer {item.sequence}<MandatoryLabel></MandatoryLabel>

                </div>
                            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 fldcol" style={{ "background-color": "#FFFFFF", "padding-top": "4px", "padding-bottom": "4px" }}>
                                <InputString id={"txtAns_" + item.sequence} style={{ "width": "95%" }}></InputString>
                </div>
                        </div>
                        </div>
                      )
            }
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ "background-color": "#CCDDFF", "border": "1px solid Silver", "border-top": "none", "padding": "5px" }}>
                    It appears that this is your first login or your password has been reset.  We ask that you please change the temporary password assigned to you to something else for security reasons.
                </div>

            </div>
            <div className="row" style={{ "border": "1px solid Silver", "border-top": "none" }}>
                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 lblcol" style={{ "background-color": "#EFF3F8", "padding": "3px", "border-right": "1px solid Silver" }}>New password
                    (Password must be 8 letters long,which must contain at least one uppercase letter,  one lowercase letter, one number and one special character.)
                 </div>
                <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 fldcol" style={{ "background-color": "#FFFFFF", "padding-top": "4px", "padding-bottom": "4px" }}>
                    <InputPassword id="txtNewPassword" maxlength="50" style={{width:"50%"}} placeholder="New Password"></InputPassword>
                </div>
            </div>
            <div className="row" style={{ "border": "1px solid Silver", "border-top": "none" }}>
                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 lblcol" style={{ "background-color": "#EFF3F8", "padding": "3px", "border-right": "1px solid Silver" }}>New password again
                    (Password must be 8 letters long,which must contain at least one uppercase letter,  one lowercase letter, one number and one special character.)
                 </div>
                <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 fldcol" style={{ "background-color": "#FFFFFF", "padding-top": "4px", "padding-bottom": "4px" }}>
                    <InputPassword id="txtConfirmPassword" maxlength="50" style={{ width: "50%" }} placeholder="New Password Again"></InputPassword>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ "background-color": "#FFFFFF", "padding": "3px", textAlign: "center"}}>
                    <Button className="btn btn-success" style={{marginRight:"10px"}} onClick={SaveSecretQuestion}>
                        Save
                    </Button>
                    <Button className="btn btn-danger" onClick={CancelSecretQuestion}>
                        Cancel
                    </Button>
                </div>
            </div>

        </div>
    );
};

export default SecretQuestion;
