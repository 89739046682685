import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import classes from './../TrialstatNoto.module.css';

const AddManualTaskModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [arrManualViewer, setManualViewer] = useState([]);
    const [arrMVStudy, setMVStudy] = useState([]);
    const [arrSite, setSite] = useState([]);
    const [arrVisit, setVisit] = useState([]);
    const [selMStudyId, setSelMStudyId] = useState([]);

    const handleShow = () => {
        
        ClearData();
        FillMVStudies();
        setShow(true);

    }

    const FillMVStudies = () => {
        fetch('api/ImageService/GetManualViewerStudies')
            .then(response => response.json())
            .then(data => {
                setMVStudy(data);
            });
    }
    const studyChangeHandler = (e) => {
        ClearData();
        FillManualViewer();
        FillSites();
       
    }

    const viewerChangeHandler = (e) => {
        setVisit(""); 
        FillVisits();
    }
    

    const FillManualViewer = () => {        
        fetch('api/ImageService/GetManualViewer?StudyId=' + document.getElementById('ddlManualStudy').value)
            .then(response => response.json())
            .then(data => {
                setManualViewer(data);
            });
    }
    
    const FillSites = () => {
        fetch('api/ImageService/FillSiteList?StudyId=' + document.getElementById('ddlManualStudy').value)
            .then(response => response.json())
            .then(data => {
                setSite(data);
            });
    }
    const FillVisits = () => {
        fetch('api/ImageService/FillMTVisitList?StudyId=' + document.getElementById('ddlManualStudy').value + '&SourceTypeID=' + document.getElementById('ddlManualViewer').value)
            .then(response => response.json())
            .then(data => {
                setVisit(data);
            });
    }

    function ClearData() {
        setManualViewer(""); 
        setSite(""); 
        setVisit(""); 
    }

    const AddNewManualTask = (event) => {

        let objDDList = document.getElementById("ddlManualStudy");
        if (objDDList.value == '-1') {
            alert('Please select the study.');
            objDDList.focus();
            return false;
        }

        objDDList = document.getElementById("ddlManualViewer");
        if (objDDList.value == '-1') {
            alert('Please select the Viewer.');
            objDDList.focus();
            return false;
        }

        objDDList = document.getElementById("ddlVisit");
        if (objDDList.value == '-1') {
            alert('Please select the Visit.');
            objDDList.focus();
            return false;
        }

        objDDList = document.getElementById("ddlSite");
        if (objDDList.value == '-1') {
            alert('Please select the Site.');
            objDDList.focus();
            return false;
        }

        let objSubject = document.getElementById('txtSubject');
        if (objSubject.value.trim() == '') {
            alert('Subject should not be empty.');
            objSubject.focus();
            return false;
        }

        let objManualTask = {
            StudyId: document.getElementById("ddlManualStudy").value,
            SiteId: document.getElementById("ddlSite").value,
            SubjectId: document.getElementById('txtSubject').value.trim(),
            VisitId: document.getElementById("ddlVisit").value,
            SourceTypeId: document.getElementById("ddlManualViewer").value,
            TaskURL: document.getElementById('txtURL').value.trim() 
        };

        fetch('api/ImageService/SaveManualTask', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objManualTask)
        }).then(r => r.text()).then(res => {

            if (res == "success") {
                setShow(false);
                props.fetchData(props.userName, props.taskStageId, props.studyId, props.sourceTypeId);
            }
            else if (res == "TASK ALREADY EXISTS" || res == "exists") {
                alert('Task already exists.');
                return false;
            }
            else if (res == "notsaved") {
                alert('Task not added, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }

        })

    };

    return (
        <>
            <Button variant="primary" className="btn btn-success" onClick={handleShow}>Add Manual Task</Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Seven} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>Add New Manual Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Study<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-8">
                            <SelectList id='ddlManualStudy' dataSource={arrMVStudy}  onChangeHandler={studyChangeHandler} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>      
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Viewer<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-8">
                            <SelectList id='ddlManualViewer' dataSource={arrManualViewer} onChangeHandler={viewerChangeHandler}  style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Visit<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-8">
                            <SelectList id='ddlVisit' dataSource={arrVisit} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Site<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-8">
                            <SelectList id='ddlSite' dataSource={arrSite} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>Subject<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-8">
                            <InputString id="txtSubject" name="txtSubject" />
                        </div>
                        <div className="col-sm-1" />
                    </div>                   
                    
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <label>URL:</label>
                        </div>
                            <div className="col-sm-8">
                                <InputString id="txtURL" maxlength="999" />
                        </div>
                        <div className="col-sm-1" />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={AddNewManualTask}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AddManualTaskModal;