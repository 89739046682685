import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputCheckbox from '../UI/Control/InputCheckbox';
import InputTextarea from '../UI/Control/InputTextarea';
import SelectList from '../UI/Control/SelectList';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import InputNumber from '../UI/Control/InputNumber';
import classes from './../TrialstatNoto.module.css';

const AddNewQueryModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [arrCommentType, setCommentType] = useState([]);
    const [arrQueryType, setQueryType] = useState([]);
    const [arrStudyList, setStudyList] = useState([]);
    const [studyID, setStudyID] = useState('');
    const [arrSiteList, setSiteList] = useState([]);
    const [siteID, setSiteID] = useState('');
    const [taskId, setTaskId] = useState('');
    const [isLookUp, setLookUp] = useState(false);
    const handleShow = () => {
        setSiteList('');
        FillStudies();
        FillCommentType();
        FillQueryType();
        setShow(true);
    };
        

    const onLookUp = () => {
        let objTaskId = document.getElementById('qtxtTask').value.trim();

        setTaskId(objTaskId);
        if (objTaskId != '') {
            setTaskId(objTaskId);
            setStudyList('');
            setSiteList('');
            setLookUp(true);
            fetch('api/TaskQuery/FillStudyByTaskId?TaskId=' + objTaskId)
                .then(response => response.json())
                .then(data => {
                    setStudyList(data);

                    if (JSON.stringify(data) == '[]') {
                        alert('Study details not found for task id ' + objTaskId + '.');
                    }
                    else {
                        FillSiteByTask(objTaskId);
                    }
                });

        }
        else {
            alert('Task Id should not be empty.');
            //FillStudies();
        }
    };

    const FillSiteByTask = (tid) => {
        fetch('api/TaskQuery/FillSiteByTaskId?TaskId=' + tid)
            .then(response => response.json())
            .then(data => {
                setSiteList(data);
                if (JSON.stringify(data) == '[]') {
                    alert('Site details not found for task id ' + objTaskId + '.');
                    newSiteId = 0;
                }
            });
    };;

    const FillStudies = () => {
        fetch('api/ImageService/fillStudyListByEDCUserId')
            .then(response => response.json())
            .then(data => {                
                setStudyList(data);
            });
    }

    const studyOnChangeHandler = (e) => {
        setStudyID(e.target.value);
        FillSite(e.target.value);
        setSiteID(0);
    }

    const FillSite = (sid) => {
        fetch('api/ImageService/FillSiteList?StudyId=' + sid)
            .then(response => response.json())
            .then(data => {
                setSiteList(data);
            });
    }

    const siteOnChangeHandler = (e) => {
        setSiteID(e.target.value);        
    }

    
    const FillCommentType = () => {
        fetch('api/TaskQuery/FillQueryCommentType')
            .then(response => response.json())
            .then(data => {
                setCommentType(data);
            });
    }

    const FillQueryType = () => {
        fetch('api/TaskQuery/GetQueryType')
            .then(response => response.json())
            .then(data => {
                setQueryType(data);
            });
    }
    
    const CreateNewQuery = (event) => {        
        let objDDList = document.getElementById("qddlStudy");  
        let objIpValue = objDDList.value;
        if (objIpValue == '-1' || objIpValue == '=') {
            alert('Please select the Study.');
            objDDList.focus();
            return false;
        } 

        objDDList = document.getElementById("qddlSite");        
        objIpValue = objDDList.value;
        if (objIpValue == '-1' || objIpValue == '=') {
            alert('Please select the Site.');
            objDDList.focus();
            return false;
        } 
        objDDList = document.getElementById("qddlQueryType");
        objIpValue = objDDList.value;
        if (objIpValue == '-1' || objIpValue == '=') {
            alert('Please select the Query Type.');
            objDDList.focus();
            return false;
        }

        objDDList = document.getElementById("qddlCommentType");
        objIpValue = objDDList.value;
        if (objIpValue == '-1' || objIpValue == '=') {
            alert('Please select the Comment Type.');
            objDDList.focus();
            return false;
        }
        
        let objComment = document.getElementById('txtComment');
        if (objComment.value.trim() == '') {
            alert('Query Comment should not be empty.');
            objComment.focus();
            return false;
        }

        var hideSite = document.getElementById('chkHideSite').checked ? 'Y' : 'N';
        var queryStatus = 1;
        var inStudyId = document.getElementById('qddlStudy').value;
        var inSiteId = document.getElementById('qddlSite').value;
        var inTaskId = 0;
        if (document.getElementById('qtxtTask').value.trim() != '') {
            inTaskId = document.getElementById('qtxtTask').value.trim();            
        }

        let objQuery = {
            QueryId: 0,
            QueryStatusId: queryStatus,
            TaskId: inTaskId,
            StudyId: inStudyId, 
            SiteId: inSiteId,            
            CommentTypeId: document.getElementById("qddlCommentType").value,
            HideFromSite: hideSite,
            QueryComment: document.getElementById('txtComment').value.trim(),
            QueryTypeID: document.getElementById("qddlQueryType").value
        };
        
        fetch('api/TaskQuery/SaveTaskQuery', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objQuery)
        }).then(r => r.text()).then(res => {

            if (res == "success") {
                setShow(false);
                props.refreshsata();
            }
            else if (res == "notsaved") {
                alert('Query not added, Something went wrong.');
                return false;
            }
            else if (res == "notexists") {
                alert('Query not added, Task Id is not exists.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })

    };
    const TaskChangeHandler = () => {        
        let objTask = document.getElementById('qtxtTask');
        if (objTask.value == '' && isLookUp) {
            setLookUp(false);
            setSiteList([]);
            FillStudies();
        }
    };
    return (
        <>
            <Button variant="primary" className="btn btn-success" onClick={handleShow}>Create Query</Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Six} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>New Query</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mt-2" id="divStudy">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label>Task Id:</label>
                        </div>
                        <div className="col-sm-7" style={{ "display": "flex", "justify-content": "space-between" }}>
                            <InputNumber id="qtxtTask" maxlength="10" style={{ "width": "75%" }} onchange={TaskChangeHandler} />
                            <Button className="btn btn-md btn-warning" variant="primary" onClick={onLookUp}>Lookup</Button>
                        </div>
                        <div className="col-sm-1" />

                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label>Select Study<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-7">
                            <SelectList id='qddlStudy' dataSource={arrStudyList} onChangeHandler={studyOnChangeHandler} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label>Select Site<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-7">
                            <SelectList id='qddlSite' dataSource={arrSiteList} onChangeHandler={siteOnChangeHandler} />
                        </div>
                        <div className="col-sm-1" />
                    </div>

                    <div className="row mt-2" id="divQueryType">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label>Query Type<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-7">
                            <SelectList id='qddlQueryType' dataSource={arrQueryType} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>

                    <div className="row mt-2" id="divCommentType">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label>Comment Type<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-7">
                            <SelectList id='qddlCommentType' dataSource={arrCommentType} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    
                    <div className="row mt-2" id="divHideSite">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label>Hide From Site:</label>
                        </div>
                        <div className="col-sm-7">
                            <InputCheckbox id="chkHideSite" name="chkHideSite" />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label>Comment<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-7">
                            <InputTextarea id="txtComment" rows="6" cols="75" />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={CreateNewQuery}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AddNewQueryModal;