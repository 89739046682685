import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Disable } from 'react-disable';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import InputNumber from '../UI/Control/InputNumber';
import SelectList from '../UI/Control/SelectList';
import MultiSelectList from '../UI/Control/MultiSelectList';
import InputRadio from '../UI/Control/InputRadio';
import InputTextarea from '../UI/Control/InputTextarea';
import InputCheckbox from '../UI/Control/InputCheckbox';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import { globalDataAction } from '../../app/globaldata';
import Button from '../UI/Button/Button';
import ToggleButton from '../UI/Button/ToggleButton';

const StudySettings = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [CIMSDisabled, setCIMSDisabled] = useState('disabled');
    const [LUCIDDisabled, setLUCIDDisabled] = useState('disabled');
    const [sponsorsList, setSponsorsList] = useState([{}]);
    const [phase, setPhase] = useState([{}]);
    const [diseaseTypes, setDiseaseTypes] = useState([]);
    const [lucStudies, setLucStudies] = useState([{ Value: '0', Name: '--Lucidity Study--' }]);
    const [cimsStudies, setCIMSStudies] = useState([{ Value: '0', Name: '--CIMS Study--' }]);
    const [lucidityId, setLucidityId] = useState('0');
    const [CIMSId, setCIMSId] = useState('0');
    const [EDCProjects, setEDCProjects] = useState([{ Name: "Third Party EDC", Value: '0' }]);
    const [CHKCIMSIntegration, setCHKCIMSIntegration] = useState(false);
    const [CHKLucIntegration, setCHKLucIntegration] = useState(false);
    const [sponsorId, setSponsorId] = useState('-1');
    const [selDiseaseTypeIds, setSelDiseaseTypeIds] = useState([]);
    const [arrCustViewer, setCustViewer] = useState([]);
    const [custViewDisabled, setCustViewDisabled] = useState(true);
    const [selCustomViewerIds, setSelCustomViewerIds] = useState([]);
    const refCIMSId = useRef([]);
    const refLucidityId = useRef([]);
    const [arrCVControl, setArrCVControl] = useState([]);
    //store study protocol ID in application store
    const dispatch = useDispatch();


    //Retrive the state from app store
    const fetchPhases = async () => {
        fetch('api/Study/GetPhases')
            .then(response => response.json())
            .then(data => {
                setPhase(data);
            });
    };
    const fetchSponsorsList = () => {
        fetch('api/Study/GetSponsors')
            .then(response => response.json())
            .then(data => {
                setSponsorsList(data);
            });
    }
    const GetLucidityStudies = (LinkLucidity, LucidityID) => {
        let ctrLucidity = document.getElementById('chkLucidityInteg');
        fetch("api/LucidityServicesCall/GetLucidityStudies")
            .then(response => response.text())
            .then(data => {
                let lucarr = [{ Value: '0', Name: '--Lucidity Study--' }]
                if (data != "") {
                    var lucStudies = JSON.parse(data);
                    for (var iCount = 0; iCount < lucStudies.length; iCount++) {
                        var retVal = refLucidityId.current.filter((Id) => { return Id == lucStudies[iCount].studyId });
                        if (retVal.length == 0 || LucidityID == lucStudies[iCount].studyId)
                            lucarr.push({ Value: lucStudies[iCount].studyId, Name: lucStudies[iCount].name });
                    }
                }
                else
                    alert('Error while getting Lucidity studies; for details, look into the ErrorLog Table.')
                setLucStudies(lucarr);
                if (LinkLucidity == 'Y') {
                    document.getElementById('chkLucidityInteg').checked = true;
                    //document.getElementById('ddlLucStudies').value = data.LucidityID;
                    setLucidityId(LucidityID);
                }
            })
    }
    const GetCIMSStudies = (LinkCIMS, CIMSID) => {
        var CIMSarr = [{ Value: "0", Name: "--CIMS Study--" }]
        let ctrCIMS = document.getElementById('chkCIMInteg');
        fetch("api/CIMSServicesCall/GetCIMSStudies")
            .then(response => response.text())
            .then(data => {
                if (data != "") {
                    var CIMSStudies = JSON.parse(data);
                    for (var iCount = 0; iCount < CIMSStudies.length; iCount++) {
                        var retVal = refCIMSId.current.filter((Id) => { return Id == CIMSStudies[iCount] });
                        if (retVal.length == 0 || CIMSID == CIMSStudies[iCount])
                            CIMSarr.push({ Value: CIMSStudies[iCount], Name: CIMSStudies[iCount] });
                    }
                }
                else
                    alert('Error while getting CIMS studies; for details, look into the ErrorLog Table.')

                setCIMSStudies(CIMSarr)
                if (LinkCIMS == 'Y') {
                    document.getElementById('chkCIMInteg').checked = true;
                    //document.getElementById('ddlCIMSStudies').value = CIMSID;
                    setCIMSId(CIMSID);
                }
                if (props.Action.toLowerCase() == "view" || props.Action.toLowerCase() == "edit") {
                    props.setNextdisabled(false);
                }
            })
    }
    const fetchStudyDetail = () => {
        fetchSponsorsList();
        fetchDiseaseTypesList();
        fetchPhases();
        fillCIMSList();
        fillLucidityList();

        if (props.studyId != '') {
            fetch('api/Study/GetStudyDetail?studyId=' + props.studyId)
                .then(response => response.json())
                .then(data => {
                    setData(data);
                    GetCIMSStudies(data.LinkCIMS, data.CIMSID);
                    GetLucidityStudies(data.LinkLucidity, data.LucidityID);
                });
        }
        else {
            GetCIMSStudies('F', "0");
            GetLucidityStudies('F', "0");
        }
    }
    const fetchDiseaseTypesList = async () => {
        fetch('api/Study/FillDiseaseTypesList')
            .then(response => response.json())
            .then(data => {
                setDiseaseTypes(data);
            });
    }

    useEffect(() => {
        fillCustomViewerList();
        //disableOtherInteg();
    }, [props.studyId])

    function setData(data) {
        document.getElementById('txtStudyShortName').value = data.StudyCode;
        document.getElementById('txtStudyName').value = data.StudyName;
        //document.getElementById('txtSponsor').value = data.Sponsor;
        setSponsorId(data.Sponsor);
        if (data.EDCID != '0') {
            setEDCProjects([{ Name: "Third Party EDC", Value: data.EDCID }]);
        }
        document.getElementById('ddlPrimaryEDC').value = data.EDCID;
        if (data.LinkCIMS == 'Y') {
            document.getElementById('chkCIMInteg').checked = true;
            setCIMSDisabled('');
            document.getElementById('ddlCIMSStudies').value = data.CIMSID;
        }
        if (data.LinkLucidity == 'Y') {
            document.getElementById('chkLucidityInteg').checked = true;
            setLUCIDDisabled('');
            document.getElementById('ddlLucStudies').value = data.LucidityID;
        }
        if (data.IncCenter == 'Y') {
            document.getElementById('rdoIncludeCenterYes').checked = true;
        }
        else {
            document.getElementById('rdoIncludeCenterNo').checked = true;
        }

        if (data.IncGroup == 'Y') {
            document.getElementById('rdoIncludeGroupYes').checked = true;
        }
        else {
            document.getElementById('rdoIncludeGroupNo').checked = true;
        }

        document.querySelector("input[name=IncludeGroup]:checked").value = data.IncGroup;
        document.getElementById('txtStudyDescription').value = data.StudyDesc;
        document.getElementById('txtNoOfDigit').value = data.SubjectDigits;
        document.getElementById('txtProtocol').value = data.Protocol;
        document.getElementById('txtProtocolVersion').value = data.ProtocolVersion;
        document.getElementById('txtStudyTags').value = data.StudyTags;
        document.getElementById('ddlPhase').value = data.PhaseID;

        if (data.EnableLocalRead == 'Y')
            document.getElementById('tglChkLocalRead').checked = true;
        
        const selDiseaseTypes = data.DiseaseTypeID.split(',');
        setSelDiseaseTypeIds(selDiseaseTypes);
        document.getElementById('txtProtocolCode').value = data.ProtocolCode;
        if (props.userRole.toLowerCase() == 'super user' && data.IsCIMSTaskCreated.toLowerCase() == 'yes') {
            setCHKCIMSIntegration(true);
            setCIMSDisabled(true);
        }
        if (props.userRole.toLowerCase() == 'super user' && data.IsLucidityTaskCreated.toLowerCase() == 'yes') {
            setCHKLucIntegration(true);
            setLUCIDDisabled(true);
        }

        document.getElementById('chkMRI').checked = data.IsMRI;
        if (data.IsMRI == true) {
            document.getElementById('txtMRIProcIdAffix').disabled = false;
            document.getElementById('txtMRIProcIdNum').disabled = false;
            document.getElementById('txtMRIProcIdAffix').value = data.MRIProcIdAffix;
            document.getElementById('txtMRIProcIdNum').value = data.MRIProcIdNum;
        }

        document.getElementById('chkUltraSound').checked = data.IsUltraSound;
        if (data.IsUltraSound == true) {
            document.getElementById('txtUSProcIdAffix').disabled = false;
            document.getElementById('txtULProcIdNum').disabled = false;
            document.getElementById('txtUSProcIdAffix').value = data.USProcIdAffix;
            document.getElementById('txtULProcIdNum').value = data.USProcIdNum;
        }
        if (data.CustomViewerIDs != '') {
            document.getElementById('chkCustomViewer').checked = true;
            setCustViewDisabled(false);
            let arr1 = data.CustomViewerIDs.split('$');
            let arr2 = new Array();
            let arr3 = new Array();
            for (var iCount = 0; iCount < arr1.length; iCount++) {
                arr2.push(arr1[iCount].split('¢')[0]);
            }

            setSelCustomViewerIds(arr2);
            let arr = new Array();
            var obj = document.getElementById('ddlCustomViewer');
            var multiSelectCV = [...obj.selectedOptions];
            for (var jCount = 0; jCount < multiSelectCV.length; jCount++) {
                arr.push({ SourceTypeID: multiSelectCV[jCount].value, SourceTypeLabel: multiSelectCV[jCount].text });
            }
            setArrCVControl(arr);

            var custView = '';
            var custViewId = 0;
            var AffixIncNo = '';
            for (var iCount = 0; iCount < arr1.length; iCount++) {
                custView = arr1[iCount].split('¢');
                custViewId = custView[0];
                AffixIncNo = custView[1].split('£');
                document.getElementById('txtCVProcIdAffix_' + custViewId).value = AffixIncNo[0];
                document.getElementById('txtCVProcIdNum_' + custViewId).value = AffixIncNo[1];
            }
        }
    }

    const CIMIntegChange = (e) => {
        if (e.target.checked) {
            setCIMSDisabled('');
        }
        else {
            setCIMSId('0')
            setCIMSDisabled('disabled');
        }
    };

    const handleSponsor = (e) => {
        setSponsorId(e.target.value);
    };

    const handleCIMSStudies = (e) => {
        setCIMSId(e.target.value);
    };

    const LUCIDIntegChange = (e) => {
        if (e.target.checked) {
            setLUCIDDisabled('');
        }
        else {
            setLucidityId('0')
            setLUCIDDisabled('disabled');
        }
    };

    const CustViewIntegChange = (e) => {
        if (e.target.checked) {
            setCustViewDisabled(false);
        }
        else {
            setSelCustomViewerIds([]);
            setArrCVControl([]);
            setCustViewDisabled(true);
        }
    };

    const handleCustomViewer = (e) => {
        //setArrCVControl([]);
    };

    const handleLucStudies = (e) => {
        setLucidityId(e.target.value);
    };

    function ValidateStudySettings() {

        const objSponsor = document.getElementById('ddlSponsors');// document.getElementById('txtSponsor');
        if (objSponsor.value.trim() == '' || objSponsor.value.trim() == '-1') {
            alert('Please select Sponsor.');//alert('Sponsor should not be empty.');
            objSponsor.focus();
            return false;
        }

        const objStudyName = document.getElementById('txtStudyName');
        if (objStudyName.value.trim() == '') {
            alert('Study name should not be empty.');
            objStudyName.focus();
            return false;
        }

        const objStudyCode = document.getElementById('txtStudyShortName');
        if (objStudyCode.value.trim() == '') {
            alert('Study short name should not be empty.');
            objStudyCode.focus();
            return false;
        }

        let re = new RegExp("^[A-Za-z0-9_]*$");
        if (re.test(objStudyCode.value.trim()) == false) {
            alert('Study short name can only contain alphabetic character, numeric character and "_" , for example: Study_A36. Please change your project name to another.');
            objStudyCode.focus();
            return false;
        }

        if (objStudyCode.value.toLowerCase().endsWith('_prod') || objStudyCode.value.toLowerCase().endsWith('_dev') || objStudyCode.value.toLowerCase().endsWith('_uat')) {
            alert('Study short name filed not allowed _PROD, _DEV, or _UAT as a suffix.');
            objStudyCode.focus();
            return false;
        }

        const objProtocol = document.getElementById('txtProtocol');
        if (objProtocol.value.trim() == '') {
            alert('Protocol should not be empty.');
            objProtocol.focus();
            return false;
        }

        const objDiseaseType = document.getElementById('ddlDiseaseType');
        if (objDiseaseType.value == 0) {
            alert('Please select disease type.');
            objDiseaseType.focus();
            return false;
        }

        const objProtocolVersion = document.getElementById('txtProtocolVersion');
        if (objProtocolVersion.value.trim() == '') {
            alert('Protocol version should not be empty.');
            objProtocolVersion.focus();
            return false;
        }

        const objProtocolCode = document.getElementById('txtProtocolCode');
        if (objProtocolCode.value.trim() == '') {
            alert('Protocol code should not be empty.');
            objProtocolCode.focus();
            return false;
        }

        if (document.getElementById('chkCIMInteg').checked === true) {
            const objCIMSIntegration = document.getElementById('ddlCIMSStudies');
            if (objCIMSIntegration.value.trim() == '0') {
                alert('CIMS integration should not be empty.');
                objCIMSIntegration.focus();
                return false;
            }
        }

        if (document.getElementById('chkLucidityInteg').checked === true) {
            const objLucidityIntegration = document.getElementById('ddlLucStudies');
            if (objLucidityIntegration.value.trim() == '0') {
                alert('Lucidity integration should not be empty.');
                objLucidityIntegration.focus();
                return false;
            }
        }

        if (document.getElementById('chkMRI').checked === true) {
            const objProcIdMRIAffix = document.getElementById('txtMRIProcIdAffix');
            if (objProcIdMRIAffix.value.trim() == '') {
                alert('Procedure Id affix should not be empty for MRI.');
                objProcIdMRIAffix.focus();
                return false;
            }

            const objMRIProcIdNum = document.getElementById('txtMRIProcIdNum');
            if (objMRIProcIdNum.value.trim() == '') {
                alert('Procedure Id number should not be empty for MRI.');
                objMRIProcIdNum.focus();
                return false;
            }
        }

        if (document.getElementById('chkUltraSound').checked === true) {
            const objUSProcIdAffix = document.getElementById('txtUSProcIdAffix');
            if (objUSProcIdAffix.value.trim() == '') {
                alert('Procedure Id affix should not be empty for IUS.');
                objUSProcIdAffix.focus();
                return false;
            }

            const objULProcIdNum = document.getElementById('txtULProcIdNum');
            if (objULProcIdNum.value.trim() == '') {
                alert('Procedure Id number should not be empty for IUS.');
                objULProcIdNum.focus();
                return false;
            }
        }

        if (document.getElementById('chkCustomViewer').checked === true) {
            var objCustomViewer = document.getElementById('ddlCustomViewer');
            var multiSelectCV = [...objCustomViewer.selectedOptions].map(option => option.value);

            if (multiSelectCV.length == 0) {
                alert('Please select custom viewer item.');
                objCustomViewer.focus();
                return false;
            }

            if (arrCVControl.length == 0) {
                alert('Please load control for Procedure Id affix and number.');
                document.getElementById('btnLoadProc').focus();
                return false;
            }

            for (var iCount = 0; iCount < arrCVControl.length; iCount++) {
                const objCVProcIdAffix = document.getElementById('txtCVProcIdAffix_' + arrCVControl[iCount].SourceTypeID);
                if (objCVProcIdAffix.value.trim() == '') {
                    alert('Procedure Id affix should not be empty for ' + arrCVControl[iCount].SourceTypeLabel + '.');
                    objCVProcIdAffix.focus();
                    return false;
                }

                const objCVProcIdNum = document.getElementById('txtCVProcIdNum_' + arrCVControl[iCount].SourceTypeID);
                if (objCVProcIdNum.value.trim() == '') {
                    alert('Procedure Id number should not be empty for ' + arrCVControl[iCount].SourceTypeLabel + '.');
                    objCVProcIdNum.focus();
                    return false;
                }
            }
        }

        return true;
    }

    const SaveStudySettings = (event) => {
        let retVal = ValidateStudySettings();
        if (retVal == false)
            return false;

        let isCIMS = document.getElementById('chkCIMInteg').checked;
        let isLucidity = document.getElementById('chkLucidityInteg').checked;

        var selDiseaseType = '';
        var multiSelect = document.getElementById('ddlDiseaseType');
        var multiSelected = [...multiSelect.selectedOptions].map(option => option.value);
        for (var jCount = 0; jCount < multiSelected.length; jCount++) {
            selDiseaseType = selDiseaseType + multiSelected[jCount] + "$";
        }
        selDiseaseType = selDiseaseType.substring(0, (selDiseaseType.length - 1));

        var selCustomViewer = '';
        var multiSelectCV = document.getElementById('ddlCustomViewer');
        var multiSelectCV = [...multiSelectCV.selectedOptions].map(option => option.value);
       
        // ¢ = Alt + 155
        // £ = Alt + 156
        for (var jCount = 0; jCount < multiSelectCV.length; jCount++) {
            selCustomViewer = selCustomViewer + (multiSelectCV[jCount] + '¢' + document.getElementById('txtCVProcIdAffix_' + multiSelectCV[jCount]).value + '£' + document.getElementById('txtCVProcIdNum_' + multiSelectCV[jCount]).value) + "$";
        }
        selCustomViewer = selCustomViewer.substring(0, (selCustomViewer.length - 1));
        var enableLR = document.getElementById('tglChkLocalRead').checked ? 'Y' : 'N';
        let studyModel = {
            StudyID: props.studyId,
            StudyCode: document.getElementById('txtStudyShortName').value,
            StudyName: document.getElementById('txtStudyName').value,
            Sponsor: document.getElementById('ddlSponsors').value,//document.getElementById('txtSponsor').value,
            EDCID: document.getElementById('ddlPrimaryEDC').value,
            LinkCIMS: isCIMS ? 'Y' : 'N',
            CIMSID: isCIMS ? document.getElementById('ddlCIMSStudies').value : '0',
            LinkLucidity: isLucidity ? 'Y' : 'N',
            LucidityID: isLucidity ? document.getElementById('ddlLucStudies').value : '0',
            IncCenter: document.querySelector("input[name=IncludeCenter]:checked").value,
            IncGroup: document.querySelector("input[name=IncludeGroup]:checked").value,
            StudyDesc: document.getElementById('txtStudyDescription').value,
            SubjectDigits: 0, //document.getElementById('txtNoOfDigit').value,
            Protocol: document.getElementById('txtProtocol').value,
            ProtocolVersion: document.getElementById('txtProtocolVersion').value,
            EnableLocalRead: enableLR,
            PhaseID: document.getElementById('ddlPhase').value,
            DiseaseTypeID: selDiseaseType, //document.getElementById('ddlDiseaseType').value,
            ProtocolCode: document.getElementById('txtProtocolCode').value,
            StudyTags: document.getElementById('txtStudyTags').value,
            UserName: EDCUserID,
            IsMRI: document.getElementById('chkMRI').checked,
            MRIProcIdAffix: document.getElementById('txtMRIProcIdAffix').value,
            MRIProcIdNum: document.getElementById('txtMRIProcIdNum').value == '' ? 0 : document.getElementById('txtMRIProcIdNum').value,
            IsUltraSound: document.getElementById('chkUltraSound').checked,
            USProcIdAffix: document.getElementById('txtUSProcIdAffix').value,
            USProcIdNum: document.getElementById('txtULProcIdNum').value == '' ? 0 : document.getElementById('txtULProcIdNum').value,
            CustomViewerIDs: selCustomViewer

        };

        fetch('api/Study/SaveStudySettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(studyModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Study not saved, Something went wrong.');
                retVal = false;
            }
            if (res == 0) {
                alert('Study code already exist.');
                retVal = false;
            }
            else if (res == -2) {
                alert('Study name already exist.');
                retVal = false;
            }
            else if (res == -3) {
                alert('Protocol already exist.');
                retVal = false;
            }
            else if (res == -4) {
                alert('CIMS integration already exist.');
                retVal = false;
            }
            else if (res == -5) {
                alert('Lucidity integration already exist.');
                retVal = false;
            }
            else {
                if (props.Action.toLowerCase() === "add") //Tej Kumar
                    dispatch(globalDataAction.storeStudyProtocolID(res));
                props.nextStep();
            }
        })
    };

    const fillCustomViewerList = async () => {
        fetch('api/Study/fillCustomViewerList')
            .then(response => response.json())
            .then(data => {
                setCustViewer(data);
                fetchStudyDetail();
            });
    };

    const fillCIMSList = async () => {
        fetch('api/Study/GetCIMSList')
            .then(response => response.json())
            .then(data => {
                refCIMSId.current = data;
            });
    };

    const fillLucidityList = async () => {
        fetch('api/Study/GetLucidityList')
            .then(response => response.json())
            .then(data => {
                refLucidityId.current = data;
            });
    };

    const LoadProcedureControl = (event) => {
        let arr = new Array();
        var selCustomViewer = '';
        var obj = document.getElementById('ddlCustomViewer');
        var multiSelectCV = [...obj.selectedOptions]; //.map(option => option.value);
        for (var jCount = 0; jCount < multiSelectCV.length; jCount++) {
            arr.push({ SourceTypeID: multiSelectCV[jCount].value, SourceTypeLabel: multiSelectCV[jCount].text });
        }
        setArrCVControl(arr);
    };

    const MRIIntegChange = (e) => {
        if (e.target.checked) {
            document.getElementById('txtMRIProcIdAffix').disabled = false;
            document.getElementById('txtMRIProcIdNum').disabled = false;
        }
        else {
            document.getElementById('txtMRIProcIdAffix').value = '';
            document.getElementById('txtMRIProcIdNum').value = '';
            document.getElementById('txtMRIProcIdAffix').disabled = true;
            document.getElementById('txtMRIProcIdNum').disabled = true;
        }
    };

    const USIntegChange = (e) => {
        if (e.target.checked) {
            document.getElementById('txtUSProcIdAffix').disabled = false;
            document.getElementById('txtULProcIdNum').disabled = false;
        }
        else {
            document.getElementById('txtUSProcIdAffix').value = '';
            document.getElementById('txtULProcIdNum').value = '';
            document.getElementById('txtUSProcIdAffix').disabled = true;
            document.getElementById('txtULProcIdNum').disabled = true;
        }
    };

    const onToggleChange = (e) => {
        var selLR = e.target.checked;
    };

    return (
        <Card>
            <Disable disabled={props.disabled}>

                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <div style={{ fontWeight: "bold" }}>
                            Study Settings
                             <hr style={{ color: "gray", margin: "0px" }} />
                        </div>

                        <div className="row mb-3 mt-3">
                            <div className="col-sm-6">
                                <div className="row">
                                    <div class="col-md-3">
                                        <label for="">Sponsor:</label>
                                        <MandatoryLabel></MandatoryLabel>
                                    </div>
                                    <div class="col-md-9" style={{ paddingLeft: "7px", width: "73.5%" }}>
                                        { /*<InputString id="txtSponsor" maxlength="50"></InputString> */}
                                        <SelectList dataSource={sponsorsList} id="ddlSponsors" onChangeHandler={handleSponsor} value={sponsorId}></SelectList>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="row">
                                    <div class="col-md-3">
                                        <label for="">Study Name:</label>
                                        <MandatoryLabel></MandatoryLabel>
                                    </div>
                                    <div class="col-md-9" style={{ paddingLeft: "0px", width: "72%" }}>
                                        <InputString id="txtStudyName" maxlength="50"></InputString>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <div className="row">
                                    <div class="col-md-3">
                                        <label for="">Study Short Name:</label>
                                        <MandatoryLabel></MandatoryLabel>
                                    </div>
                                    <div class="col-md-9" style={{ paddingLeft: "7px", width: "73.5%" }}>
                                        <InputString id="txtStudyShortName" maxlength="10" disabled={props.userRole.toLowerCase() == 'super user' ? true : false}></InputString>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="row">
                                    <div class="col-md-3">
                                        <label for="">Protocol:</label>
                                        <MandatoryLabel></MandatoryLabel>
                                    </div>
                                    <div class="col-md-9" style={{ paddingLeft: "0px", width: "72%" }}>
                                        <InputString id="txtProtocol" maxlength="64"></InputString>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <div className="row mb-3">
                                        <div class="col-md-3">
                                            <label for="">Study Description:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <InputTextarea id="txtStudyDescription" rows="3" maxlength="200"></InputTextarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="">Phase:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <SelectList dataSource={phase} id="ddlPhase"></SelectList>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="" style={{ marginLeft: "10px" }}>Disease Type:</label>
                                            <MandatoryLabel></MandatoryLabel>
                                        </div>
                                        <div class="col-md-9">
                                            <MultiSelectList dataSource={diseaseTypes} size="6" id="ddlDiseaseType" selectedData={selDiseaseTypeIds}></MultiSelectList>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="">Protocol Code:</label>
                                            <MandatoryLabel></MandatoryLabel>
                                        </div>
                                        <div class="col-md-9">
                                            <InputString id="txtProtocolCode" maxlength="250"></InputString>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="" style={{ marginLeft: "10px" }}>Protocol Version:</label>
                                            <MandatoryLabel></MandatoryLabel>
                                        </div>
                                        <div class="col-md-9">
                                            <InputString id="txtProtocolVersion" maxlength="64"></InputString>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>

                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-10">
                            <div className="row mb-3 mt-3">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>Study Tags:</label>
                                        </div>
                                        <div className="col-md-9">
                                            <InputString id="txtStudyTags" maxlength="1500" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label style={{ marginLeft: "10px" }}>Local Read:</label>
                                        </div>
                                        <div className="col-md-9">
                                            <ToggleButton id="tglChkLocalRead" onToggleChange={() => onToggleChange(event)} className="LocalReadToggle" name="tglChkLocalRead" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="">Primary EDC:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <SelectList dataSource={EDCProjects} id="ddlPrimaryEDC" disabled={props.userRole.toLowerCase() == 'super user' ? true : false}></SelectList>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <div style={{ fontWeight: "bold" }}>
                                Integration
                                 <hr style={{ color: "gray", margin: "0px" }} />
                            </div>
                            <div className="row mb-3 mt-3">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="">CIMS Integration:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <div className="input-group">
                                                <InputCheckbox id="chkCIMInteg" name="chkCIMInteg" onChange={CIMIntegChange} style={{ "margin-top": "10px" }} disabled={CHKCIMSIntegration}></InputCheckbox>
                                                {/*<InputString id="txtCIMSIntegration" maxlength="64" disabled={CIMSDisabled} style={{ "margin-left": "10px" }}></InputString> */}
                                                <SelectList dataSource={cimsStudies} disabled={CIMSDisabled} id="ddlCIMSStudies" style={{ "margin-left": "10px" }} onChangeHandler={handleCIMSStudies} value={CIMSId} ></SelectList>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="" style={{ marginLeft: "10px"}}>Lucidity Integration:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <div className="input-group">
                                                <div className="input-group">
                                                    <InputCheckbox id="chkLucidityInteg" name="chkLucidityInteg" onChange={LUCIDIntegChange} style={{ "margin-top": "10px" }} disabled={CHKLucIntegration}></InputCheckbox>
                                                    {/* <InputString visible="false" id="txtLucidityIntegration" maxlength="64" disabled={LUCIDDisabled} style={{ "margin-left": "10px" }}></InputString> %> */}
                                                    <SelectList dataSource={lucStudies} disabled={LUCIDDisabled} id="ddlLucStudies" style={{ "margin-left": "10px" }} onChangeHandler={handleLucStudies} value={lucidityId}></SelectList>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>

                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <div className="row mb-3 mt-3">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                        </div>
                                        <div class="col-md-4" style={{ borderStyle: "solid", borderWidth: "thin", borderColor: "silver", padding: "10px", marginRight:"5px" }}>
                                            <div className="input-group">
                                                <div className="input-group">
                                                    <InputCheckbox id="chkMRI" style={{ "margin-top": "10px" }} onChange={MRIIntegChange}></InputCheckbox>
                                                    <label for="" style={{ marginLeft: "10px" }}>MRI</label>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "10px" }}>
                                                <div className="col-md-6">
                                                    <label>ProcId Affix</label>
                                                    <MandatoryLabel></MandatoryLabel>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>ProcId Num</label>
                                                    <MandatoryLabel></MandatoryLabel>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop:"2px" }}>
                                                <div className="col-md-12">
                                                    <div className="input-group">
                                                        <InputString id="txtMRIProcIdAffix" maxlength="254" disabled></InputString>
                                                        <InputNumber id="txtMRIProcIdNum" maxlength="1" disabled></InputNumber>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4" style={{ borderStyle: "solid", borderWidth: "thin", borderColor: "silver", padding: "10px" }}>
                                            <div className="input-group">
                                                <div className="input-group">
                                                    <InputCheckbox id="chkUltraSound" style={{ "margin-top": "10px" }} onChange={USIntegChange}></InputCheckbox>
                                                    <label for="" style={{ marginLeft: "10px" }}>IUS</label>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "10px" }}>
                                                <div className="col-md-6">
                                                    <label>ProcId Affix</label>
                                                    <MandatoryLabel></MandatoryLabel>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>ProcId Num</label>
                                                    <MandatoryLabel></MandatoryLabel>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "2px" }}>
                                                <div className="col-md-12">
                                                    <div className="input-group">
                                                        <InputString id="txtUSProcIdAffix" maxlength="254" disabled></InputString>
                                                        <InputNumber id="txtULProcIdNum" maxlength="1" disabled></InputNumber>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="" style={{ marginLeft: "10px" }}>Custom Viewer:</label>
                                        </div>
                                        <div class="col-md-9" style={{ borderStyle: "solid", borderWidth: "thin", borderColor: "silver", padding: "10px" }}>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="input-group">
                                                                <div className="input-group">
                                                                    <InputCheckbox id="chkCustomViewer" onChange={CustViewIntegChange} style={{ "margin-top": "10px" }} disabled={CHKLucIntegration}></InputCheckbox>
                                                                    <MultiSelectList id="ddlCustomViewer" onChangeHandler={handleCustomViewer} dataSource={arrCustViewer} selectedData={selCustomViewerIds} disabled={custViewDisabled} size="3" style={{ "margin-left": "10px" }}></MultiSelectList>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-md-3">
                                                    <Button id="btnLoadProc" className="btn btn-success" onClick={LoadProcedureControl} disabled={custViewDisabled}>Load Proc.</Button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {arrCVControl.length > 0 && <div className="row" style={{ marginTop: "10px" }}>
                                                    <div className="col-md-5">
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>ProcId Affix</label>
                                                        <MandatoryLabel></MandatoryLabel>
                                                    </div>
                                                    <div className="col-md-3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                        <label>ProcId Num</label>
                                                        <MandatoryLabel></MandatoryLabel>
                                                    </div>
                                                </div>}
                                                {
                                                    arrCVControl.map((item, index) =>
                                                        <div className="row" style={{ marginTop: "5px" }}>
                                                            <div className="col-md-1">
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>{item.SourceTypeLabel}</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <div className="input-group">
                                                                    <InputString id={"txtCVProcIdAffix_" + item.SourceTypeID} maxlength="254"></InputString>
                                                                    <InputNumber id={"txtCVProcIdNum_" + item.SourceTypeID} maxlength="1"></InputNumber>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                  
                    <div className="row" style={{ "display": "none" }}>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <label for="">Subject ID Format:</label>
                                        </div>
                                        <div class="col-md-9">
                                            <span for="">Number of Digits:</span>
                                            <MandatoryLabel></MandatoryLabel>
                                            <InputNumber id="txtNoOfDigit" maxlength="9"></InputNumber>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <span>Include Center:</span>
                                            <MandatoryLabel></MandatoryLabel>
                                        </div>
                                        <div class="col-md-9">
                                            <InputRadio orientation='horizontal' id="rdoIncludeCenterYes" name="IncludeCenter" value="Y" text="Yes" checked="true" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                                            <InputRadio orientation='horizontal' id="rdoIncludeCenterNo" name="IncludeCenter" value="N" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="row">
                                        <div class="col-md-3">
                                            <span>Include Group:</span>
                                            <MandatoryLabel></MandatoryLabel>
                                        </div>
                                        <div class="col-md-9">
                                            <InputRadio orientation='horizontal' id="rdoIncludeGroupYes" name="IncludeGroup" value="Y" text="Yes" checked="true" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                                            <InputRadio orientation='horizontal' id="rdoIncludeGroupNo" name="IncludeGroup" value="N" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="row" style={{ "display": "none" }}>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <div className="row">
                                <div className="col-sm-6">
                                    Sample Subject ID : CID-GRP1-OCT1
                            </div>
                                <div className="col-sm-6">

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                </div>
            </Disable>
            <div className="mt-2" style={{ "text-align": "center" }}>
                <props.ActionButton action={props.Action} component="StudySettings" SaveStudySettings={SaveStudySettings} {...props} />
            </div>
        </Card>
    );
};
export default StudySettings;
