import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Card from '../UI/Card/Card';
import InputContainer from '../UI/Container/InputContainer';
import Notice from './Notice';
import NoticeModal from './NoticeModal';
import ImportLucidityCases from './ImportLucidityCases';
import InprogressIcon from '../../Images/Icons/InProgress.svg';
import LockIcon from '../../Images/Icons/Lock.svg';
import DataTable from "react-data-table-component";
import InputString from '../UI/Control/InputString';
import MultiSelect from 'multiselect-react-dropdown';
import SelectList from '../UI/Control/SelectList';
import classes from './../TrialstatNoto.module.css';
import customStyles from '../customStyles';
import NoData from '../NoData';
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';

const ClosedTaskDashboard = (props) => {
    const navigate = useNavigate();
    const [edcUserID, setEDCUserID] = useState('');
    const [username, setUserName] = useState('');    
    //const [readType, setReadType] = useState('');
    const [rowData, setData] = useState([]);
    const [notice, setNotice] = useState([]);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [arrUserStudies, setUserStudies] = useState([]);
    const [arrSourceType, setSourceType] = useState([]);
    const [sourceTypeId, setSourceTypeId] = useState('-1');
    const [studyId, setStudyId] = useState('-1');


    useEffect(() => {
        getUserID();
        getUserNameAndRoleByID();
    }, []);

    const getUserNameAndRoleByID = () => {
        fetch('api/Users/getUserNameAndRoleByID')
            .then(response => response.json())
            .then(data => {
                setUserName(data.UserName);
                fetchData(data.UserName, '', '');
                fetchNotices();
                setCompPermission(data.RoleLabel);
                //setReadType(data.ReadType);
                fillStudyListByEDCUserId();
                FillSourceTypesList('');
            });
    };
    const getUserID = () => {
        fetch('api/Users/GetEDCUserID')
            .then(response => response.text())
            .then(data => {
                setEDCUserID(data);
            });
    };
    const setCompPermission = (usrRole) => {
        usrRole = usrRole.trim();
        if (usrRole == "Read-Only")
            setUserPermisssion("RO")
        else if (usrRole == "Database Administrator")
            setUserPermisssion("RO")
        else if (usrRole == "Clinical Project Manager")
            setUserPermisssion("RO")
        else if (usrRole == "Auditor")
            setUserPermisssion("RO")
        else if (usrRole == "Tester")
            setUserPermisssion("RW")
        else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator")
            setUserPermisssion("RW")
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management")
            setUserPermisssion("RO")
        else
            setUserPermisssion("RO")
    }
    const fetchData = (UserName, SId, StId) => {

        fetch('api/ImageService/GetClosedTasks?UserName=' + UserName + '&SId=' + SId + '&StId=' + StId)
            .then(response => response.json())
            .then(data => {
                setData(data);
                if (localStorage.getItem('searchedVal') != '' && localStorage.getItem('searchedVal') != null) {
                    let objSerachedVal = JSON.parse(localStorage.getItem('searchedVal'));
                    if (objSerachedVal != null) {                       
                        setStudyId(objSerachedVal.study);
                        setSourceTypeId(objSerachedVal.sourceTypeId);
                        document.getElementById('ddlStudy').value = objSerachedVal.study;
                        document.getElementById('ddlSourceType').value = objSerachedVal.sourceTypeId;
                        localStorage.setItem('searchedVal', '');
                    }
                }
            });
    }
    const fillStudyListByEDCUserId = () => {
        fetch('api/ImageService/FillClosedTasksStudyListByUserId')
            .then(response => response.json())
            .then(data => {
                setUserStudies(data);
            });
    }
    const FillSourceTypesList = (sid) => {
        fetch('api/ImageService/FillClosedTasksSourceTypesList?SId=' + sid)
            .then(response => response.json())
            .then(data => {
                setSourceType(data);
            });
    }
    
    //const UnLockHandler = (taskID) => {       
    //    RemoveTaskLock(taskID);
    //}
    const selectRef = useRef('');
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');

    const columns = [
        {
            name: "",
            selector: "IST",
            width: "2%",
            style: { "color": "red" },
            export: false

        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '4%',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {row.Status.toLowerCase() === "open" && <img className={classes.act_icon}
                        onClick={e => TaskDetailsHandler(row.TaskID)} src={InprogressIcon} alt="Inprogress " />}
                    {(row.Status.toLowerCase() === "locked" && row.LockedBy == edcUserID) && <img className={classes.act_icon} src={LockIcon} alt="Locked" onClick={e => TaskDetailsHandler(row.TaskID)} />}
                    {(row.Status.toLowerCase() === "locked" && row.LockedBy != edcUserID) && <img className={classes.act_icon} src={LockIcon} alt="Locked" onClick={e => TaskDetailsHandler_RO(row.TaskID, false)} />}
                </div>
            ),
            export: false
        },
        {
            name: "Task Id",
            selector: "TaskID",
            sortable: true,
            center: true,
            width: "5%"
        },
        {
            name: "Stage",
            selector: "Stage",
            sortable: true,
            center: true,
            width: "10%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Stage}</div>
            )
        },
        {
            name: "Source",
            selector: "SourceType",
            sortable: true,
            center: true,
            width: "7%"
        },
        {
            name: "Type",
            selector: "Type",
            sortable: true,
            center: true,
            width: "5%",
            omit: true
        },
        {
            name: "Status",
            selector: "Status",
            sortable: true,
            center: true,
            width: "6%",
            omit: true
        },
        {
            name: "Study",
            selector: "Study",
            sortable: true,
            center: true,
            width: "17%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.Study}</div>
            )
        },
        {
            name: "Protocol",
            selector: "Protocol",
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Site",
            selector: "Site",
            sortable: true,
            center: true,
            width: "7%"
        },
        {
            name: "Subject Id",
            selector: "SubjectID",
            sortable: true,
            center: true,
            width: "11%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.SubjectID}</div>
            )
        },
        {
            name: "Procedure Id",
            selector: "ProcedureID",
            sortable: true,
            center: true,
            width: '12%'
        },
        {
            name: "Visit",
            selector: "Visit",
            sortable: true,
            center: true,
            width: '7%'
        },
        {
            name: "Upload Date",
            selector: "ProcedureDate",
            sortable: true,
            center: true,
            width: '13%',
            sortFunction:  (a, b) => {return props.sortByDateTime(a.ProcedureDate, b.ProcedureDate);}
        },
        {
            name: "Due",
            selector: "Due",
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Hrs Remaining",
            selector: "HrsRemaining",
            sortable: true,
            center: true,
            width: '8%',
            omit: true
        },
        {
            name: "LockedBy",
            selector: "LockedBy",
            omit: true
        }
    ];

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (row[column] != null) {
                        if (searchText.length > 1)
                            return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                        else
                            return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
                    }
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
        selectRef.current.resetSelectedValues();
    };    

    const sourceTypeChangeHandler = (e) => {       
        var selStudy = document.getElementById('ddlStudy').value == -1 || document.getElementById('ddlStudy').value == '' ? '' : document.getElementById('ddlStudy').value;
        var selSource = e.target.value == -1 || e.target.value == '' ? '' : e.target.value;
        localStorage.setItem('searchedVal', JSON.stringify({ study: document.getElementById('ddlStudy').value, sourceTypeId: document.getElementById('ddlSourceType').value }));       
        fetchData(username, selStudy, selSource);       
    }
    
    const studyChangeHandler = (e) => {
        var selStudy = e.target.value == -1 || e.target.value == '' ? '' : e.target.value;
        var selSource = document.getElementById('ddlSourceType').value == -1 || document.getElementById('ddlSourceType').value == '' ? '' : document.getElementById('ddlSourceType').value;
        localStorage.setItem('searchedVal', JSON.stringify({ study: document.getElementById('ddlStudy').value, sourceTypeId: document.getElementById('ddlSourceType').value }));
        FillSourceTypesList(selStudy);
        fetchData(username, selStudy, selSource);
    }

    const conditionalRowStyles = [
        {
            when: row => row.HrsRemaining < 0,
            style: {
                color: 'Red'
            }
        }
    ];
    const PreventFilters = () => {
        localStorage.setItem('searchedVal', JSON.stringify({ study: document.getElementById('ddlStudy').value, sourceTypeId: document.getElementById('ddlSourceType').value }));
    };
    const TaskDetailsHandler = (taskId) => {
        PreventFilters();
        if (userPermission != 'RO')
            AddTaskLock(taskId);
        else {            
            navigate('/ImgTaskDetails',
                {
                    state: {
                        UserPermission: userPermission,
                        canUnlok: true,
                        taskId: taskId,
                        locNav: 'ctd'// Closed task Dashboard
                    }
                });
        }
    };
    const TaskDetailsHandler_RO = (taskId, flag) => {
        PreventFilters();
        navigate('/ImgTaskDetails',
            {
                state: {
                    UserPermission: 'RO',
                    canUnlok: flag,
                    taskId: taskId,
                    locNav: 'ctd'// Closed task Dashboard
                }
            });
    };

    function getDateTime() {
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;
        var day = now.getDate();
        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        if (month.toString().length == 1) {
            month = '0' + month;
        }
        if (day.toString().length == 1) {
            day = '0' + day;
        }
        if (hour.toString().length == 1) {
            hour = '0' + hour;
        }
        if (minute.toString().length == 1) {
            minute = '0' + minute;
        }
        if (second.toString().length == 1) {
            second = '0' + second;
        }
        var dateTime = year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second;
        return dateTime;
    }
    const AddTaskLock = (taskId) => {
        var currentTime = getDateTime();
        fetch('api/ImageService/AddTaskLock?TaskID=' + taskId + '&LockedDate=' + currentTime, { method: "POST" })
            .then(response => response.json())
            .then(data => {
                navigate('/ImgTaskDetails',
                    {
                        state: {
                            UserPermission: userPermission,
                            canUnlok: true,
                            taskId: taskId,
                            locNav: 'ctd'// Closed task Dashboard
                        }
                    });
            });
    };

    const fetchNotices = () => {
        fetch('api/ImageService/GetNotices')
            .then(response => response.json())
            .then(data => {
                setNotice(data);
            });
    };

    return (
        <Card>

            <Row className="mt-2 mb-2" >
                <Col md={3}>
                    <SelectList id='ddlStudy' dataSource={arrUserStudies} onChangeHandler={studyChangeHandler} value={studyId} />
                </Col>
                <Col md={2}>
                    <SelectList id='ddlSourceType' dataSource={arrSourceType} onChangeHandler={sourceTypeChangeHandler} value={sourceTypeId} />
                </Col>
                <Col md={4}>
                    <div className="input-group">
                        {/*readType == "Histo" && <ImportLucidityCases />*/}
                    </div>
                </Col>
                <Col md={3}>
                    <div className="input-group" style={{ "float": "right" }}>
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                    </div>
                </Col>
            </Row>
            <div className="tableContainer ">
                {search(rowData).length > 0 ?
                    <DataTableExtensions columns={columns} data={search(rowData)} filter={false} fileName="ImageServiceDashboard">
                        <DataTable
                            className="table table-striped table-bordered table-hover"
                            conditionalRowStyles={conditionalRowStyles}
                            customStyles={customStyles}
                            striped
                            pagination
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[20, 50, 100]}
                        />
                    </DataTableExtensions> : <NoData headers={columns} />}
            </div>
        </Card>
    );
};
export default ClosedTaskDashboard;