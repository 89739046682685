import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import classes from './../TrialstatNoto.module.css';
import customStyles from '../customStyles';
import NoData from '../NoData';
import CustomViewerModal from "./CustomViewerModal";
import CustomViewerEditModal from "./CustomViewerEditModal";
import DataTableExtensions from "../ExportTable/index";
import "../ExportTable/index.css";
import './../CustomDataTableExtensions.css';

const CustomViewers = (props) => {
    const navigate = useNavigate();
    //const [edcUserID, setEDCUserID] = useState('');
    //const [readType, setReadType] = useState('');
    const [rowData, setData] = useState([]);
    //const [notice, setNotice] = useState([]);
    //const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA

    useEffect(() => {
        //getUserID();
        //getUserNameAndRoleByID();
        fetchGetCustomViewer();
    }, []);

    //const getUserNameAndRoleByID = () => {
    //    fetch('api/Users/getUserNameAndRoleByID')
    //        .then(response => response.json())
    //        .then(data => {
    //            fetchGetCustomViewer();
    //        });
    //};
    //const getUserID = () => {
    //    fetch('api/Users/GetEDCUserID')
    //        .then(response => response.text())
    //        .then(data => {
    //            setEDCUserID(data);
    //        });
    //};
    //const setCompPermission = (usrRole) => {
    //    usrRole = usrRole.trim();
    //    if (usrRole == "Read-Only")
    //        setUserPermisssion("RO");
    //    else if (usrRole == "Database Administrator")
    //        setUserPermisssion("RO");
    //    else if (usrRole == "Clinical Project Manager")
    //        setUserPermisssion("RO");
    //    else if (usrRole == "Auditor")
    //        setUserPermisssion("RO");
    //    else if (usrRole == "Tester")
    //        setUserPermisssion("RW");
    //    else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator")
    //        setUserPermisssion("RW");
    //    else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management")
    //        setUserPermisssion("RO");
    //    else
    //        setUserPermisssion("RO");
    //}
    const fetchGetCustomViewer = () => {
        fetch('api/CustomViewer/GetCustomViewersList')
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    const selectRef = useRef('');
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');

    const columns = [
        {
            name: "SourceTypeID",
            selector: "SourceTypeID",
            omit: true
        },
        {
            name: "Source Type",
            selector: "SourceTypeLabel",
            sortable: true,
            center: true,
            width: "20%"
        },
        {
            name: "Description",
            selector: "SourceDescription",
            sortable: true,
            center: true,
            width: "40%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.SourceDescription}</div>
            )
        },
        {
            name: "Custom Source",
            selector: "CustomSource",
            sortable: true,
            center: true,
            width: "10%",
            cell: (row) => (
                <div> {row.CustomSource == "Y" && <label> Yes </label>} {row.CustomSource == "N" && <label> No </label>}</div>
            )
        },
        {
            name: "ReadTypesID",
            selector: "ReadTypesID",
            omit: true
        },
        {
            name: "Read Type",
            selector: "ReadType",
            sortable: true,
            center: true,
            width: "10%"
        },
        {
            name: "Enabled",
            selector: "Enabled",
            sortable: true,
            center: true,
            width: "10%",
            cell: (row) => (
                <div> {row.Enabled == "Y" && <label> Yes </label>} {row.Enabled == "N" && <label> No </label>}</div>
            )
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '10%',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
                'justify-content': 'left'
            },                        
        cell: (row) => (
            <div style={{ "padding": "5px" }}>
                <CustomViewerEditModal header="Edit Custom Viewer" SourceTypeID={row.SourceTypeID} CustomSource={row.CustomSource}
                    SourceTypeLabel={row.SourceTypeLabel} SourceDescription={row.SourceDescription} ReadTypesID={row.ReadTypesID} Enabled={row.Enabled}
                    fetchGetCustomViewer={fetchGetCustomViewer} ></CustomViewerEditModal>
                {
                    row.CustomSource == "Y" && 
                    <Button id="btnDelete" variant="primary" className="btn btn-danger" style={{ "margin": "5px" }} onClick={() => handleDeleteViewer(row.SourceTypeID, event)}>Delete</Button>
                }
                </div>
        )
        }
    ];
    

    const handleDeleteViewer = (stId, event) => {
        var con = confirm('Are you sure, you want to delete selected viewer?');
        if (con == true) {
            DeleteViewer(stId);
        }
    };

    function DeleteViewer(srcTypeId) {
        let objCustomViewerModal = {
            SourceTypeID: srcTypeId
        };

        fetch('api/CustomViewer/DeleteCustomViewer', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objCustomViewerModal)
        }).then(r => r.text()).then(res => {
            if (res == "success") {
                fetchGetCustomViewer();
            }
            else if (res == "notdeleted") {
                alert('Custom viewer not deleted, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })
    }

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (row[column] != null) {
                        if (searchText.length > 1)
                            return searchText.indexOf(row[column].toString().toLowerCase()) > -1;
                        else
                            return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1;
                    }
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
        selectRef.current.resetSelectedValues();
    };

 
    return (
        <Card>

            <Row className="mt-2 mb-2" >
                <Col md={6}>
                    <div className="input-group">
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="input-group" />
                </Col>
               
                <Col md={2}>
                    <div style={{ "float": "right" }}>
                        <CustomViewerModal header="Add New Custom Viewer" fetchGetCustomViewer={fetchGetCustomViewer} />
                    </div>
                </Col>
            </Row>
            <div className="tableContainer ">
                {search(rowData).length > 0 ?
                    <DataTableExtensions columns={columns} data={search(rowData)} filter={false} fileName="CustomViewers">
                        <DataTable
                            className="table table-striped table-bordered table-hover"
                            customStyles={customStyles}
                            striped
                            pagination
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[20, 50, 100]}
                        />
                    </DataTableExtensions> : <NoData headers={columns}></NoData>}
            </div>
        </Card>
    );
};
export default CustomViewers;