import React from 'react';
import { useSelector } from 'react-redux'
import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import InputRadio from '../UI/Control/InputRadio';
import TextArea from '../UI/Control/InputTextarea';
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const QCComplete = (props) => {
    const navigate = useNavigate();
    const taskId = props.taskId;
    const EDCUserID = useSelector(state => state.auth.gValue01);

    const SaveQCComplete = (event) => {
        let objNote = document.getElementById('QCCompletNote');
        //if (objNote.value.trim() == '') {
        //    alert('Notes should not be empty.');
        //    objNote.focus();
        //    return false;
        //}

        let objVideoQCed = document.querySelector("input[name=VideoQCed]:checked");
        if (objVideoQCed == null) {
            alert('Please select Video QC\'d.');
            objVideoQCed.focus();
            return false;
        }

        let objTestVidApproved = document.querySelector("input[name=TestVidApproved]:checked");
        if (objTestVidApproved == null) {
            alert('Please select test video approved.');
            objTestVidApproved.focus();
            return false;
        }

        let objCorrectProtocol = document.querySelector("input[name=CorrectProtocol]:checked");
        if (objCorrectProtocol == null) {
            alert('Please select correct protocol ID.');
            objCorrectProtocol.focus();
            return false;
        }

        let objCorrectSubject = document.querySelector("input[name=CorrectSubject]:checked");
        if (objCorrectSubject == null) {
            alert('Please select correct subject format.');
            objCorrectSubject.focus();
            return false;
        }

        let objIdentifiersVisible = document.querySelector("input[name=IdentifiersVisible]:checked");
        if (objIdentifiersVisible == null) {
            alert('Please select potential identifiers/faces visible.');
            objIdentifiersVisible.focus();
            return false;
        }

        let objMaskingApplied = document.querySelector("input[name=MaskingApplied]:checked");
        if (objMaskingApplied == null) {
            alert('Please select masking applied.');
            objMaskingApplied.focus();
            return false;
        }

        let objLandmarks = document.querySelector("input[name=Landmarks]:checked");
        if (objLandmarks == null) {
            alert('Please select landmarks.');
            objLandmarks.focus();
            return false;
        }

        let objMoveNextStage = document.querySelector("input[name=MoveNextStage]:checked");
        if (objMoveNextStage == null) {
            alert('Please select Move to next stage.');
            objMoveNextStage.focus();
            return false;
        }
        let objCIMSDataUpdated = document.querySelector("input[name=CIMSDataUpdated]:checked");
        if (objCIMSDataUpdated == null) {
            alert('Please select Was CIMS Data Updated.');
            objCIMSDataUpdated.focus();
            return false;
        }

        let qcModel = {
            TaskID: taskId,
            QCCompletNote: objNote.value,
            VideoQC: objVideoQCed.value,
            TestVidApproved: objTestVidApproved.value,
            CorrectProtocol: objCorrectProtocol.value,
            CorrectSubject: objCorrectSubject.value,
            IdentifiersVisible: objIdentifiersVisible.value,
            MaskingApplied: objMaskingApplied.value,
            Landmarks: objLandmarks.value,
            MoveNextStage: objMoveNextStage.value,
            CIMSDataUpdated: objCIMSDataUpdated.value,
            CreationUser: EDCUserID
        };

        fetch('api/ImageService/SaveQCComplete', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(qcModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('QC not completed, Something went wrong.');
            }
            if (res > 0) {
                createSubject();
                props.relaodAction();
            }
        })
    };
    const createSubject = async () => {
        const response = await fetch('api/ServiceCall/CreateSubject?TaskId=' + taskId + '&CreationUser=' + EDCUserID,
            {
                method: "POST",
                mode: 'cors'
            }
        )
        let retData = await response.text();        
        if (retData.trim() != "\"SubjectCreated\"") {
            alert("Error while creating subject: " + retData);
        }        
    }
    return (
        <div>
        <Row className="mt-2 mb-2">
                <Col md={6}>
                <Row>
                    <Col md={2}>
                            Notes:
                    </Col>
                    <Col md={10}>
                        <TextArea rows='12' cols='60' id="QCCompletNote" />
                    </Col>
                </Row>
                </Col>
                <Col Col={6}>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Video QC'd <MandatoryLabel />:
             </Col>
                        <Col md={6}>
                            <InputRadio id="VideoQCedYes" orientation='horizontal' value="Y" name="VideoQCed" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="VideoQCedNo" orientation='horizontal' value="N" name="VideoQCed" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Test Video Approved <MandatoryLabel />:
             </Col>
                        <Col md={6}>
                            <InputRadio id="TestVidApprovedYes" orientation='horizontal' value="Y" name="TestVidApproved" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="TestVidApprovedNo" orientation='horizontal' value="N" name="TestVidApproved" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <a style={{ "margin-left": "45px", display:"none" }} href='#'>Link to Test Video</a>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Correct Protocol ID <MandatoryLabel />:
             </Col>
                        <Col md={6}>
                            <InputRadio id="CorrectProtocolYes" orientation='horizontal' value="Y" name="CorrectProtocol" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="CorrectProtocolNo" orientation='horizontal' value="N" name="CorrectProtocol" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Correct Subject Format <MandatoryLabel />:
             </Col>
                        <Col md={6}>
                            <InputRadio id="CorrectSubjectYes" orientation='horizontal' value="Y" name="CorrectSubject" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="CorrectSubjectNo" orientation='horizontal' value="N" name="CorrectSubject" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Potential Identifiers/Faces Visible <MandatoryLabel />:
             </Col>
                        <Col md={6}>
                            <InputRadio id="IdentifiersVisibleYes" orientation='horizontal' value="Y" name="IdentifiersVisible" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="IdentifiersVisibleNo" orientation='horizontal' value="N" name="IdentifiersVisible" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Masking Applied <MandatoryLabel />:
             </Col>
                        <Col md={6}>
                            <InputRadio id="MaskingAppliedYes" orientation='horizontal' value="Y" name="MaskingApplied" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="MaskingAppliedNo" orientation='horizontal' value="N" name="MaskingApplied" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Landmarks <MandatoryLabel />:
                        </Col>
                        <Col md={6}>
                            <InputRadio id="LandmarksYes" orientation='horizontal' value="Y" name="Landmarks" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="LandmarksNo" orientation='horizontal' value="N" name="Landmarks" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>                       
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Move to next stage? <MandatoryLabel />
                        </Col>
                        <Col md={6}>
                            <InputRadio id="MoveNextYes" orientation='horizontal' value="Y" name="MoveNextStage" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="MoveNextNo" orientation='horizontal' value="N" name="MoveNextStage" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>                      
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md={4}>
                            Was CIMS Data Updated? <MandatoryLabel />
                        </Col>
                        <Col md={6}>
                            <InputRadio id="CIMSDataUpdatedYes" orientation='horizontal' value="Y" name="CIMSDataUpdated" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                            <InputRadio id="CIMSDataUpdatedNo" orientation='horizontal' value="N" name="CIMSDataUpdated" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }}></InputRadio>
                        </Col>
                        <Col md={2}>
                            <Button className="btn-success" onClick={SaveQCComplete} > Submit </Button>
                        </Col>
                    </Row>
                </Col>
        </Row>

        <Row className="mt-2 mb-2">
                <Col md={12} style={{ "text-align": "center" }}>
                   
            </Col>
            </Row>
        </div>
    );
}
export default QCComplete;
