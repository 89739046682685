import React from 'react';
import customStyles from './customStyles';
//import classes from './Card.module.css';

const NoData = (props) => {
    return (
        <div className="tableContainer">
            <div class="sc-gsnTZi idPHNo rdt_TableHead" role="rowgroup">
                <div class="sc-dkzDqf gZWIKX rdt_TableHeadRow" role="row">
                    {
                        props.headers.map(header => {
                            if (typeof header.omit == 'undefined' || header.omit == false) {
                                return <div data-column-id="1" class="sc-hKMtZM sc-eCYdqJ sc-iqcoie eaRkvd kSJejC crSOFH rdt_TableCol">
                                    <div data-column-id="1" data-sort-id="1" role="columnheader" tabindex="0" class="sc-crXcEl gJhVev rdt_TableCol_Sortable">
                                        <div data-column-id="1" class="sc-evZas eUeqdG">{header.name}</div>
                                    </div>
                                </div>
                            }
                        })
                    }
                </div>
            </div>
            <div id="row-0" className="sc-jqUVSM kUlZxW rdt_TableRow" style={{ border: "solid", borderWidth: "thin", borderColor: "silver", textAlign: "center", display: "block" }}>
                <div style={{ marginTop:"10px" }}>
                    No data found
                </div>    
            </div>
            </div>
    );
};

export default NoData;
