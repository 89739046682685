import React, { useState} from 'react';
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import MultiSelectList from '../UI/Control/MultiSelectList';
import { Button } from "react-bootstrap";
import CRFInfoMapping from "./CRFInfoMapping"
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import { useSelector } from 'react-redux'

const ReadDetailsCRF = (props) => {
    const [arrEDCeCRFs, setEDCeCRFsTemp] = useState([]);
    const [EDCeCRFs, setEDCeCRFs] = useState([{}]);
    const [inputVal, setInputVal] = useState('');
    const [readInfoCRFID, setReadInfoCRFID] = useState("0");
    const [sourceTypeId, setSourceTypeId] = useState("0");
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [arrCRFMapping, setCRFMapping] = useState([]);
    const [formId, setFormId] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setInputVal('');
        fetchEDCFormsLib().catch((error) => {
            console.log(error.message)
        });
        fetchGetReadInfoCRFID();
        setShow(true);
    };

    const fetchEDCFormsLib = async () => {
        const response = await fetch('api/ServiceCall/GetEDCFormLib')
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const edcForms = await response.text();
        let listForms = JSON.parse(edcForms);
        const arrEDCForms = new Array();
        for (var iCount = 0; iCount < listForms.length; iCount++) {
            arrEDCForms.push({ Name: listForms[iCount].FormName, Value: listForms[iCount].FormID })
        }
        setEDCeCRFsTemp(arrEDCForms);
        setEDCeCRFs(arrEDCForms);
    }

    const searchChangeHandlerReadInfo = (e) => {
        let arrEDC = EDCeCRFs.filter(item => item.Name.toLowerCase().includes(e.target.value.toLowerCase()));
        setEDCeCRFsTemp(arrEDC);
        setInputVal(e.target.value);
    }

    const readInfoCRFChangeHandler = () => {
        let ReadInfoCRFID = document.getElementById('multiSelectReadDetails').value == '' ? 0 : document.getElementById('multiSelectReadDetails').value;
        setReadInfoCRFID(ReadInfoCRFID);
    }

    const fetchGetReadInfoCRFID = () => {
        fetch('api/ReadSettings/GetReadInfoCRFID?studyProtocolId=' + props.studyProtocolID + '&studyReadSettingsID=' + props.studyReadSettingsID)
            .then(response => response.json())
            .then(data => {
                setReadInfoCRFID(data.ReadInfoCRFID);
                setSourceTypeId(data.SourceTypeID);
            });
    }

    const UpdateReadDetailsCRF = (event) => {

        const objMultiSelectReadDetails = document.getElementById('multiSelectReadDetails');
        if (objMultiSelectReadDetails.value.trim() == '') {
            alert('Please select read info CRF to submit.');
            objMultiSelectReadDetails.focus();
            return false;
        }

        let readSettingsModel =
        {
            StudyReadSettingsID: props.studyReadSettingsID,
            ReadInfoCRFID: document.getElementById('multiSelectReadDetails').value == '' ? 0 : document.getElementById('multiSelectReadDetails').value,
            Username: EDCUserID,
            listCRFMapping: arrCRFMapping,
        }

        fetch('api/ReadSettings/UpdateReadDetailsCRF', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(readSettingsModel)
        }).then(r => r.text()).then(res => {
            props.fetchReadSettingData();
            setShow(false);
        })
    };

    return (
        <>
            <a style={{ color: props.IsReadInfo === "No" ? "red" : "green", "cursor": "pointer", "text-align": "center" }}
                onClick={handleShow}><u><b>{props.text}</b></u></a>
            {/*<button className={props.btnCss} type="button" onClick={handleShow}> {props.text} </button>*/}

            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Eleven} animation={false} backdrop="static">
                <Modal.Header className={classes.modalHeader}>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        <Modal.Title>{props.header}</Modal.Title>
                        </div>
            </Modal.Header>
                <Modal.Body>
                    <div class="form-group" style={{ marginTop: "10px" }}>
                        <InputString id="txtRDSearch" value={inputVal} onChangeHandler={searchChangeHandlerReadInfo} placeholder="Search" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }}></InputString>
                    </div>
                    <div class="form-group" style={{ marginTop: "10px" }}>
                        <MultiSelectList onChangeHandler={readInfoCRFChangeHandler} id="multiSelectReadDetails" dataSource={arrEDCeCRFs} isMultiSelect="false" size="8" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} selectedData={readInfoCRFID} disabled={props.isDisabled}></MultiSelectList>
                    </div>
                    <div class="form-group" style={{ marginTop: "10px", display:"none" }}>
                        <label for="selectReadDetails">Select CRF Version</label>
                        <SelectList id="selectReadDetails" dataSource={props.arrEDCeCRFs} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }}></SelectList>
                    </div>
                    <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-2" style={{ color: "red", paddingRight: "0px" }}>
                            <CRFInfoMapping action={props.action} CFRFormID={readInfoCRFID} sourceType={sourceTypeId} studyReadSettingId={props.studyReadSettingsID} setCRFMapping={setCRFMapping}
                                arrCRFMapping={arrCRFMapping} setFormId={setFormId} formId={formId} disabled={props.disabled} isDisabled={props.isDisabled}></CRFInfoMapping>
                        </div>
                        <div className="col-md-9" style={{ color: "red", paddingLeft: "10px", fontSize:"14px" }}>
                            Note : Mapping details will be saved on submission of read-info details only.
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        {!props.disabled && props.action == "copy" && <Button className="btn btn-success" onClick={CopyReadsetting} style={{ marginRight: "5px" }}> Copy </Button>}
                        {!props.disabled && (props.action == "add" || props.action == "edit") && <Button variant="secondary" className="btn btn-success" style={{ marginRight: "5px" }} onClick={UpdateReadDetailsCRF} disabled={props.isDisabled}> Submit </Button>}
                <Button variant="primary" className="btn btn-danger" onClick={handleClose}>
                    Cancel
                            </Button>
                        </div>
            </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReadDetailsCRF;
