import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
const TaskHistory = (props) => {
    return (
        <div style={{ "margin-left": "12px", "margin-right": "12px", "margin-top": "0px" }}>
            {props.historyData.map((item) => {
                return (<div>
                    <Row style={{ "height": "40px" }}>
                        <Col className={classes.cellBorder} style={{ "display": "inline-flex", borderRight: "none" }} md={2}>
                            <div style={{"margin-right": "50px" }}><b>User ID:</b></div>
                            {item.UserID}
                        </Col>
                        <Col className={classes.cellBorder} style={{ "display": "inline-flex", borderLeft: "none" }}>
                            <div style={{ "margin-left": "150px", "margin-right": "50px" }}><b>Date:</b></div>
                            {item.Date}
                        </Col>
                    </Row>
                    <Row style={{ minHeight: "40px", "height": "auto" }}>
                        <Col className={classes.cellRLBBorder} md={2}>Task Audit</Col>
                        <Col className={classes.cellRightBorder}>{item.TaskAudit}</Col>
                    </Row>
                    <Row style={{ "margin-bottom": "12px", minHeight:"40px", "height":"auto"}}>
                        <Col className={classes.cellRLBBorder} md={2}>Task Comments</Col>
                        <Col className={classes.cellRightBorder}>{item.Comment}</Col>
                    </Row> 
                </div>);
            })}
        </div>
    );
}

export default TaskHistory;