import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import InputString from '../UI/Control/InputString';
import classes from './../TrialstatNoto.module.css';
import ToggleButton from '../UI/Button/ToggleButton';
import SelectList from '../UI/Control/SelectList';

const MoveTaskModal = (props) => {
    const [show, setShow] = useState(false);
    const [rowData, setData] = useState([]);
    const [arrSiteCode, setArrSiteCode] = useState([]);
    const [arrVisit, setArrVisit] = useState([]);
    const [subjectCheck, setSubjectCheck] = useState('N');
    const [centerCheck, setCenterCheck] = useState('N');
    const [visitCheck, setVisitCheck] = useState('N');
    const handleClose = () => setShow(false);
    const handleShow = () => {
        FillSiteCodeList();
        FillVisitList();
        setSubjectCheck('N');
        setCenterCheck('N');
        setVisitCheck('N');
        setShow(true);
    };

    const onToggleChange = (toggleType, e) => {
        if (e.target.checked == true) {
            if (toggleType == 'subjectId') {
                document.getElementById('txtSubjectId').disabled = false;
                setSubjectCheck('Y');
            }
            else if (toggleType == 'siteId') {
                document.getElementById('ddlSite').disabled = false;
                setCenterCheck('Y');
            }
            if (toggleType == 'visit') {
                document.getElementById('ddlVisit').disabled = false;
                setVisitCheck('Y');
            }
        }
        else {
            if (toggleType == 'subjectId') {
                document.getElementById('txtSubjectId').disabled = true;
                document.getElementById('txtSubjectId').value = '';
                setSubjectCheck('N');
            }
            else if (toggleType == 'siteId') {
                document.getElementById('ddlSite').disabled = true;
                document.getElementById('ddlSite').value = '';
                setCenterCheck('N');
            }
            if (toggleType == 'visit') {
                document.getElementById('ddlVisit').disabled = true;
                document.getElementById('ddlVisit').value = '';
                setVisitCheck('N');
            }
        }
    }

    const FillSiteCodeList = () => {
        fetch('api/ImageService/FillSiteCodeList?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '--Select Center--' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setArrSiteCode(arr);
            });
    }

    const FillVisitList = () => {
        fetch('api/ImageService/FillVisitList?taskId=' + props.taskId)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '--Select Visit--' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setArrVisit(arr);
            });
    }

    const saveMovedTask = (event) => {
        let objSubjectId = document.getElementById('txtSubjectId');       
        if (subjectCheck == 'Y' && objSubjectId.value.trim() == '') {
            alert('New value for subject ID should not be empty.');
            objSubjectId.focus();
            return false;
        }

        let objSiteId = document.getElementById('ddlSite');
        if (centerCheck == 'Y' && objSiteId.value == '') {
            alert('Please select new value for Site.');
            objSiteId.focus();
            return false;
        }

        let objVisit = document.getElementById('ddlVisit');
        if (visitCheck == 'Y' && objVisit.value == '') {
            alert('Please select new value for visit.');
            objVisit.focus();
            return false;
        }

        let objNotoSubjectDetails = {
            TaskID: props.taskId,
            SubjectCheck: subjectCheck,
            NewSubject: objSubjectId.value,
            CenterCheck: centerCheck,
            NewCenter: objSiteId.value,
            VisitCheck: visitCheck,
            NewVisit: objVisit.value,
        };
        fetch('api/ImageService/MoveTask', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objNotoSubjectDetails)
        }).then(r => r.text())
            .then(res => { 
                setSubjectCheck('N');
                setCenterCheck('N');
                setVisitCheck('N');
                if (res == "\"1\"") {
                    alert('MOVE SUCCESSFUL');
                    setShow(false);
                }
                else if (res == "\"0\"") {
                    alert('ERROR, CONTACT SUPPORT');
                    setShow(false);
                }
                else if (res == "\"2\"") {
                    alert('DATA EXISTS IN DESTINATION VISIT, CANNOT MOVE');
                    setShow(false);
                }
                else if (res == "\"3\"") {
                    alert('CENTER NOT VALID');
                    setShow(false);
                }
                else if (res == "\"4\"") {
                    alert('VISIT NOT VALID');
                    setShow(false);
                }
                else if (res == "\"4\"") {
                    alert('SUBJECT ID DEACTIVATED');
                    setShow(false);
                }
                else {
                    alert('Unhandled Error, on move task.');
                    setShow(false);
                }
        })
    };

    return (
        <>
            <Button className="btn btn-danger" style={props.style} onClick={handleShow}> {props.text} </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Fourteen} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center", display: "block" }}>Move a Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: "center" }}>
                        <div className="row">
                            <div className="col-sm-8" style={{ textAlign: "left", paddingLeft: "20px" }}>
                                <h6><b>Task :</b> {props.taskId}</h6>
                            </div>
                        </div>
                        <div style={{ overflowY: "auto", maxHeight: "290px" }}>
                            <table className="table table-bordered" style={{ marginTop: "10px", width: "100%" }}>
                                <thead>
                                    <tr className={classes.tbHeaderCaptionDark}>
                                        <td></td>
                                        <td>Current</td>
                                        <td>Move?</td>
                                        <td>New Value</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "20%" }}><b>Subject ID</b></td>
                                        <td style={{ width: "20%" }}>{props.subjectId}</td>
                                        <td style={{ width: "10%" }}> <ToggleButton onToggleChange={e => onToggleChange('subjectId',e)} /></td>
                                        <td style={{ width: "30%" }}>
                                            <InputString id="txtSubjectId" maxlength="255" disabled></InputString>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "20%" }}><b>Site ID</b></td>
                                        <td style={{ width: "20%" }}>{props.siteId}</td>
                                        <td style={{ width: "10%" }}> <ToggleButton onToggleChange={e => onToggleChange('siteId',e)} /></td>
                                        <td style={{ width: "30%" }}>
                                            <SelectList id="ddlSite" dataSource={arrSiteCode} disabled/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "20%" }}><b>Visit</b></td>
                                        <td style={{ width: "20%" }}>{props.visit}</td>
                                        <td style={{ width: "10%" }}> <ToggleButton onToggleChange={e => onToggleChange('visit',e)} /></td>
                                        <td style={{ width: "30%" }}>
                                            <SelectList id="ddlVisit" dataSource={arrVisit} disabled />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSave" className="btn btn-md btn-success" variant="primary" onClick={saveMovedTask} disabled={subjectCheck == "Y" || centerCheck == "Y" || visitCheck == "Y" ? false : true}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default MoveTaskModal;