import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputString from '../UI/Control/InputString';
import InputTextarea from '../UI/Control/InputTextarea';
import InputCheckbox from '../UI/Control/InputCheckbox';
import SelectList from '../UI/Control/SelectList';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import customStyles from '../customStyles';
import classes from './../TrialstatNoto.module.css';
const CustomViewerEditModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [readTypeId, setReadTypeId] = useState(false);

    const [arrReadType, setReadType] = useState([]);
    
    const handleShow = () => {
        FillReadType();
        setReadTypeId(props.ReadTypesID);
  
        setShow(true);
    }

    const FillReadType = () => {
        fetch('api/CustomViewer/FillReadTypeList')
            .then(response => response.json())
            .then(data => {
                setReadType(data);
                setData();
            });
    }

    function setData() {
        document.getElementById("txtSourceLabel").value = props.SourceTypeLabel;
        document.getElementById("txtSourceDescription").value = props.SourceDescription;
        document.getElementById("ddlReadType").value = props.ReadTypesID;
        if (props.Enabled == 'Y') {
            document.getElementById('chkEnabled').checked = true;
        }

        if (props.CustomSource == "N") {
            document.getElementById("txtSourceLabel").disabled = true;
            
            if (props.SourceTypeLabel.toLowerCase() == "cims" || props.SourceTypeLabel.toLowerCase() == "lucidity") {
                document.getElementById("ddlReadType").disabled = true;
                document.getElementById('chkEnabled').disabled = true;
            }
        } 
    }

    const SaveCustomViewer = (event) => {

        let objSourceLabel = document.getElementById('txtSourceLabel');
        if (objSourceLabel.value.trim() == '') {
            alert('Source label should not be empty.');
            objSourceLabel.focus();
            return false;
        }

        //let objSourceDesc = document.getElementById('txtSourceDescription');
        //if (objSourceDesc.value.trim() == '') {
        //    alert('Source description should not be empty.');
        //    objSourceLabel.focus();
        //    return false;
        //}

        let objDDList = document.getElementById("ddlReadType");
        if (objDDList.value == '-1') {
            alert('Please select the Read Type.');
            objDDList.focus();
            return false;
        }

        var cvEnabled = document.getElementById('chkEnabled').checked ? 'Y' : 'N';

        let objCustomViewerModal = {
            SourceTypeID: props.SourceTypeID ,
            SourceTypeLabel: document.getElementById('txtSourceLabel').value.trim(),
            SourceDescription: document.getElementById('txtSourceDescription').value.trim(),
            ReadTypesID: document.getElementById("ddlReadType").value,
            Enabled: cvEnabled
        };

        fetch('api/CustomViewer/SaveCustomViewer', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objCustomViewerModal)
        }).then(r => r.text()).then(res => {
            if (res == "success") {
                setShow(false);
                props.fetchGetCustomViewer();
            }
            else if (res == "duplicate") {
                alert('Duplicate found!');
                return false;
            }
            else if (res == "notsaved") {
                alert('Custom viewer not saved, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })
    };

    return (
        <>
            <Button id="btnEdit" variant="primary" className="btn btn-success" onClick={handleShow}>Edit</Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Five} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-4">
                            <label>Source Label<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-6">
                            <InputString id="txtSourceLabel"  />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-4">
                            <label>Source Description:</label>
                        </div>
                        <div className="col-sm-6">
                            <InputTextarea id="txtSourceDescription" />                            
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-4">
                            <label>Read Type<MandatoryLabel />:</label>
                        </div>
                        <div className="col-sm-6">
                            <SelectList id='ddlReadType'  dataSource={arrReadType} style={{ marginBottom: "10px" }} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1" />
                        <div className="col-sm-4">
                            <label>Enabled:</label>
                        </div>
                        <div className="col-sm-6">
                            <InputCheckbox id="chkEnabled" name="chkEnabled" />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={SaveCustomViewer}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default CustomViewerEditModal;