import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Login from './components/Login';
import Home from './components/Home';
import { authAction } from './app/auth';
import SecretQuestion from '../src/components/Admin/SecretQuestion';
import ServiceAgreement from '../src/components/Admin/ServiceAgreement';
import ChangePassword from '../src/components/UserDetails/ChangePassword';
import { useNavigate } from 'react-router-dom';

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const IsSessionExist = () => {
        fetch('api/Users/IsSessionExist')
            .then(response => response.text())
            .then(data => {
                if (data == "false") {
                    dispatch(authAction.setUserAuth({
                        isUserAuth: false
                    }));
                }
                else {
                    dispatch(authAction.setUserAuth({
                        isUserAuth: true
                    }));
                }
            });
    };
    IsSessionExist();
    const isUserAuth = useSelector(state => state.auth.bValue01);
    const flagSecQues = useSelector(state => state.auth.bValue02);
    const flagAcceptAggr = useSelector(state => state.auth.bValue03);
    const [isPassExpired, setIsPassExpired] = useState(false);
    const [isCancelUpdatePassword, setIsCancelUpdatePassword] = useState(false);
    const [userName, setUserName] = useState('');
    const [uType, setUType] = useState('');
    
    const lucUpdateInterval = useRef(0);
    const cimsUpdateInterval = useRef(0);   
    let lucUpdateTimer = null;
    let lucReconTimer = null;
    let cimsUpdateTimer = null;
    let cimsReconTime = null;

    const handleTabClose = event => {
        dispatch(authAction.logout());        
        event.returnValue = ''
        return;  
    };   

    useEffect(() => {
        getQueryVariable();
        //CIMS Reconciliationa dn Update.
        cimsReconScheduler();
        //Lucidity Reconciliationa dn Update.
        LucReconScheduler();
        window.addEventListener('unload', handleTabClose);
    }, []);
    
    function getQueryVariable() {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        var pair = vars[0].split("=");
        if (pair[0] == 'NOTOReadType') {
            localStorage.removeItem('isFisrtTimeLoading');
            const readType = pair[1];
            if (readType.toLowerCase() == 'endo') {
                dispatch(authAction.login({
                    isUserAuth: true,
                    isSecQues: true,
                    isAcceptAggr: true
                }));
            }
            else if (readType.toLowerCase() == 'histo') {
                dispatch(authAction.login({
                    isUserAuth: true,
                    isSecQues: true,
                    isAcceptAggr: true
                }));
            }
        }
    } 
    const fetchEDCProject = async (usrName, passWrd) => {  
        let UserLogin = {
            Username: usrName,
            Password: passWrd
        };
        //const response = await fetch('api/ServiceCall/authNOTOUser?usrName=' + usrName + '&passWrd=' + passWrd,
        const response = await fetch('api/ServiceCall/authNOTOUser',
            {
                method: "POST",
                headers: { 'Content-type': 'application/json'},
                body: JSON.stringify(UserLogin)
            }
        )
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const userAuth = await response.text();
        return userAuth;
    }
    
    const idleTime = () => {
        fetch('api/AppSettings/IdleTimeOut')
            .then(res => res.text())
            .then(duration => {
                localStorage.setItem("_expiredTime", Date.now() + (duration * 60 * 1000));
            });
    }

    function dispatchLogout() {
        dispatch(authAction.logout());
        navigate('./Login');
    }; 

    const saveLoginState = (useName, userType) => {
        fetch('api/Users/GetUserByUsername?username=' + useName)
            .then(response => response.json())
            .then(data => {
                EDCLoginDetails(data.EDCUserID, useName, userType);
                idleTime();
            });
    }
    const loginHandler = (useName, password, userType) => {  
        setIsPassExpired(false);
        setIsCancelUpdatePassword(false);
        setUserName(useName);
        setUType(userType);
        fetchEDCProject(useName, password)
            .then((res) => {
                res = res.replace('"', '');
                res = res.replace('"', '');
               
                if (res === "authenticated") {
                    saveLoginState(useName, userType);
                }
                else if (res === "passExpired") {
                    setIsPassExpired(true);
                }
                else {
                    alert(res);
                }
            })
            .catch((error) => {
                console.log(error.message)
            })
    };

    const logoutHandler = () => {       
        dispatch(authAction.logout());
    };

    //const idleTime = () => {
    //    fetch('api/AppSettings/IdleTimeOut')
    //        .then(res => res.text())
    //        .then(duration => {
    //            localStorage.setItem("_expiredTime", Date.now() + (duration * 60 * 1000));
    //        });
    //}
    const EDCLoginDetails = (EDCUserID, useName, userType) => {
        let objEDCUsersModel = {
            UserID: EDCUserID
        };

        fetch('api/Users/EDCLoginDetails', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objEDCUsersModel)
        }).then(res => res.json()).then(data => {
            const obj = JSON.parse(data);

            //  idleTime();
            EDCUserID = data.EDCUserID;
            dispatch(authAction.login({
                isUserAuth: true,
                isSecQues: obj.SecretQuestionReset == 'N' ? false : true,
                isAcceptAggr: obj.ClickThroughDate == '' ? false : true
            }));
            flagSecQues = obj.SecretQuestionReset == 'N' ? false : true;
            flagAcceptAggr = obj.ClickThroughDate == '' ? false : true;
        });
    };

    const setSecQuesTrue = () => {
        dispatch(authAction.login({
            isUserAuth: true,
            isSecQues: true,
            isAcceptAggr: flagAcceptAggr == true ? true : false 
        }));
    };

    const setAccAgrTrue = () => {
        dispatch(authAction.login({
            isUserAuth: true,
            isSecQues: true,
            isAcceptAggr: true
        }));
    };
    //Lucidity import Update code
    const LucUpdateScheduler = () => {
        fetch('api/LucidityServicesCall/LucidityUpdateTimer')
            .then(response => response.text())
            .then(data => {
                lucUpdateInterval.current = data;
                if (lucUpdateTimer == null) {
                    let lucIntervalMS = (60000 * lucUpdateInterval.current);
                    lucUpdateTimer = setInterval(callLucidityUpdateAPI, lucIntervalMS);
                }               
            });
    };   
    const callLucidityUpdateAPI = () => {
        fetch('api/LucidityServicesCall/ImportLucidity?callType=Update')
            .then(response => response.text())
            .then(data => {
                if (data == "success") {                    
                }
                else if (data == "get_study_error") {
                    alert('Error while getting Lucidity studies; for details, look into the ErrorLog Table.')
                }
                else if (data == "case_import_error") {
                    alert('Error while importing Lucidity cases; for details, look into the ErrorLog Table.')
                }
            });
    };
    //Lucidity import Reconciliation code
    const LucReconScheduler = () => {
        fetch('api/LucidityServicesCall/LucidityReconTimer')
            .then(response => response.text())
            .then(lucReconData => {
                callLucidityReconAPI();
                if (lucReconTimer == null) {                    
                    let lucIntervalMS = (3600000 * lucReconData);
                    lucReconTimer = setInterval(callLucidityReconAPI, lucIntervalMS);
                }
                LucUpdateScheduler();
            });
    };
    const callLucidityReconAPI = () => {
        fetch('api/LucidityServicesCall/ImportLucidity?callType=Reconciliation')
            .then(response => response.text())
            .then(data => {
                if (data == "success") { }
                else if (data == "get_study_error") {
                    alert('Error while getting Lucidity studies(Recon); for details, look into the ErrorLog Table.')
                }
                else if (data == "case_import_error") {
                    alert('Error while importing Lucidity cases(Recon); for details, look into the ErrorLog Table.')
                }
            });
    };

    //CIMS import Update/Reconciliation code
    const cimsUpdateScheduler = () => {
        fetch('api/CIMSServicesCall/CIMSUpdateTimer')
            .then(response => response.text())
            .then(data => {
                cimsUpdateInterval.current = data;
                if (cimsUpdateTimer == null) {
                    let cimsIntervalMS = (60000 * cimsUpdateInterval.current);
                    cimsUpdateTimer = setInterval(callCIMSUpdateAPI, cimsIntervalMS);
                }             
            });
    };
    const callCIMSUpdateAPI = () => {
        fetch('api/CIMSServicesCall/ImportCIMS?callType=Update')
            .then(response => response.text())
            .then(data => {
                if (data == "success") {}
                else if (data == "get_study_error") {
                    alert('Error while getting CIMS studies; for details, look into the ErrorLog Table.')
                }
                else if (data == "case_import_error") {
                    alert('Error while importing CIMS cases; for details, look into the ErrorLog Table.')
                }
            });
    };
    //CIMS import Reconciliation code
    const cimsReconScheduler = () => {
        fetch('api/CIMSServicesCall/CIMSReconTimer')
            .then(response => response.text())
            .then(cimsReconTimer => {
                callCIMSReconAPI();
                if (cimsReconTime == null) {
                    let cimsIntervalMS = (3600000 * cimsReconTimer);
                    cimsReconTime = setInterval(callCIMSReconAPI, cimsIntervalMS);
                }
                cimsUpdateScheduler();
            });
    };
    const callCIMSReconAPI = () => {
        fetch('api/CIMSServicesCall/ImportCIMS?callType=Reconciliation')
            .then(response => response.text())
            .then(data => {
                if (data == "success") {}
                else if (data == "get_study_error") {
                    alert('Error while getting CIMS studies; for details, look into the ErrorLog Table.')
                }
                else if (data == "case_import_error") {
                    alert('Error while importing CIMS cases; for details, look into the ErrorLog Table.')
                }
            });
    };

    return (

        <React.Fragment>
            <main>
                {!isUserAuth && (isPassExpired == false || isCancelUpdatePassword) && <Login onLogin={loginHandler} />}
                {isUserAuth && !flagSecQues && <SecretQuestion setSecQuesTrue={setSecQuesTrue} dispatchLogout={dispatchLogout} />}
                {isUserAuth && flagSecQues && !flagAcceptAggr && <ServiceAgreement setAccAgrTrue={setAccAgrTrue} dispatchLogout={dispatchLogout} />}
                {isUserAuth && flagSecQues && flagAcceptAggr && <Home onLogout={logoutHandler} />}
                {isPassExpired && <ChangePassword userName={userName} userType={uType} loginHandler={loginHandler} setIsPassExpired={setIsPassExpired} setIsCancelUpdatePassword={setIsCancelUpdatePassword} />}
                {/*window.removeEventListener('unload', handleTabClose)*/}
            </main>
        </React.Fragment>
    );
}
export default App;
