import React, { useState, useEffect } from 'react'
import { format } from 'date-fns';
import Card from './UI/Card/Card';
const AboutUs = () =>{
    const [aboutUs, setAboutUs] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [httpError, setHttpError] = useState(null)
    const [currentTime, setcurrentTime] = useState(format(new Date(), 'dd MMM yyyy kk:mm:ss'))

    useEffect(() => {
        const fetchVersion = async () => {
            const response = await fetch('/api/Home/AboutUsDetails')
               
            //const response = await fetch('https://10.12.10.150:8033/api/Home/AboutUsDetails',
            //    {
            //        mode: 'cors',
            //        headers: {
            //            'Access-Control-Allow-Origin': '*'
            //        }
            //    })
            if (!response.ok) {
                throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
            }
            const json = await response.json();        
            setAboutUs(json);           
        }       
        fetchVersion().catch((error) => {
            setIsLoading(false);
            setHttpError(error.message)
        })
        setIsLoading(false);        
    }, []);  

    setInterval(() => {
        setcurrentTime(format(new Date(), 'dd MMMM yyyy kk:mm:ss'))
    }, 1000)

    if (isLoading) {        
        return (
            <div className="row" style={{ "margin-top": "100px", "font-size": "large" }}>
                <p>Loading....</p>
            </div>
        );
    }
    if (httpError) {
        return (
            <div className="row" style={{ "margin-top": "100px" }}>
                <p>{httpError}....</p>
            </div>
        );
    }
    return ( <Card>           
            <h3>Version : {aboutUs.MajorVersion}.{aboutUs.MinorVersion}.{aboutUs.Revision}</h3>
            <h3>DB Version : {aboutUs.DBVersion}</h3>      
            <h3>Release Date : {aboutUs.VersionDate}</h3>
        <h3>Current Time : {currentTime}</h3>       
        <div style={{"text-align":"center", "margin-top":"50px"}}><h4>{aboutUs.CopyRight}</h4></div> 
        </Card>
       
   );
}
export default AboutUs;