import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import classes from '../TrialstatNoto.module.css'
import customStyles from '../customStyles';
import NoData from '../NoData';
import UploadDoumentModal from './UploadDoumentModal';

const DocumentMgmtList = (props) => {
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [arrStudies, setStudies] = useState([]);
    const [userRole, setUserRole] = useState('');

    const columns = [
        {
            name: "StudyFilesID",
            selector: "StudyFilesID",
            omit: true
        },
        {
            name: "Study",
            selector: "STUDYCODE",
            sortable: true,
            center: true
        },
        {
            name: "File Name",
            selector: "FileName",
            sortable: true,
            center: true
        },
        {
            name: "Description",
            selector: "Description",
            sortable: true,
            center: true,
        },
        {
            name: "Creation Date",
            selector: "CreationDate",
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            'width': '200px',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
                
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    <button style={{ "margin": "5px" }} onClick={() => handleDownloadFile(row.StudyFilesID, row.FileName, event)} className="btn btn-success"> Download </button>
                    {(userRole == "Management, Image Services" ||
                        userRole == "Image Services Coordinator" ||
                        userRole == "Clinical Data Manager" ||
                        userRole == "Manager, Clinical Data Management")
                        &&
                        <button style={{ "margin": "5px" }} onClick={() => handleDeleteFile(row.StudyFilesID)} className="btn btn-danger"> Delete </button>
                    }
                </div>
            )
        }
    ];

    const handleDeleteFile = (StudyFilesID) => {
        var con = confirm('Are you sure, you want to delete study file?');
        if (con == true) {
            delStudyFile(StudyFilesID)
        }       
    }
    const delStudyFile = (StudyFilesID) => {
        fetch('api/DocumentsMgmt/DeleteStudyFile?studyFilesID=' + StudyFilesID)
            .then(response => response.json())
            .then(data => {
                if (data == "1") {
                    fetchDocManagementList();
                }
                else
                    alert("Error while deleting the study file.")
            });
    };
    const filteredColumns = ['STUDYCODE', 'FileName', 'Description', 'CreationDate'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }
    const getUserNameAndRoleByID = () => {
        fetch('api/Users/getUserNameAndRoleByID')
            .then(response => response.json())
            .then(data => {
                setUserRole(data.RoleLabel);
            });
    };
    const studyChangeHandler = (e) => {        
        fetchDocManagementList(e.target.value);
    }

    const fetchDocManagementList = (sId) => {
        fetch('api/DocumentsMgmt/GetDocManagementList?StudyId=' + sId)
            .then(response => response.json())
            .then(data => {
                setRows(data);                
            });
    }

    useEffect(() => {
        getUserNameAndRoleByID();
        fetchDocManagementList('');
        FillStudyFilesList();
    }, []);

    const handleDownloadFile = (studyFilesID, fileName, event) => {
        fetch('api/DocumentsMgmt/DownloadFile?studyFilesID=' + studyFilesID)
            .then(response => response.json())
            .then(data => {
                var binaryString = window.atob(data.filedata);
                var binaryLen = binaryString.length;
                var bytes = new Uint8Array(binaryLen);
                for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }

                var blob = new Blob([bytes], { type: data.mimetype });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', fileName);
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    const FillStudyFilesList = () => {
        fetch('api/Site/FillDocumentStudyList')
            .then(response => response.json())
            .then(data => {
                setStudies(data);
            });
    }

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={3}>
                <div className="input-group">
                    <SelectList id='ddlStudies' dataSource={arrStudies} onChangeHandler={studyChangeHandler} />
                </div>
            </Col>
            <Col md={2}>
            </Col>
            <Col md={6}>
                <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" style={{ float: "right" }} />
            </Col>
            <Col md={1}>
                {(userRole == "Management, Image Services" ||
                    userRole == "Image Services Coordinator" ||
                    userRole == "Clinical Data Manager" ||
                    userRole == "Manager, Clinical Data Management")
                    && <UploadDoumentModal Text="Upload" header="Document upload" fetchDocManagementList={fetchDocManagementList} style={{float:"right"}} />}
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}

export default DocumentMgmtList;