import React, { useState, useEffect } from 'react';
import classes from './../TrialstatNoto.module.css';
import { Row, Col } from 'react-bootstrap';
import InputContainer from '../UI/Container/InputContainer'

const QueryDetails = (props) => {
    const [arrQueryDetails, setArrQueryDetails] = useState([]);
    const GetQueriesByQueryID = () => {
        fetch('api/TaskQuery/GetQueriesByQueryId?QueryId=' + props.queryid)
            .then(response => response.json())
            .then(data => {
                setArrQueryDetails(data.listQueryDetails);
            });
    }

    useEffect(() => {
        GetQueriesByQueryID();
    }, []);

    return (
        <Row>
            <Col md={12}>
                {arrQueryDetails.map((item, index) =>
                    <InputContainer Expended='false' header='Query Details' style={{ "text-align": "center", "margin-top": "15px" }}>
                        <div style={{ "margin-left": "12px", "margin-right": "12px", "margin-top": "0px" }}>
                            <Row>
                                <Col md={1} className={classes.cellRLBBorder}><b>Query ID</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{item.QueryID}</Col>
                                <Col md={1} className={classes.cellRightBorder}><b>Task ID</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{item.TaskID}</Col>
                                <Col md={2} className={classes.cellRightBorder}><b>Study Name</b></Col>
                                <Col md={2} className={classes.cellRightBorder}>{item.studyCode}</Col>
                                <Col md={1} className={classes.cellRightBorder}><b>Status</b></Col>
                                <Col md={3} className={classes.cellRightBorder}>{item.QueryStatus}</Col>
                            </Row>
                            <Row>
                                <Col md={1} className={classes.cellRLBBorder}><b>Site Name</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{item.SiteName}</Col>
                                <Col md={1} className={classes.cellRightBorder}><b>Created By</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{item.CreationUser}</Col>
                                <Col md={2} className={classes.cellRightBorder}><b>Created Date</b></Col>
                                <Col md={6} className={classes.cellRightBorder}>{item.CreationDate}</Col>
                            </Row>
                            {props.userRole != 'siteuser' && < Row >
                                <Col md={1} className={classes.cellRightBorder}><b>Hide From Site</b></Col>
                                <Col md={1} className={classes.cellRightBorder}>{item.HideFromSite}</Col>
                                <Col md={1} className={classes.cellRLBBorder}><b>Type</b></Col>
                                <Col md={9} className={classes.cellRightBorder}>{item.TypeLabel}</Col>
                            </Row>}
                            <InputContainer Expended='true' header='Comment History'
                                style={{ "text-align": "center", "margin-bottom": "0px", "margin-top": "15px" }}>
                                <Row style={{ "margin-left": "0px", "margin-right": "0px", "margin-top": "0px" }}>
                                    <Col md={2} className={classes.cellRLBBorder}><b>User ID</b></Col>
                                    <Col md={2} className={classes.cellRLBBorder}><b>Date</b></Col>
                                    <Col md={1} className={classes.cellRightBorder}><b>Note Type</b></Col>
                                    <Col md={7} className={classes.cellRLBBorder}><b>Comment</b></Col>
                                </Row>
                                {item.listQueryComments.map((item1, index1) =>
                                    <Row style={{ "margin-left": "0px", "margin-right": "0px", "margin-top": "0px" }}>
                                        <Col md={2} className={classes.cellRLBBorder}>{item1.CreationUser}</Col>
                                        <Col md={2} className={classes.cellRightBorder}>{item1.CreationDate}</Col>
                                        <Col md={1} className={classes.cellRightBorder}>{item1.CommentType}</Col>
                                        <Col md={7} className={classes.cellRightBorder}>{item1.QueryComment}</Col>
                                    </Row>
                                )}
                            </InputContainer>                          
                        </div>
                    </InputContainer>
                )
                }
            </Col>
        </Row>
    );
};

export default QueryDetails;
