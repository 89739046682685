import React, { useState } from 'react';
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import MultiSelectList from '../UI/Control/MultiSelectList';
import { Button } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import { useSelector } from 'react-redux'

const OutcomeInfoCRF = (props) => {
    const [arrEDCOutcomeECRFs, setEDCOutcomeECRFsTemp] = useState([]);
    const [inputValOutcomeCRF, setInputValOutcomeCRF] = useState('');
    const [EDCeCRFs, setEDCeCRFs] = useState([{}]);
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setInputValOutcomeCRF('');
        fetchEDCFormsLib().catch((error) => {
            console.log(error.message)
        });
        setShow(true);
    };

    const searchChangeHandlerOutcomeCRF = (e) => {
        let arrEDC = EDCeCRFs.filter(item => item.Name.toLowerCase().includes(e.target.value.toLowerCase()));
        setEDCOutcomeECRFsTemp(arrEDC);
        setInputValOutcomeCRF(e.target.value);
    }

    const fetchEDCFormsLib = async () => {
        const response = await fetch('api/ServiceCall/GetEDCFormLib')
        if (!response.ok) {
            throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
        }
        const edcForms = await response.text();
        let listForms = JSON.parse(edcForms);
        const arrEDCForms = new Array();
        for (var iCount = 0; iCount < listForms.length; iCount++) {
            arrEDCForms.push({ Name: listForms[iCount].FormName, Value: listForms[iCount].FormID })
        }
        setEDCeCRFs(arrEDCForms);
        setEDCOutcomeECRFsTemp(arrEDCForms);
        if (arrEDCForms.length > 0)
            fetchGetStudyParadigmCRFsDetails();
    }

    const fetchGetStudyParadigmCRFsDetails = () => {
        fetch('api/ReadSettings/getStudyParadigmCRFsDetails?studyReadSettingsID=' + props.studyReadSettingsID + '&readerType=OUTC')
            .then(response => response.json())
            .then(data => {
                if (data.listStudyParadigmCRFModel.length > 0) {
                    document.getElementById('multiSelectOutcomeCRF').value = data.listStudyParadigmCRFModel[0].FormID;
                    document.getElementById('hdnOutcCRFID').value = data.listStudyParadigmCRFModel[0].StudyParadigmCRFID;
                }
                else {
                    document.getElementById('multiSelectOutcomeCRF').value = 0;
                    document.getElementById('hdnOutcCRFID').value = 0;
                }
            });
    }

    const updateStudyParadigmCRFs = (event) => {

        const objmultiSelectOutcomeCRF = document.getElementById('multiSelectOutcomeCRF');
        if (objmultiSelectOutcomeCRF.value.trim() == '') {
            alert('Please select outcome CRF to submit.');
            objmultiSelectOutcomeCRF.focus();
            return false;
        }

        let readSettingsModel =
        {
            StudyReadSettingsID: props.studyReadSettingsID,
            Username: EDCUserID,
            listStudyParadigmCRFModel: new Array()
        }

        let crfId = 0;
        crfId = document.getElementById('hdnOutcCRFID').value;
        readSettingsModel.listStudyParadigmCRFModel.push({ StudyParadigmCRFID: crfId, StudyReadSettingsID: props.studyReadSettingsID, ReadStage: 0, ReaderType: 'OUTC', FormID: document.getElementById('multiSelectOutcomeCRF').value == '' ? 0 : document.getElementById('multiSelectOutcomeCRF').value });

        fetch('api/ReadSettings/updateStudyParadigmCRFs', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(readSettingsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Outcome not updated, Something went wrong.');
                return false;
            }
            
            if (res > 0) {
                props.fetchReadSettingData();
                setShow(false);
            }
        })
    };
    return (
        <>
            <a style={{ color: props.IsOutcome === "No" ? "red" : "green", "cursor": "pointer", "text-align":"center" }}
                onClick={handleShow}><u><b>{props.text}</b></u></a>
            {/*<button className={props.btnCss} type="button" onClick={handleShow}> {props.text} </button> */}

            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Eleven} animation={false} backdrop="static">
                <Modal.Header className={classes.modalHeader}>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        <Modal.Title>{props.header}</Modal.Title>
                        </div>
                </Modal.Header>
                <Modal.Body>
                    <div class="form-group" style={{ marginTop: "10px" }}>
                        <InputString id="txtOutcomeCRFSearch" value={inputValOutcomeCRF} onChangeHandler={searchChangeHandlerOutcomeCRF} placeholder="Search" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }}></InputString>
                    </div>
                    <div class="form-group" style={{ marginTop: "10px" }}>
                        <input type="hidden" id="hdnOutcCRFID" />
                        <MultiSelectList id="multiSelectOutcomeCRF" dataSource={arrEDCOutcomeECRFs} isMultiSelect="false" size="8" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></MultiSelectList>
                    </div>
                    <div class="form-group" style={{ marginTop: "10px", display:"none" }}>
                        <label for="selectOutcomeCRF">Select CRF Version</label>
                        <SelectList id="selectOutcomeCRF" dataSource={props.selectDataSource} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></SelectList>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        {!props.disabled && props.action == "copy" && <Button className="btn btn-success" onClick={CopyReadsetting} style={{ marginRight: "5px" }}> Copy </Button>}
                        {!props.disabled && (props.action == "add" || props.action == "edit") && <Button variant="secondary" className="btn btn-success" style={{ marginRight: "5px" }} onClick={updateStudyParadigmCRFs} disabled={props.isDisabled}> Submit </Button>}
                    <Button variant="primary" className="btn btn-danger" onClick={handleClose}>
                        Cancel
                            </Button>
                        </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OutcomeInfoCRF;
