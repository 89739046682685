import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import { Button } from "reactstrap";
import StudySettings from "./StudySettings";
import VisitSettings from "./VisitSettings";
import ReadSettings from "./ReadSettings";
import { useNavigate } from 'react-router-dom';
import classes from "./StepperCss.css"

let custom = {
    enterRight: 'animated fadeIn',
    enterLeft: 'animated fadeIn',
    exitRight: 'animated fadeOut',
    exitLeft: 'animated fadeOut'
}

const HeaderStudySettings = (props) => {
    return (
        <h6 style={{ marginTop:"10px" }}> Study Settings </h6>
    );
};

const HeaderVisitSettings = (props) => {
    return (
        <h6 style={{ marginTop: "10px" }}> Visit Settings </h6>
    );
};

const HeaderReadSettings = (props) => {
    return (
        <h6 style={{ marginTop: "10px" }}> Read Settings </h6>
    );
};

const Designer = (props) => {
    const [stepWizard, setStepWizard] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [action, setAction] = useState(props.Action);
    const navigate = useNavigate();

    if (typeof props.Action == 'undefined') {
        setAction('View')
    }
    const [nextDisabled, setNextdisabled] = useState(props.Action.toLowerCase() == 'add' ? false : true);
    const StepStyleDTO = { activeBgColor: "#5faed2", completedBgColor: "#0c3f62", borderRadius: "unset" };
    const connectorStyleConfig = {stepSize: "6.7em"};
    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const handleStepChange = (e) => {       
        setActiveStep(e.activeStep - 1);
    };

    const setGoSteps = (step) => {
        stepWizard.goToStep(step);
    };

    const handleClose = (e) => {
        navigate('/Designer');
    };

    const ActionButton = (props) => {

        const handleBack = () => {
            props.previousStep();
        };

        const handleNext = () => {
            if (props.Action.toLowerCase() == 'view') {
                if (typeof props.component != 'undefined' && props.component.toLowerCase() == 'readsettings') {
                    fetchEDCFormsLib();
                    props.nextStep();
                }
                else
                    props.nextStep();
            }
            else {
                if (typeof props.component != 'undefined' && props.component.toLowerCase() == 'studysettings') {
                    if (props.SaveStudySettings()) {
                        props.nextStep();
                    }
                }
                else if (typeof props.component != 'undefined' && props.component.toLowerCase() == 'visitsettings') {
                    if (props.SaveVisitSettings()) {
                        props.nextStep();
                    }
                }
                else if (typeof props.component != 'undefined' && props.component.toLowerCase() == 'readsettings') {
                    fetchEDCFormsLib();
                    props.nextStep();
                }
                else if (typeof props.component != 'undefined' && props.component.toLowerCase() == 'adjudicationsettings') {
                    
                    props.nextStep();
                }
                else if (typeof props.component != 'undefined' && props.component.toLowerCase() == 'adjudicationverification') {
                    props.nextStep();
                }
                else if (typeof props.component == 'undefined') {
                    props.nextStep();
                }
            }
        };

        const handleFinish = () => {
            props.lastStep();
        };

        const fetchEDCFormsLib = async () => {
            const response = await fetch('api/ServiceCall/GetEDCFormLib')
            if (!response.ok) {
                throw new Error("Something went wrong, message :" + response.message + " \nHttp Error:" + response.httpError)
            }
            const edcForms = await response.text();
            let listForms = JSON.parse(edcForms);
            const arrEDCForms = new Array();
            for (var iCount = 0; iCount < listForms.length; iCount++) {
                arrEDCForms.push({ Name: listForms[iCount].FormName, Value: listForms[iCount].FormID })
            }
            setEDCeCRFsTemp(arrEDCForms);
        }

        return (
            <div className="row" style={{ "margin-left": "0px" }}>
                <div className="col-sm-12" >
                    {props.currentStep > 1 && (
                        <Button className="btn btn-success" onClick={handleBack}>Back</Button>
                    )}&nbsp;&nbsp;&nbsp;&nbsp;

                    {props.currentStep < props.totalSteps && (
                        <Button id="btnNext" className="btn btn-success" onClick={handleNext} disabled={nextDisabled}>Next</Button>
                    )}

                    {props.currentStep === props.totalSteps && (
                        <Button className="btn btn-success" style={{display:"none"}} onClick={handleFinish}>Finish</Button>
                    )}

                    <Button className="btn btn-danger" style={{ marginLeft: "15px" }} onClick={ props.Close}>Close</Button>
                </div>
            </div>
        );
    };

    return (
        <div id="fff" className="row">            
            <div className="col-sm-12">
            <Stepper styleConfig={StepStyleDTO} activeStep={activeStep} connectorStyleConfig={connectorStyleConfig} >
                    <Step onClick={() => setGoSteps(1)} children={<HeaderStudySettings />} />
                    <Step onClick={() => setGoSteps(2)} children={<HeaderVisitSettings />} />
                    <Step onClick={() => setGoSteps(3)} children={<HeaderReadSettings />} />
            </Stepper>
            {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
                <StepWizard transitions={custom} instance={assignStepWizard} onStepChange={handleStepChange}>
                    <StudySettings Action={action} disabled={action == 'View' ? true : false}
                        ActionButton={ActionButton} Close={props.onCloseHandler || handleClose} studyId={props.studyId} userRole={props.userRole} setNextdisabled={setNextdisabled} />

                    <VisitSettings Action={action} disabled={action == 'View' ? true : false}
                        ActionButton={ActionButton} Close={props.onCloseHandler || handleClose} studyId={props.studyId} userRole={props.userRole} />
                    <ReadSettings Action={action} disabled={action == 'View' ? true : false}
                        ActionButton={ActionButton} Close={props.onCloseHandler || handleClose} studyId={props.studyId} userRole={props.userRole} />
                </StepWizard>
            </div>           
        </div>
    );
};
export default Designer;
