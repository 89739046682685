import React, { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Multiselect from 'multiselect-react-dropdown';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import ManageAmendmentModal from './ManageAmendmentModal';
import StudyStatusModal from './StudyStatusModal';
import ViewStudyModal from './ViewStudyModal';
import EditStudyModal from './EditStudyModal';
import customStyles from '../customStyles';
import NoData from '../NoData';
import classes from './../TrialstatNoto.module.css';
import AdjudicationSettingsModal from "./AdjudicationSettings";
//import Dropdown from 'react-bootstrap/Dropdown'; 
import Button1 from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import OutcomeNotificationModal from './OutcomeNotificationModal';

const Studies = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [rowData, setData] = useState([]);
    const [isSaved, setIsSaved] = useState(false);   
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [manageSitePermission, setmanageSitePermission] = useState('RO'); //RO, RW, NA
    const [envVar, setEnvVar] = useState('PROD'); //PROD, UAT, DEV
    const selectRef = useRef('');
    const navigate = useNavigate();
    const [status, setStatus] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [userRole, setUserRole] = useState('');
    const [isOutcomeConfig, setIsOutcomeConfig] = useState(true);   
    const [isEmailNotification, setIsEmailNotification] = useState(true);   
    const [isSiteDashboad, setIsSiteDashboad] = useState(true);   
    const onClickHandler = () => {
        navigate('/NewStudy',{
            action: 'Add',
        });
    }

    const onClickRefershHandler = () => {
        setQ('');
        setInputVal('');
        selectRef.current.resetSelectedValues();
        fetchData();
    }
    useEffect(() => {
        FillStudyStatusData();
        fetchData();
        getUserRole();
    }, [isSaved])

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => { 
                envVariable(role);       
                setUserRole(role);
            });
    };
    const envVariable = (role) => {
        fetch('api/AppSettings/EnvVariable')
            .then(res => res.text())
            .then(varData => {
                setCompPermission(role, varData);  
            });
    }
    const setCompPermission = (role, varData) => {      
        if (varData == "UAT") {
            setEnvVar(varData)
            setUserPermisssion("RO")
        }
        else {
            if (role == "Read-Only")
                setUserPermisssion("RO")
            else if (role == "Database Designer" || role == "Super User")
                setUserPermisssion("RW")
            else if (role == "Tester")
                setUserPermisssion("RO")
            else if (role == "Clinical Data Manager" || role == "Manager, Clinical Data Management") {
                setUserPermisssion("RO")
                setmanageSitePermission("RW")
            }
            else
                setUserPermisssion("NA")
        }
    }

    const onManageSiteHandler = (StudyID, StudyProtocolId, event) => {
        navigate('/ManageSites', { state: { studyId: StudyID, studyProtocolId: StudyProtocolId} });
    }
    
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');    

    const columns = [
        {
            name: "Study Name",
            selector: "StudyName",
            sortable: true,
            center: true,
            width: "18%",
            cell: (row) => (
                <div className={classes.textWrap}>{row.StudyName}</div>
            )
        },
        {
            name: "Protocol",
            selector: "Protocol",
            sortable: true,
            center: true,
            width: "12%"
        },
        {
            name: "Study Protocol ID",
            selector: "StudyProtocolID",
            omit: true
        },
        {
            name: "Protocol Version",
            selector: "ProtocolVersion",
            sortable: true,
            center: true,
            width: "8%"
        },
        {
            name: "Sponsor",
            selector: "Sponsor",
            sortable: true,
            center: true,
            width: "12%"
        },
        {
            name: "Status",
            selector: "Status",
            sortable: true,
            center: true,
            width: "10%",
        },
        {
            name: "EDCID",
            selector: "EDCID",
            omit: true
        },
        {
            name: "Environment",
            selector: "Environment",
            omit: true
        },
        {
            name: "Study Tags",
            selector: "StudyTags",
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "ConfigEnabled",
            selector: "OCConfigEnabled",
            sortable: false,
            center: true,
            omit: true
        },
        {
            name: "OCEmailNotification",
            selector: "OCEmailNotification",
            sortable: false,
            center: true,
            omit: true
        },
        {
            name: "OCDashboardNotification",
            selector: "OCDashboardNotification",
            sortable: true,
            center: true,
            omit: true
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '40%',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)',
                'justify-content': 'left'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {/* Read Write Permission*/}
                    {row.EDCID != "waiting" && <div style={{ "padding": "5px" }}>
                        {userPermission == "RW" && ((row.EDCID == "0" || row.EDCID == "") || (userRole == 'Super User')) &&
                            <EditStudyModal style={{ "margin": "5px" }} studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} text="Edit" action="Edit" fetchData={fetchData} userRole={userRole}></EditStudyModal>}

                        {userPermission == "RW" && <ViewStudyModal style={{ "margin": "5px" }} studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} text="View" action="View" userRole=""></ViewStudyModal>}

                        {(userPermission == "RW" || envVar == "UAT" || (userRole == 'Clinical Data Manager' || userRole == 'Manager, Clinical Data Management')) &&
                            <AdjudicationSettingsModal disabled={userPermission == 'RO' ? true : false} style={{ "margin": "5px" }} text="Adjudication" studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} action="newAdjudication" fetchData={fetchData}></AdjudicationSettingsModal>}

                        {userPermission == "RW" && (row.EDCID != "0" && row.EDCID != "") &&
                            <ManageAmendmentModal style={{ "margin": "5px" }} title="Manage study" text="Study" studyId={row.StudyID} studyName={row.StudyName} protocolVersion={row.Protocol} action="createamendment" fetchData={fetchData}></ManageAmendmentModal>}

                        {userPermission == "RW" && <StudyStatusModal style={{ "margin": "5px" }} title="Update status" text="Status" studyName={row.StudyName} protocolVersion={row.Protocol} statusData={statusData} studyStatusId={row.StudyStatusId} studyId={row.StudyID} fetchData = {fetchData} ></StudyStatusModal>}

                        {userPermission == "RW" && (row.EDCID != "0" && row.EDCID != "") &&
                            <button style={{ "margin": "5px" }} title="Manage site" onClick={() => onManageSiteHandler(row.StudyID, row.StudyProtocolID, event)} className="btn btn-info">Site</button>}

                        {userPermission == "RW" && row.DeployedOnProd == "N" && <button style={{ "margin": "5px" }} onClick={() => handleDeleteStudy(row.StudyID, event)} className="btn btn-danger"> Delete </button>}

                        {/* Read Only Permission or Clinical Data Manager */}
                        {userPermission == "RO" && <ViewStudyModal style={{ "margin": "5px" }} studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} text="View" action="View" userRole=""></ViewStudyModal>}
                        {(userPermission == "RO" || manageSitePermission == "RW") && (row.EDCID != "0" && row.EDCID != "") &&
                            <button style={{ "margin": "5px" }} title="Manage site" onClick={() => onManageSiteHandler(row.StudyID, row.StudyProtocolID, event)} className="btn btn-info">Site</button>}
                        {userPermission == "RW" && <ButtonGroup>
                            <OutcomeNotificationModal text="Outcome" refreshData={fetchData} studyId={row.StudyID} setIsOutcomeConfig={row.OCConfigEnabled == "Y" ? "yes" : "no"} setIsEmailNotification={row.OCEmailNotification == "Y" ? "yes" : "no"} setIsSiteDashboad={row.OCDashboardNotification == "Y" ? "yes" : "no" } ></OutcomeNotificationModal>
                            <DropdownButton as={ButtonGroup} id="bg-nested-dropdown" variant="secondary">
                                <Dropdown.Item eventKey="1" disabled={row.OCConfigEnabled == "N" ? true : false} onClick={() => OnClickOutComesMapping(row.StudyID, row.StudyProtocolID, event)} >Outcomes Mapping</Dropdown.Item>
                                <Dropdown.Item eventKey="2" disabled={row.OCEmailNotification == "N" ? true : false} onClick={() => onOutcomeEmailHandler(row.StudyID, row.StudyProtocolID, event)} >Outcomes Email Template</Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup>}
                    </div>
                    }
                    {row.EDCID == "waiting" && <div style={{ "padding": "5px" }}>
                        {<ViewStudyModal style={{ "margin": "5px" }} studyId={row.StudyID} studyName={row.StudyName} studyProtocolId={row.StudyProtocolID} protocolVersion={row.Protocol} text="View" action="View" userRole=""></ViewStudyModal>}
                        {<StudyStatusModal style={{ "margin": "5px" }} title="Update status" text="Status" studyName={row.StudyName} protocolVersion={row.Protocol} statusData={statusData} studyStatusId={row.StudyStatusId} studyId={row.StudyID} fetchData={fetchData} ></StudyStatusModal>}
                    </div>
                    }
                    
                </div>
            )
        }
    ];   

    const OnClickOutComesMapping = (StudyID, StudyProtocolId, event) => {
        navigate('/OutcomeMappingList', { state: { studyId: StudyID, studyProtocolId: StudyProtocolId } });
    };

    const fetchData = () => {
        fetch('api/Study/GetStudies', {
            headers: new Headers({
                'Authorization': 'Basic ' + btoa(EDCUserID),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        }).then(response => response.json())
            .then(data => {
                setData(data);
            });
        setIsSaved(false);
    }
    const fetchEnvironment = (studyId) => {
        fetch('api/Study/GetStudyEnvironmentByStudyId?studyId=' + studyId)
            .then(response => response.json())
            .then(data => {
                if (data.Environment.toLowerCase() == 'prod')
                    return false;
                else
                    return true;
            });
    }  

    const filteredColumns = ['StudyName', 'Protocol', 'Sponsor', 'Status', 'StudyTags']; //rows[0] && Object.keys(rows[0]);
    function search(rows) {
      
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return filteredColumns.some((column) => { 
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);   
        selectRef.current.resetSelectedValues();
        if (search(rowData).length == 1) {            
            const collection = document.getElementsByClassName("rdt_Table");
            collection[0].setAttribute("style", "min-height: 200px!important");
        }
    }    
   
    const handleDeleteStudy = (studyId, event) => {
        var con = confirm('Are you sure, you want to delete study?');
        if (con == true) {
            DeleteStudy(studyId);
        }
    };

    const DeleteStudy = (studyId) => {
        let objStudyModel = {
            StudyID: studyId,
            UserName: EDCUserID,
            Alert: 'X'
        };

        fetch('api/Study/DeleteStudy', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objStudyModel)
        }).then(r => r.json()).then(res => {
            if (res > 0) {
                fetchData();
            }
        })
    };

    const FillStudyStatusData = () => {
        fetch('api/Study/FillStudyStatusData')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: 0, Name: '' }];
                let arr1 = new Array();
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                    arr1.push(data[iCount].Name);
                }
                setStatus(arr1);
                setStatusData(arr);
            });
    }

    const onOutcomeEmailHandler = (StudyID, StudyProtocolId, event) => {
        navigate('/OutcomeEmailDesigner', { state: { studyId: StudyID, studyProtocolId: StudyProtocolId } });
    }

    return <Card>    
        <Row>
            <Col md={3} className="form-inline mb-2">
                <Multiselect
                    placeholder="Status"
                    isObject={false}
                    onKeyPressFn={function noRefCheck() { }}
                    onRemove={function noRefCheck() {
                        let setItem = selectRef.current.getSelectedItems() 
                        setQ(setItem.toString());                       
                    }}
                    onSearch={function noRefCheck() { }}
                    onSelect={function noRefCheck() {
                        let setItem = selectRef.current.getSelectedItems() 
                        setQ(setItem.toString());                       
                    }}
                    options={status}
                    ref={selectRef}
                />
            </Col>
            <Col md={5}>
                <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" style={{ marginRight: "15px" }} />
                
            </Col>
            <Col md={2} style={{ paddingLeft:"0px" }}>
                <Button onClick={onClickRefershHandler} className="btn btn-success">Refresh</Button>
            </Col>
            <Col md={2}>
                {userPermission == "RW" && <Button style={{ "float": "right" }} onClick={onClickHandler} className="btn btn-success">Create New Study</Button>}
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}
export default Studies;