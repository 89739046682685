import React, { useState , useEffect  } from 'react';
import InputContainer from '../UI/Container/InputContainer';
import { Button } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const AssignReadByUser = (props) => {
    const [assignReaderDetails, setAssignReaderDetails] = useState({});

    const fetchAssignReaderDetails = () => {
        fetch('api/ImageService/GetAssignReaderDetails?taskId=' + props.taskId + '&readStage=' + props.readStage)
            .then(response => response.json())
            .then(data => {
                setAssignReaderDetails(data);
            });
    }

    useEffect(() => {
        fetchAssignReaderDetails();
    }, []);

    return (
        <div style={{ marginTop: "15px" }}>
            <InputContainer border='true' header={"Central Reader " + props.readStage} style={{ "text-align": "center" }} Expended="true" >
                <div className="row">
                    <div className="col-sm-9">
                        <div className="row" style={{ padding: "15px" }}>
                            <div className="col-sm-3">
                                <strong> Assigned Reader:</strong><MandatoryLabel />
                            </div>
                            <div className="col-sm-3" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                {assignReaderDetails.AssignedReader}
                            </div>
                            <div className="col-sm-2">
                                <Button variant="primary" className="btn btn-success" onClick={() => props.onChangeReader(event)} readAssignmentID={props.readAssignmentID}>Change Reader</Button>
                            </div>
                            <div className="col-sm-2">
                                <Button variant="primary" className="btn btn-warning" onClick={() => props.OnRemoveAssignedReader(event)} readAssignmentID={props.readAssignmentID}>Remove Reader</Button>
                            </div>
                        </div>

                    </div>

                </div>

            </InputContainer>
        </div>
    );
}
export default AssignReadByUser;
