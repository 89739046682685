import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import InputRadio from '../UI/Control/InputRadio';

const OutcomeNotificationModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [ocConfig, setOcConfig] = useState(props.setIsOutcomeConfig);
    const [ocEmail, setOcEmail] = useState(props.setIsEmailNotification);
    const [ocDashBoard, setOcDashBoard] = useState(props.setIsSiteDashboad);
   
    const handleShow = () => {
        setOcConfig(props.setIsOutcomeConfig);
        setOcEmail(props.setIsEmailNotification);
        setOcDashBoard(props.setIsSiteDashboad);
        setShow(true);
        
    }

    const SaveConfiguration = (event) => {        

        var configOC = document.querySelector("input[name=ConfigureOutcomes]:checked").value == 'yes' ? 'Y' : 'N';
        var emailNotify = configOC == 'N' ? 'N' : document.querySelector("input[name=OENR]:checked").value == 'yes' ? 'Y' : 'N';
        var dashboardNotify = configOC == 'N' ? 'N' : document.querySelector("input[name=OISD]:checked").value == 'yes' ? 'Y' : 'N';
        
        let objConfig = {
            OutcomesID: 0,
            StudyID: props.studyId,
            ConfigEnabled: configOC,
            DashboardNotification: dashboardNotify,
            EmailNotification: emailNotify
        };

        fetch('api/OutcomeNotification/SaveOutcomeConfig', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objConfig)
        }).then(r => r.text()).then(res => {
            
            if (res == "success") {
                props.refreshData();
                setShow(false);
            }
            else if (res == "notsaved") {
                alert('Outcome configuration not saved, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })

    };
    const handleConfigOnchange = (e) => {
        setOcConfig(e.target.value);
        if (e.target.value === "no") {
            setOcEmail(e.target.value);
            document.querySelector("input[name=OENR]").disabled = true;
            setOcDashBoard(e.target.value);
            document.querySelector("input[name=OISD]").disabled = true;
        }
        else {
            document.querySelector("input[name=OENR]").disabled = false;
            document.querySelector("input[name=OISD]").disabled = false;
        }
    }
    const handleEmailOnchange = (e) => {
        setOcEmail(e.target.value);
    }
    const handleDashboardOnchange = (e) => {
        setOcDashBoard(e.target.value);
    }
    return (
        <>
            <Button className="btn btn-secondary" onClick={handleShow}>{props.text}</Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Seven} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>Outcome Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col-sm-5">
                            Configure Outcomes
                        </div>
                        <div className="col-sm-6">  
                            <InputRadio orientation='horizontal' value="yes" id="rdoCOYes" name="ConfigureOutcomes" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }} onChange={handleConfigOnchange} checked={ocConfig === "yes"} />
                            <InputRadio orientation='horizontal' value="no" id="rdoCONo" name="ConfigureOutcomes" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }} onChange={handleConfigOnchange} checked={ocConfig === "no"}/>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-5">
                            Outcome Email Notification Required
                        </div>
                        <div className="col-sm-6">
                            <InputRadio orientation='horizontal' value="yes" id="rdoOENRYes" name="OENR" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }} onChange={handleEmailOnchange} checked={ocEmail === "yes"} />
                            <InputRadio orientation='horizontal' value="no" id="rdoOENRNo" name="OENR" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }} onChange={handleEmailOnchange} checked={ocEmail === "no"} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-5">
                            Outcomes in Site Dashboard
                        </div>
                        <div className="col-sm-6">
                            <InputRadio orientation='horizontal' value="yes" id="rdoOISDYes" name="OISD" text="Yes" style={{ "margin-left": "10px", "margin-right": "5px" }} onChange={handleDashboardOnchange} checked={ocDashBoard === "yes"}/>
                            <InputRadio orientation='horizontal' value="no" id="rdoOISDNo" name="OISD" text="No" style={{ "margin-left": "10px", "margin-right": "5px" }} onChange={handleDashboardOnchange} checked={ocDashBoard === "no"} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={SaveConfiguration}> Save </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default OutcomeNotificationModal;