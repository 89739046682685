import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { Row, Col } from "reactstrap";
import InputString from '../UI/Control/InputString';
import AssignedReviewByUserModal from './AssignedReviewByUserModal';
import customStyles from '../customStyles';
import NoData from '../NoData';
import InputTextArea from '../UI/Control/InputTextarea';

const Reviewers = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [reviewers, setReviewers] = useState([]);
    const taskId = props.taskId;

    useEffect(() => {
        fetchReviewers();
    }, [])
    const columns = [
        {
            name: "Central Reviewer",
            selector: "Reader",
            sortable: true,
            center: true
        },
        {
            name: "Reviewer Type",
            selector: "ReaderType",
            sortable: true,
            center: true,
        },
        {
            name: "Global CR Queue",
            selector: "CurrReadQueue",
            sortable: true,
            center: true
        },
        {
            name: "Total Study Review",
            selector: "TotalStudyReads",
            sortable: true,
            center: true,
            center: true
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: '150px',
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    <AssignedReviewByUserModal btn="true" text="View" row={row} ></AssignedReviewByUserModal>
                    <Button className="btn btn-sm btn-success" style={{ marginLeft: "10px" }} onClick={() => onClickHandler(row)}> Assign </Button>
                </div>
            )
        }
    ];

    const fetchReviewers = () => {
        fetch('api/ImageService/GetReviewers?taskId=' + taskId)
            .then(response => response.json())
            .then(data => {
                setReviewers(data);
            });
    }

    function search(rows) {
        const columns = ['Reader', 'ReaderType', 'CurrReadQueue', 'TotalStudyReads']; //rows[0] && Object.keys(rows[0]);
        if (q != '') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
        selectRef.current.resetSelectedValues();
    }

    const Close = (e) => {
        props.setActionType("Re-review");
    }
    const onClickHandler = (row) => {
        let reviewType = row.ReaderType.toString().toLowerCase();
        let objReviewerNote = document.getElementById('txtReviewerNote');
        if (props.reviewAssignmentID == 0) {
            let objReadAssignmentsModel = {
                TaskID: taskId,
                ReadStage: props.reviewStage,
                AssignedReader: row.EDCUserID,
                CreationUser: EDCUserID,
                ReaderNote: objReviewerNote.value
            };
            fetch('api/ImageService/SaveReviewAssignments', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objReadAssignmentsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1) {
                    alert('Review assignment not done, Something went wrong.');
                }
                if (res > 0) {
                    props.relaodAction();
                }
            })
        }
        else {
            let objReadAssignmentsModel = {
                ReadAssignmentID: props.reviewAssignmentID,
                AssignedReader: row.EDCUserID,
                CreationUser: EDCUserID,
                ReaderNote: objReviewerNote.value,
                TaskID: taskId
            };

            fetch('api/ImageService/UpdateReviewAssignments', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objReadAssignmentsModel)
            }).then(r => r.json()).then(res => {
                if (res == -1) {
                    alert('Changing reviewer not done, Something went wrong.');
                }
                if (res > 0) {
                    props.relaodAction();
                }
            })
        }
    }
   
    return (
        <div>
            <Row className="mt-2 mb-2" >
                <Col md={7} className="form-inline">
                    <InputTextArea rows='2' cols='60' id="txtReviewerNote" placeholder="Reviewer Note" maxlength="250" />
                </Col>
                <Col md={4}>
                    <div className="input-group">
                        <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                    </div>
                </Col>
                <Col md={1}>
                    <Button className="btn btn-danger" style={{ float: "right" }} onClick={Close}> Close </Button>
                </Col>
            </Row>
            <div className="tableContainer ">
                {search(reviewers).length > 0 ? <DataTable
                    columns={columns}
                    data={search(reviewers)}
                    className="table table-striped table-bordered table-hover"
                    customStyles={customStyles}
                    striped
                    pagination
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                /> : <NoData headers={columns}></NoData>}
            </div>
        </div>
    );
};

export default Reviewers;
