import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import DeleteIcon from '../../Images/Icons/delete.svg';
import classes from './../TrialstatNoto.module.css';
import NoticeModal from './NoticeModal';

const Notice = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [usrRole, setUsrRole] = useState('');
    const handleDeleteNotice = (eventRow, event) => {
        var con = confirm('Are you sure, you want to delete notice?');
        if (con == true) {
            DeleteNotices();
        }
    };

    const DeleteNotices = (event) => {
        let objNoticesModel = {
            NoticeID: props.noticeId,
            ModifyUser: EDCUserID,
            Alert : 'X'
        };

        fetch('api/ImageService/DeleteNotices', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objNoticesModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Notices not saved, Something went wrong.');
            }
            if (res == 0) {
                alert('Notices already exists, Please try again.');
            }
            if (res > 0) {
                props.fetchNotices(props.noticeStudyId);
                setShow(false);
            }
        })

    };
    function getUserRole() {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setUsrRole(role);
            });
    }
    
    useEffect(() => {
        getUserRole();
    }, []);

    return (
            <div className={"col-sm-12 " + classes.rowWhite}>
                <div className="row">
                    <div className="col-sm-9">
                        <p> {props.notice} </p>
                </div>
                <div className="col-sm-3" style={{ fontStyle: "italic", textAlign: "right", wordWrap: "break-word", fontSize: "14px", color:"orangered" }}>
                    {props.studyName}
                </div>
                </div>
                <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-10">
                            <span style={{ marginTop: "10px", fontSize: "12px", fontStyle: "italic" }}> Posted by {props.postedBy} on {props.createdDate} </span>
                        </div>
                        {props.UserPermission == "RW" &&
                            <div className="col-sm-2">
                            <div style={{ float: "right" }}>
                                <NoticeModal text="MANAGE ALERTS" btn="icon" header="CRF Selection" noticeId={props.noticeId} fetchNotices={props.fetchNotices} noticeStudyId={props.noticeStudyId}></NoticeModal>
                                    {usrRole != "Read-Only" && <img className={classes.act_icon} src={DeleteIcon} alt="Delete" title="Delete" onClick={e => handleDeleteNotice(props.noticeId, e)} />}
                                </div>
                        </div>}
                    </div>
                    </div>
                </div>
            </div>
    );

};

export default Notice;
