import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';
import SelectList from '../UI/Control/SelectList';

const DMGlobalDashboard = (props) => {
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [studyList, setStudyList] = useState([]);
    const [studyID, setStudyID] = useState('');
    const [sponsorList, setSponsorList] = useState([]);
    const [sponsorID, setSponsorID] = useState('');

    const columns = [
        {
            name: "StudyID",
            selector: "StudyID",
            omit: true
        },
        {
            name: "Sponsor Name",
            selector: "SponsorName",
            sortable: true,
            center: true
        },
        {
            name: "Study Name",
            selector: "StudyName",
            sortable: true,
            center: true
        },
        {
            name: "#Sites",
            selector: "Sites",
            sortable: true,
            center: true
        },
        {
            name: "#Subjects",
            selector: "Subjects",
            sortable: true,
            center: true,
        },
        {
            name: "New/QC Complete",
            selector: "New_QCComplete",
            sortable: true,
            center: true
        },        
        {
            name: "Read Assigned",
            selector: "ReadAssigned",
            sortable: true,
            center: true
        },
        {
            name: "Adj Required",
            selector: "AdjRequired",
            sortable: true,
            center: true,
        },        
        {
            name: "Closed",
            selector: "ClosedQueries",
            sortable: true,
            center: true
        },
        {
            name: "#Local Reads",
            selector: "LocalReads",
            sortable: true,
            center: true
        },     
        {
            name: "# Open",
            selector: "Q_Open",
            sortable: true,
            center: true
        },
        {
            name: "# Responded",
            selector: "Q_Responded",
            sortable: true,
            center: true,
        },
        {
            name: "# Closed",
            selector: "Q_Closed",
            sortable: true,
            center: true,
        }
    ];

    const filteredColumns = ['SponsorName'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }
    const fetchStudies = async () => {
        fetch('api/Site/GetStudiesByUser')
            .then(response => response.json())
            .then(data => {
                setStudyList(data);
                var selStudyID = document.getElementById("ddlStudies").value;
                setStudyID(selStudyID);
                fetchSponsors();
                fetchDMGlobalDashboardData();
            });
    }
    const fetchSponsors = async () => {
        fetch('api/Site/GetSponsors')
            .then(response => response.json())
            .then(data => {
                setSponsorList(data);
            });
    }
    const fetchDMGlobalDashboardData = () => {
        fetch('api/Dashboard/GetDMGlobalDashboard?StudyID=' + studyID + '&SponsorID=' + sponsorID)
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }
    const onStudyChangeHandler = (e) => {
        setInputVal("");
        setStudyID(e.target.value);
    }
    const onSponsorChangeHandler = (e) => {
        setInputVal("");
        setSponsorID(e.target.value);
    }  

    useEffect(() => {
        fetchStudies();
    }, [studyID, sponsorID]);

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={2}>
                <SelectList id='ddlStudies' dataSource={studyList} onChangeHandler={onStudyChangeHandler} style={{ width: "300px" }} />
            </Col>
            <Col md={1} style={{ width: '70px' }}></Col>
            <Col md={2}>
                <SelectList id='ddlSponsors' dataSource={sponsorList} onChangeHandler={onSponsorChangeHandler} style={{ width: "300px" }} />
            </Col>
            <Col md={3}></Col>
            <Col>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
        </Row>
        <div className="tableContainer">
            <table style={{ backgroundColor: '#CCDDEE', color: '#2f3132', fontSize: '12pt', fontWeight: 'bold', borderStyle: 'solid', borderWidth: 'thin', borderColor:'silver' }}>
                <tr>
                    <td colSpan="8" style={{ width: '33.5%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'silver' }}></td>
                    <td colSpan="8" style={{ width: '41.5%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'silver' }}>Task Stage</td>
                    <td colSpan="3" style={{ width: '26%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'silver'}}>#Queries</td>
                </tr>
                    </table>
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
};

export default DMGlobalDashboard;
