import React, { useEffect } from 'react';
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import MultiSelectList from '../UI/Control/MultiSelectList';
import ArrowLeft from '../../Images/Icons/ArrowLeftBorder.svg';
import ArrowRight from '../../Images/Icons/ArrowRightBorder.svg';

const FirstLocalRead = (props) => {
    // Add to selected list.
    function hanldleLeftShift(ctrl_avl, ctrl_sel) {
        let avlList = document.getElementById(ctrl_avl);
        let selList = document.getElementById(ctrl_sel);
        let options = avlList.selectedOptions;
        if (options.length > 0) {
            Array.from(options).map((option) => {
                selList.appendChild(option);
            });
            Array.from(options).map((option) => {
                avlList.removeChild(option);
            });
        }
        else
            alert('Select form to assign.')
    }

    //Add item(s) to available list.
    function handleRightShift(ctrl_avl, ctrl_sel) {
        let avlList = document.getElementById(ctrl_avl);
        let selList = document.getElementById(ctrl_sel);
        let options = selList.selectedOptions;
        if (options.length > 0) {
            Array.from(options).map((option) => {
                avlList.appendChild(option);
            });
            Array.from(options).map((option) => {
                selList.removeChild(option);
            });
        }
        else
            alert('Select form to revoke.')
    }

    useEffect(() => {
        if (props.selectedData.length > 0) {

            let avlList = document.getElementById("multiSelectFLR_" + props.sequence);
            let selList = document.getElementById("multiSelectFLRSel_" + props.sequence);
            
            for (var iCount = 0; iCount < props.selectedData.length; iCount++) {
                for (var pCount = 0; pCount < props.selectedData[iCount].length; pCount++) {
                    for (var i = 0; i < avlList.options.length; i++) {
                        if (props.selectedData[iCount][pCount].FormID == avlList.options[i].value) {
                            avlList.options[i].selected = (props.selectedData[iCount].FormID = avlList.options[i].value);
                        }
                    }
                }
            }

            let options = avlList.selectedOptions;
            if (options.length > 0) {
                Array.from(options).map((option) => {
                    selList.appendChild(option);
                });
                Array.from(options).map((option) => {
                    avlList.removeChild(option);
                });
            }
        }
    }, []);

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="row" style={{ marginTop: "5px", marginBottom: "5px" }}>
                    <div className="col-sm-5" style={{ width: "46%", paddingLeft: "25px", paddingRight: "25px" }}>                        
                        <InputString id={"txtFLRSearch_" + props.sequence} onChangeHandler={props.searchChangeHandlerLocalRead} placeholder="Search" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }}></InputString>
                    </div>
                    <div className="col-sm-2" style={{ width: "8%" }}>
                    </div>
                    <div className="col-sm-5" style={{ width: "46%", paddingLeft: "25px", paddingRight: "25px" }}>                        
                        <InputString id={"txtFLRSearchSelected_" + props.sequence} onChangeHandler={props.searchChangeHandlerLocalReadSelected} placeholder="Search" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }}></InputString>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: "10px" }}>
                    <div className="col-sm-5" style={{ width: "46%", paddingLeft: "25px", paddingRight: "25px" }}>
                        <MultiSelectList id={"multiSelectFLR_" + props.sequence} dataSource={props.multiSelectDataSource} selectedData={props.selectedData} isMultiSelect="true" size="8" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled} ></MultiSelectList>
                    </div>
                <div className="col-sm-2 d-flex align-items-center justify-content-center" style={{ width: "8%" }}>
                    <table style={{ border: "none", marginTop: "5px" }}>
                        <tr>
                            <td>
                                    {!props.isDisabled && <img src={ArrowRight} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt=">>"
                                        onClick={() => hanldleLeftShift("multiSelectFLR_" + props.sequence, "multiSelectFLRSel_" + props.sequence)} />}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                    {!props.isDisabled && <img src={ArrowLeft} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt="<<"
                                        onClick={() => handleRightShift("multiSelectFLR_" + props.sequence, "multiSelectFLRSel_" + props.sequence)} />}
                            </td>
                        </tr>
                    </table>
                </div>
                    <div className="col-sm-5" style={{ width: "46%", paddingLeft: "25px", paddingRight: "25px" }}>
                        <MultiSelectList id={"multiSelectFLRSel_" + props.sequence} isMultiSelect="true" size="8" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></MultiSelectList>
                </div>
            </div>
            <div className="row" style={{ marginBottom: "5px", display:'none' }}>
                <div className="col-sm-12">
                    <div class="form-group">
                        <label for={"selectFLR_" + props.sequence}>Select CRF Version</label>
                            <SelectList id={"selectFLR_" + props.sequence} dataSource={props.selectDataSource} style={{ "margin-top": "0px" }} disabled={props.isDisabled}></SelectList>
                    </div>
                </div>
            </div>
            </div>
            </div>
    );
};

export default FirstLocalRead;
