import React from 'react';
const MultiSelectList = (props) => {  
    let length = 0;
    let isSelected = false;
    return (
        <select id={props.id} onChange={props.onChangeHandler} className="form-select" style={props.style} size={props.size} multiple={props.isMultiSelect == "false" ? false : true} disabled={props.disabled}>
            {props.dataSource && props.dataSource.map((listItem) => {
                isSelected = false;
               
                if (typeof props.selectedData != 'undefined') {
                    if (props.selectedData.indexOf(listItem.Value) != -1) {
                        isSelected = true;
                    }
                }
                else {
                    if (listItem.Value == props.selectedData) {
                        isSelected = true;
                    }
                }
                
                if (isSelected)
                    return <option value={listItem.Value} selected>{listItem.Name}</option>
                else
                    return <option value={listItem.Value}>{listItem.Name}</option>

            })}
        </select>
    );
};
export default MultiSelectList;

