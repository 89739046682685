import React from 'react';

const InputCheckbox = (props) => {
    return (
        <div style={(props.orientation && props.orientation === 'horizontal') && { display: "inline-flex" }}>
        <input type="checkbox"
            className="form-check-input"
            id={props.id}
            name={props.name}
            style={props.style}
            disabled={props.disabled}
            onChange={props.onChange} />
            <label for={props.id}> {props.text} </label>
        </div>
    );
};

export default InputCheckbox;
