import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputNumber from '../UI/Control/InputNumber';
import classes from './../TrialstatNoto.module.css';

const RateModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const SavePayment = (event) => {

        var minRange = document.getElementById('txtMinRange').value;
        if (minRange == '') {
            alert('Minimum range should not be empty.')
            document.getElementById('txtMinRange').focus();
            return false;
        }

        var maxRange = document.getElementById('txtMaxRange').value;
        if (maxRange == '') {
            alert('Maximum range should not be empty.')
            document.getElementById('txtMaxRange').focus();
            return false;
        }

        if (parseInt(minRange) > parseInt(maxRange)) {
            alert('Minimum range should not be greater than maximum range.')
            document.getElementById('txtMaxRange').focus();
            return false;
        }

        var totalPayment = document.getElementById('txtTotalPayment').value;
        if (totalPayment == '') {
            alert('Total payment should not be empty.')
            document.getElementById('txtTotalPayment').focus();
            return false;
        }

        let objHRPaySetModel = {
            ReadPayID: 0,
            Min: document.getElementById('txtMinRange').value.trim(),
            Max: document.getElementById('txtMaxRange').value.trim(),
            TotalPayment: document.getElementById('txtTotalPayment').value.trim(),
            ReadPayType: props.paymentType.toLowerCase(),
        };

        fetch('api/GlobalSettings/SaveHistoReadPaySettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objHRPaySetModel)
        }).then(r => r.text()).then(res => {
            if (res == "1") {
                setShow(false);
                props.fetchGetHistoReadPaySettingsDetails(props.paymentType.toLowerCase());
            }
            else if (res == "-2") {
                alert('Duplicate payment range!');
                return false;
            }
            else {
                alert(props.paymentType + ' payment not saved, Something went wrong.');
            }
        })
    };

    return (
        <>
            <Button variant="primary" className="btn btn-sm btn-success" onClick={handleShow}> Add Rate  </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Four} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-1">
                        </div>
                        <div className="col-sm-4" style={{ marginTop:"25px" }}>
                            <label>Read Range</label>
                        </div>
                        <div className="col-sm-3">
                            <label>Min</label>
                            <InputNumber id="txtMinRange" maxlength="2"></InputNumber>
                        </div>
                        <div className="col-sm-3">
                            <label>Max</label>
                            <InputNumber id="txtMaxRange" maxlength="2"></InputNumber>
                        </div>
                        <div className="col-sm-1">
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-1">
                        </div>
                        <div className="col-sm-4">
                            <label>Total Payment</label>
                        </div>
                        <div className="col-sm-6">
                            <InputNumber id="txtTotalPayment" maxlength="9"></InputNumber>
                        </div>
                        <div className="col-sm-1">
                        </div>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={SavePayment}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default RateModal;