import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import AdjudicationValidateProgram from './AdjudicationValidateProgram';
import InputTextarea from '../UI/Control/InputTextarea';
import classes from './../TrialstatNoto.module.css';
import { useSelector } from 'react-redux'
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import AdjCodeModal from './AdjCodeHistoyModal';


const AdjudicationPrograming = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [show, setShow] = useState(false);
    const handleValidate = () => { }
    const handleSave = () => { };
    const handleClose = () => setShow(false);
    const handleShow = () => {
        fetchAdjConfiguration();
        fetchAdjNotes();
        setShow(true);
    };
    const [rowData, setRows] = useState([]);

    const fetchAdjNotes = () => {
        fetch('api/AdjudicationSettings/GetAdjNotes?studyReadSettingsID=' + props.studyReadSettingsID)
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    };

    const fetchAdjConfiguration = () => {
        fetch('api/AdjudicationSettings/GetAdjConfiguration?studyReadSettingsID=' + props.studyReadSettingsID)
            .then(response => response.json())
            .then(data => {
                document.getElementById('txtAdjDetermination').value = data.AdjDetermination;
                //document.getElementById('txtAdjOutDetermination').value = data.AdjOutDetermination; 

            });
    };

    const SaveAdjConfig = (event) => {
        let objAdjDetermination = document.getElementById('txtAdjDetermination');
        let objAdjNotes = document.getElementById('txtAdjNotes');
        //let objAdjOutDetermination = '';

        if (objAdjDetermination.value.trim() == '') {
            alert('Adjudication determination should not be empty.');
            objAdjDetermination.focus();
            return false;
        }

        if (objAdjNotes.value.trim() == '') {
            alert('Adjudication notes should not be empty.');
            objAdjNotes.focus();
            return false;
        }

        let objAdjConfig =
        {
            StudyReadSettingsID: props.studyReadSettingsID,
            AdjDetermination: objAdjDetermination.value,
            AdjOutDetermination: '',
            UpdatedBy: EDCUserID,
            AdjNotes: objAdjNotes.value
        }

        fetch('api/AdjudicationSettings/UpdateAdjConfiguration', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objAdjConfig)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Adjudication configuration not saved, Something went wrong.');
                return false;
            }
            if (res > 0 || res == "" ) {
                props.fetchAdjSettingDetail();
                setShow(false);
            }
        })
        return true;
    };

    return (
        <>
            <Button className={"btn btn-md " + (props.isAdjConfigSubmit === true ? "btn-success" : "btn-warning")} variant="primary" onClick={handleShow}> {props.text} </Button>
            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_One} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>Adjudication Programming</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ "padding-bottom": "10px" }}><b>{props.header}</b></div>
                    <p><b>Adjudication Determination</b><MandatoryLabel></MandatoryLabel></p>
                    <InputTextarea id="txtAdjDetermination" rows="4" cols="75"></InputTextarea>

                    <p><b>Adjudication Notes</b><MandatoryLabel></MandatoryLabel></p>
                    <InputTextarea id="txtAdjNotes" rows="2" cols="75" maxlength="200"></InputTextarea>
                    <p></p>
                    <div style={{ "display": rowData.length > 0 ? " " : "none" }}>
                        <p> <strong>Notes History</strong> </p>
                        <table>
                            <tr>
                                <th className={classes.tbHeaderCaptionDark} style={{ "text-align": "center" }} >Adjudication Notes</th>
                                <th className={classes.tbHeaderCaptionDark} style={{ "text-align": "center" }} >User</th>
                                <th className={classes.tbHeaderCaptionDark} style={{ "text-align": "center" }} >Date</th>
                                <th className={classes.tbHeaderCaptionDark} style={{ "text-align": "center" }} >Action</th>
                            </tr>
                            <tbody>
                                {rowData.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item.AdjNotes}</td>
                                            <td>{item.UpdatedBy}</td>
                                            <td>{item.HistoryDate}</td>
                                            <td>
                                                {
                                                    <AdjCodeModal btn="true" text="View Adjudication Code" AdjCode={item.AdjDetermination} AdjTitle="Adjudication Code from history" ></AdjCodeModal>
                                                }
                                            </td>
                                        </tr>);
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ "text-align": "center" }}>
                        {/*<AdjudicationValidateProgram disabled= {props.disabled} text = "Validate"/>  */}
                        {/*{!props.disabled && <EDCProjectModal SaveAdjConfigAction={SaveAdjConfig} CloseAdjPopup={handleClose}></EDCProjectModal>}  */}
                        {!props.disabled && <Button className="btn btn-md btn-success" variant="primary" style={{ "margin-left": "10px" }} onClick={SaveAdjConfig}> Save </Button>}
                        <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose} style={{ "margin-left": "10px" }}> Cancel </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AdjudicationPrograming;