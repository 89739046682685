import React from 'react';
const SelectList = (props) => { 
    let isSelected = false;
    return (
        <select id={props.id} onChange={props.onChangeHandler} className="form-select" style={props.style} disabled={props.disabled} value={props.value}>
            {
                props.dataSource && props.dataSource.map((listItem) => {
                    isSelected = false;
                    if (listItem.Value == props.selectedData) {
                        isSelected = true;
                    }

                    if (isSelected)
                        return <option value={listItem.Value} selected>{listItem.Name}</option>
                    else
                        return <option value={listItem.Value}>{listItem.Name}</option>
                })
            }
        </select>
    );
};
export default SelectList;
