import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import customStyles from '../customStyles';
import NoData from '../NoData';

const DistributionList = (props) => {
    const usrRole = useSelector(state => state.auth.UserRole);
    const navigate = useNavigate();
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const onCreateDistribution = () => {
        navigate({
            pathname: '/Distribution',
            search: '?action=add',
        });
    }

    const onEditDistribution = () => {
        navigate({
            pathname: '/Distribution',
            search: '?action=edit',
        });
    }

    const onViewDistribution = () => {
        navigate({
            pathname: '/Distribution',
            search: '?action=view',
        });
    }

    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');    
    useEffect(() => {
        setCompPermission();
    }, [])
    const setCompPermission = () => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO")
        else if (usrRole == "Database Designer" || usrRole == "Super User")
            setUserPermisssion("RW")
        else if (usrRole == "Tester")
            setUserPermisssion("RO")
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management") {
            setUserPermisssion("RO")
            setmanageSitePermission("RW")
        }
        else
            setUserPermisssion("NA")
    }
    const columns = [
        {
            name: "Name of List",
            selector: "NameofList",
            sortable: true,
            center: true
        },
        {
            name: "Last Updated",
            selector: "LastUpdated",
            sortable: true,
            center: true,
            sortFunction: (a, b) => { return props.sortByDateTime(a.LastUpdated, b.LastUpdated); }
        },
        {
            name: "Updated By",
            selector: "UpdatedBy",
            sortable: true,
            center: true,
        },
        {
            name: "Study Name",
            selector: "StudyName",
            sortable: true,
            center: true
        },
        {
            name: "Internal / External",
            selector: "InternalExternal",
            sortable: true,
            center: true
        },
        {
            name: "Number of Recipients",
            selector: "NumberofRecipients",
            sortable: true,
            center: true,
        },
        {
            name: "Last Used",
            selector: "LastUsed",
            sortable: true,
            center: true,
            sortFunction: (a, b) => { return props.sortByDateTime(a.LastUsed, b.LastUsed); }
        },
        {
            name: "Status",
            selector: "Status",
            sortable: true,
            center: true
        },
        {
            name: "Action",
            button: true,
            width: '350px',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {userPermission == "RW" && <button style={{ "margin": "5px" }} className="btn btn-sm btn-success"> Duplicate </button>}
                    {(userPermission == "RW" || userPermission == "RO") && <button style={{ "margin": "5px" }} className="btn btn-sm btn-success" onClick={onViewDistribution}> View </button>}
                    {userPermission == "RW" && <button style={{ "margin": "5px" }} className="btn btn-sm btn-success" onClick={onEditDistribution}> Edit </button>}
                    {userPermission == "RW" && <button style={{ "margin": "5px" }} className="btn btn-sm btn-danger"> Delete </button>}
                    {userPermission == "RW" && <button style={{ "margin": "5px" }} className={row.Status === "Active" ? "btn btn-sm btn-secondary" : "btn btn-sm btn-success"}> {row.Status === "Active" ? "Disable" : "Active"} </button>}
                </div >
            )
        }
    ];

    let data = [
        {
            NameofList: 'List01',
            LastUpdated: "02 January 2022",
            UpdatedBy: "DemoUser",
            StudyName: "Demo 01",
            InternalExternal: "Both",
            NumberofRecipients: 10,
            LastUsed: "21 January 2022",
            Status: "Active"
        }        
    ];

    function search(rows) {
        const columns = rows[0] && Object.keys(rows[0]);
        if (q != 'All') {
            let selString = q.toString();
            let searchText = selString.toLowerCase().split(",");
            return rows.filter((row) => {
                return columns.some((column) => {
                    if (searchText.length > 1)
                        return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                    else
                        return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
                })
            })
        }
        else
            return rows;
    }
    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }    


    const arrStudyName = [
        {
            Name: "All(Default)",
            Value: "All"
        },
        {
            Name: "Study 1",
            Value: "Study 1"
        },
        {
            Name: "Study 2",
            Value: "Study 2"
        },
        {
            Name: "Study 3",
            Value: "Study 3"
        },
        {
            Name: "Study 4",
            Value: "Study 4"
        },
        {
            Name: "Study 5",
            Value: "Study 5"
        }
    ]

    const studyChangeHandler = (e) => {
        setQ(e.target.value.trim());
        setInputVal("");
    }

    return <Card>
        <Row>
            <Col md={4} className="form-inline mb-2">
                <SelectList id="selectStudyName" dataSource={arrStudyName} onChangeHandler={studyChangeHandler} style={{ width: "90%" }} />
            </Col>
            <Col md={6}>
                <div className="input-group">
                   
                    <InputString id="txtSearch" onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={2}>
                {userPermission == "RW" && <Button style={{ "float": "right" }} onClick={onCreateDistribution} className="btn btn-success">Create</Button>}
            </Col>
        </Row>
        <div className="tableContainer">
            { search(data).length > 0 ? <DataTable
                    columns={columns}
                    data={search(data)}
                    className="table table-striped table-bordered table-hover"
                    customStyles={customStyles}
                    striped
                    pagination
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                /> : <NoData headers={columns}></NoData> }
        </div>
    </Card>
}

export default DistributionList;
