import React, { useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import classes from './../TrialstatNoto.module.css';
import SelectList from '../UI/Control/SelectList';
import EditIcon from '../../Images/Icons/edit.svg';
import InputString from '../UI/Control/InputString';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import { useSelector } from 'react-redux'
import ViewIcon from '../../Images/Icons/view.svg';
import CopyIcon from '../../Images/Icons/copy.svg';

const CRFSelection = (props) => {
    const [show, setShow] = useState(false);
    const [arrVisit, setVisitList] = useState([]);
    const [arrParadigm, setParadigmList] = useState([]);
    const [VeriCRFDisabled, setVeriCRFDisabled] = useState('');
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [arrSourceType, setArrSourceType] = useState([]);
    const [showAPITextBox, setAPITextBox] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setAPIVisits('');
        setAPITextBox(false);
        fillVisitList();
        setShow(true);
    };
    
    //Retrive the state from app store
    const fillVisitList = () => {
        setVeriCRFDisabled('');
        fetch('api/ReadSettings/fillVisitList?studyProtocolId=' + props.studyProtocolID)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setVisitList(arr);   
                document.getElementById('txtAPILink').hidden = true;
                FillParadigmList();
                //GetAPILinkVisitList();
            });
    }

    //Retrive Paradigm
    const FillParadigmList = () => {
        fetch('api/ReadSettings/FillParadigmList')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setParadigmList(arr);
                FillSourceTypeList();
            });
    }

    //Retrive Source Type
    const FillSourceTypeList = () => {
        fetch('api/ReadSettings/FillSourceTypeList?studyProtocolId=' + props.studyProtocolID)
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '0', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setArrSourceType(arr);
                if (props.studyReadSettingsID > 0) {
                    fetchReadSettingDetails();
                }
            });
    }

    const fetchReadSettingDetails = () => {
        fetch('api/ReadSettings/GetReadSettingsDetail?studyProtocolId=' + props.studyProtocolID + '&studyReadSettingsID=' + props.studyReadSettingsID)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    function setData(data) {
       // alert(data.ReadType)
        document.getElementById('selectVisit').value = data.StudyVisitID;
        document.getElementById('selectType').value = data.ReadType;
        if (data.ReadType == 1) {
            GetAPILinkVisitList();
            document.getElementById('ddlAPIVisits').value = data.APILink;
        }
        else if (data.ReadType == 1 || data.ReadType == 2) {
            setAPITextBox(true);
            document.getElementById('txtAPILink').hidden = false;
            document.getElementById('ddlAPIVisits').hidden = true;
            setAPIVisits([]);
        }
        else {
            HideAPILink();
        }
        document.getElementById('selectParadigm').value = data.ReadParadigm;
        document.getElementById('hdnReadParadigmId').value = data.ReadParadigm;
        const selectedIndex = document.getElementById('selectParadigm').selectedIndex;
        const selectedOption = document.getElementById('selectParadigm').options[selectedIndex];
        document.getElementById('hdnParadigmName').value = selectedOption.text;
        //document.getElementById('selectVariabilityRead').value = data.Variability;

        if (data.Variability == 'N')
            setVeriCRFDisabled('disabled');
        else
            setVeriCRFDisabled('');

        document.getElementById('hdnAPILink').value = data.APILink;
        document.getElementById('txtAPILink').value = data.APILink;
        document.getElementById('txtTags').value = data.Tags;        
    }

    const [arrAPIVisits, setAPIVisits] = useState([]);
    const GetAPILinkVisitList = () => {
        var VistsItemList = [{ Value: "-1", Name: "-- Select API Link--" }];
        setAPITextBox(false);
        var isShowTxtBox = false;
        fetch("api/CIMSServicesCall/GetVisitsInCIMSStudy?SpId=" + props.studyProtocolID)
            .then(response => response.text())
            .then(data => {
                
                if (data != "" && data != "emptyid") {
                    var apiRes = JSON.parse(data);
                    var cimsVisits = apiRes.visits;
                    
                    if (cimsVisits.length == 0) {
                        isShowTxtBox = true;
                    }
                    else {
                        for (var iCount = 0; iCount < cimsVisits.length; iCount++) {

                            VistsItemList.push({ Value: cimsVisits[iCount], Name: cimsVisits[iCount] });
                        }
                    }
                }
                else if (data == "emptyid") {
                    //alert('API Link list not available, please add manually');
                    isShowTxtBox = true;
                }
                else
                    alert('Error while getting API Link details,  please look into the ErrorLog Table.');

                setAPIVisits(VistsItemList);
                
                if (isShowTxtBox == true) {
                    setAPITextBox(true);
                    document.getElementById('txtAPILink').hidden = false;
                    document.getElementById('ddlAPIVisits').hidden = true;
                }
                else if (props.studyReadSettingsID > 0) {
                    document.getElementById('txtAPILink').hidden = true;
                    document.getElementById('ddlAPIVisits').hidden = false;
                    document.getElementById("ddlAPIVisits").value = document.getElementById('hdnAPILink').value;
                }
            })
    }    

    function ValidateCRFSelection() {
        const objVisit = document.getElementById('selectVisit');
        if (objVisit.value == '') {
            alert('Please select Visit.');
            objVisit.focus();
            return false;
        }

        const objType = document.getElementById('selectType');
        if (objType.value == '0') {
            alert('Please select read type.');
            objType.focus();
            return false;
        }

        const objParadigm = document.getElementById('selectParadigm');
        if (objParadigm.value == '') {
            alert('Please select read paradigm.');
            objParadigm.focus();
            return false;
        }

        if (document.getElementById('selectType').value == 1 || document.getElementById('selectType').value == 2) {
            let objAPILink = document.getElementById('ddlAPIVisits');
            if (showAPITextBox == true) {
                objAPILink = document.getElementById('txtAPILink');
                if (objAPILink.value.trim() == '') {
                    alert('API link should not be empty.');
                    objAPILink.focus();
                    return false;
                }
            }
            else {
                const objAPILink = document.getElementById('ddlAPIVisits');
                if (objAPILink.value == '-1') {
                    alert('API link should not be empty.');
                    objAPILink.focus();
                    return false;
                }
            }
        }
        return true;
    }

    const SubmitReadSettingsData = (event) => {
        if (props.studyReadSettingsID == 0) {
            SaveReadSettings();
        }
        else {
            UpdateReadSettings();
        }

    };
    const SaveReadSettings = (event) => {
        const retVal = ValidateCRFSelection();
        if (retVal == false)
            return false;

        var selectedText = '';
        if (document.getElementById('selectType').value == 1 || document.getElementById('selectType').value == 2) {
            var objDDL = document.getElementById("ddlAPIVisits");          

            if (showAPITextBox == true) {
                selectedText = document.getElementById('txtAPILink').value;
            }
            else {
                selectedText = objDDL.options[objDDL.selectedIndex].text;
            }
        }
        
        let readSettingsModel =
        {
            StudyReadSettingsID: 0,
            StudyProtocolID: props.studyProtocolID,
            VisitShort: document.getElementById('selectVisit').value,
            StudyVisitID: document.getElementById('selectVisit').value,
            ReadType: document.getElementById('selectType').value,
            ReadParadigm: document.getElementById('selectParadigm').value,
            Variability: 'N', //document.getElementById('selectVariabilityRead').value,
           // VariabilityFormID: document.getElementById('selectVariabilityCRF').value,
            APILink: selectedText,
            Tags: document.getElementById('txtTags').value,
            Username: EDCUserID
        }

        fetch('api/ReadSettings/SaveReadSettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(readSettingsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Read settings not saved, Something went wrong.');
                return false;
            }
            if (res == 0) {
                alert('Read settings already exist.');
                return false;
            }
            if (res == -2) {
                var objSelType = document.getElementById('selectType');
                var selectText = objSelType.options[objSelType.selectedIndex].innerHTML;
                alert('API link already exist for source type ' + selectText + '.');
                return false;
            }
            if (res > 0) {
                props.fetchReadSettingData();
                setShow(false);
            }
        })
        return true;
    };

    const UpdateReadSettings = (event) => {
        const retVal = ValidateCRFSelection();
        if (retVal == false)
            return false;

        if (props.IsParadigmReads.toLowerCase() == 'yes' && document.getElementById('hdnReadParadigmId').value != document.getElementById('selectParadigm').value) {
            const selectedIndex = document.getElementById('selectParadigm').selectedIndex;
            const selectedOption = document.getElementById('selectParadigm').options[selectedIndex];
            let con = confirm('You have changed read paradigm from "' + document.getElementById('hdnParadigmName').value + '" to "' + selectedOption.text + '", You will loose saved paradigm reads, Are you sure, you want to continue.');
            if (!con) {
                return false;
            }
        }

        var selectedText = '';
        if (document.getElementById('selectType').value == 1 || document.getElementById('selectType').value == 2) {
            var objDDL = document.getElementById("ddlAPIVisits");
            if (showAPITextBox == true) {
                selectedText = document.getElementById('txtAPILink').value;
            }
            else {
                selectedText = objDDL.options[objDDL.selectedIndex].text;
            }
        }
       
        let readSettingsModel =
        {
            StudyReadSettingsID: props.studyReadSettingsID,
            StudyProtocolID: props.studyProtocolID,
            VisitShort: document.getElementById('selectVisit').value,
            StudyVisitID: document.getElementById('selectVisit').value,
            ReadType: document.getElementById('selectType').value,
            ReadParadigm: document.getElementById('selectParadigm').value,
            Variability: 'N', 
            APILink: selectedText,
            Tags: document.getElementById('txtTags').value,
            Username: EDCUserID,
            ReadParadigmIdPrevious: props.IsParadigmReads.toLowerCase() == 'yes' ? document.getElementById('hdnReadParadigmId').value : 0
        }

        fetch('api/ReadSettings/UpdateReadSettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(readSettingsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Read settings not updated, Something went wrong.');
                return false;
            }
            if (res == 0) {
                alert('Read settings already exist.');
                return false;
            }
            if (res == -2) {
                var objSelType = document.getElementById('selectType');
                var selectText = objSelType.options[objSelType.selectedIndex].innerHTML;
                alert('API link already exist for source type ' + selectText + '.');
                return false;
            }
            if (res > 0) {
                props.fetchReadSettingData();
                setShow(false);
            }
        })
        return true;
    };

    const arrVariabilityRead = [
        {
            Name: "Yes",
            Value: "Y"
        },
        {
            Name: "No",
            Value: "N"
        }]

    const arrVariabilityCRF = [
        {
            Name: "",
            Value: "0"
        },
        {
            Name: "VARCRF",
            Value: "1"
        }]
        
    const slectTypeangeHandler = (e) => {           
        if (document.getElementById('selectType').value == 1 || document.getElementById('selectType').value == 2) {
            let actType = document.getElementById("selectType");     
            var selectedText = actType.options[actType.selectedIndex].text;
            document.getElementById('divAPILink').hidden = false;

            if (selectedText.toLowerCase() == "cims")
                GetAPILinkVisitList();
            else {
                setAPITextBox(true);
                document.getElementById('txtAPILink').hidden = false;
                document.getElementById('ddlAPIVisits').hidden = true;
                setAPIVisits([]);
            }
        }
        else {
            HideAPILink();
        }
    };
    const HideAPILink = () => {
        setAPITextBox(false);
        document.getElementById('txtAPILink').hidden = true;
        document.getElementById('ddlAPIVisits').hidden = true;
        setAPIVisits([]);
        document.getElementById('divAPILink').hidden = true;
    };

    const VariabilityReadChange = (e) => {
        if (e.target.value == "Y") {
            setVeriCRFDisabled('');
        }
        else {
            setVeriCRFDisabled('disabled');
        }
    };

    return (
        <>
            
            {props.btn === "undefined" && <Button variant="primary" className="btn btn-success" onClick={handleShow}> {props.text}  </Button>}
            {props.btn === "true" && props.action === "add" && <Button variant="primary" className="btn btn-success" onClick={handleShow} style={{ float:"right" }}> {props.text}  </Button>}
            {/*props.btn === "true" && props.action === "edit" && <a className="dropdown-item" onClick={handleShow}>{props.text}</a>*/}
            {props.btn === "true" && props.action === "edit" && <button className="btn btn-success" type="button" onClick={handleShow}> {props.text} </button>}
            {props.btn === "false" && <a style={{ cursor: "pointer" }} onClick={handleShow}> {props.text}  </a>}
           
            {props.btn === "icon" && props.action === "edit" && <img className="" style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "10px" }} height="34px" width="34px" src={EditIcon} alt="Edit" onClick={handleShow} />}
            {props.btn === "icon" && props.action === "view" && <img className="" style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "10px" }} height="34px" width="34px" src={ViewIcon} alt="View" onClick={handleShow} />}
            {props.btn === "icon" && props.action === "copy" && <img className="" style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "10px" }} height="34px" width="34px" src={CopyIcon} alt="Edit" onClick={handleShow} />}


            <Modal show={show} onHide={handleClose} dialogClassName={classes.TSModalDailog_Ten} animation={false} backdrop="static">
                <Modal.Header className={classes.modalHeader}>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        <Modal.Title>{props.header}</Modal.Title>
                   </div>
                </Modal.Header>
                <Modal.Body>
                    <div class="form-group" style={{ marginTop: "10px" }}>
                        <label for="selectVisit" className={classes.label_One}>Visit<MandatoryLabel></MandatoryLabel></label>
                        <SelectList id="selectVisit" dataSource={arrVisit} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></SelectList>
                    </div>
                    <div class="form-group" style={{ marginTop: "15px" }}>
                        <label for="selectType" className={classes.label_One}>Type<MandatoryLabel></MandatoryLabel></label>
                        <SelectList id="selectType" dataSource={arrSourceType} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} onChangeHandler={slectTypeangeHandler} disabled={props.isDisabled} ></SelectList>
                    </div>
                    <div class="form-group" style={{ marginTop: "15px" }}>
                        <input type="hidden" id="hdnReadParadigmId" />
                        <input type="hidden" id="hdnParadigmName" />
                        <label for="selectParadigm" className={classes.label_One}>Read Paradigm<MandatoryLabel></MandatoryLabel></label>
                        <SelectList id="selectParadigm" dataSource={arrParadigm} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></SelectList>
                    </div>
                    <div class="form-group" style={{ marginTop: "15px", display: "none" }}>
                        <label for="selectVariabilityRead" className={classes.label_One}>Variability Read</label>
                        <SelectList id="selectVariabilityRead" dataSource={arrVariabilityRead} onChangeHandler={VariabilityReadChange} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></SelectList>
                    </div>
                    <div class="form-group" style={{ display: "none", marginTop: "15px" }}>
                        <label for="selectVariabilityCRF" className={classes.label_One}>Variability CRF<MandatoryLabel></MandatoryLabel></label>
                        <SelectList id="selectVariabilityCRF" dataSource={arrVariabilityCRF} disabled={VeriCRFDisabled} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></SelectList>
                    </div>
                    <div id="divAPILink" className="form-group" style={{ marginTop: "15px" }}>
                        <label htmlFor="ddlAPIVisits" className={classes.label_One}>API LINK<MandatoryLabel /></label>
                        <input type="hidden" id="hdnAPILink" />
                        <SelectList id="ddlAPIVisits" dataSource={arrAPIVisits} style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled} />
                        <InputString id="txtAPILink" style={{ marginTop: "5px", borderWidth: '1px', lineHeight: "1.8rem", fontSize: "18px"}} />
                    </div>
                    <div class="form-group" style={{ marginTop: "15px", }}>
                        <label for="txtTags" className={classes.label_One}>Tags</label>
                        <InputString id="txtTags" style={{ marginTop: "5px", borderWidth: '1px', lineHeight: "1.8rem", fontSize: "18px" }} width="1499"></InputString>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                        {!props.disabled && props.action == "copy" && <Button className="btn btn-success" onClick={CopyReadsetting} style={{ marginRight:"5px" }}> Copy </Button>}
                        {!props.disabled && (props.action == "add" || props.action == "edit") && <Button variant="secondary" className="btn btn-success" onClick={SubmitReadSettingsData} style={{ marginRight: "10px" }}> Submit </Button>}
                            <Button variant="primary" className="btn btn-danger" onClick={handleClose}>
                                Cancel
                            </Button>  
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default CRFSelection;