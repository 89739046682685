import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import NotificationDetailsModel from './NotificationDetailsModel'
import classes from '../TrialstatNoto.module.css'
import customStyles from '../customStyles';
import NoData from '../NoData';
import CopyIcon from '../../Images/Icons/copy.svg';
import DeleteIcon from '../../Images/Icons/delete.svg';
import AssignedUserRoleModal from "./AssignedUserRoleModal";
import AssignStudyModal from "./AssignStudyModal";

const ManageUserList = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const selectRef = useRef('');
    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [arrAssignedRole, setAssignedRoleList] = useState([]);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [arrUserRoles, setUserRoles] = useState([]);

    const columns = [
        {
            name: "UserID",
            selector: "UserID",
            omit: true
        },
        {
            name: "EDCUserID",
            selector: "EDCUserID",
            omit: true
        },
        {
            name: "Name",
            selector: "FullName",
            sortable: true,
            center: true
        },
        {
            name: "Email",
            selector: "Email",
            sortable: true,
            center: true,
        },
        {
            name: "TypeLabel",
            selector: "ReadTypes.TypeLabel",
            omit: true,
        },
        {
            name: "Role",
            selector: "RoleLabel",
            sortable: true,
            center: true,
            cell: (row) => (
                <div className={classes.textWrap}>{row.ReadTypes.TypeLabel == '' ? row.RoleLabel : row.RoleLabel + " (" + row.ReadTypes.TypeLabel + ")"}</div>
            )
        },
        {
            name: "SourceTypeID",
            selector: "SourceTypeID",
            omit: true,
        },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: (userPermission == "RW" || userPermission == "RW-CR") ? '300px' : '0px',
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {userPermission == "RW" && <button style={{ "margin": "5px" }} onClick={() => handleResetPassword(row.EDCUserID, row.FullName, row.Email, event)} className="btn btn-info"> Reset password </button>}
                    {userPermission == "RW-CR" && row.SourceTypeID != "" && <button style={{ "margin": "5px" }} onClick={() => handleResetPassword(row.EDCUserID, row.FullName, row.Email, event)} className="btn btn-info"> Reset password </button>}
                </div>
            )
        }
    ];

    const filteredColumns = ['FullName', 'Email', 'RoleLabel'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const userRoleChangeHandler = (e) => {
        fetchUsersData(e.target.value);
    }

    const GetUserPermissionRoles = () => {
        fetch('api/Users/GetUserPermissionRoles')
            .then(response => response.json())
            .then(data => {
                setUserRoles(data);
            });
    }

    const fetchUsersData = (roleId) => {
        if (typeof roleId == 'undefined') {
            roleId = -1;
        }

        fetch('api/Users/GetUsers?roleId=' + roleId)
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }


    useEffect(() => {
        GetUserPermissionRoles();
        fetchUsersData();
        getUserRole();
    }, []);

    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setCompPermission(role);
            });
    };

    const setCompPermission = (usrRole) => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO")
        else if (usrRole == "Database Designer" || usrRole == "Super User")
            setUserPermisssion("RW")
        else if (usrRole == "Tester")
            setUserPermisssion("RW")
        else if (usrRole == "Database Administrator")
            setUserPermisssion("RW")
        else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator")
            setUserPermisssion("RW-CR")
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management")
            setUserPermisssion("RW")
        else
            setUserPermisssion("NA")
    }
    const handleResetPassword = (edcUserId, fullName, email, event) => {
        ResetPassword(edcUserId, fullName, email);
    };

    const ResetPassword = async (edcUserId, fullName, email) => {
        let objUserPasswordDetails = {
            EDCUserId: edcUserId,
            Username: fullName,
            Email: email
        };

        fetch('api/ServiceCall/ResetPassword', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUserPasswordDetails)
        }).then(res => res.json()).then(data => {
            if (data.retVal == 0 || data.retVal == -1) {
                alert('Reset password not done, Something went wrong.');
            }
            if (data.retVal > 0) {
                alert('Reset password done successfully and password details sent on user email id.');
            }
        });
    }

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={3}>
                <SelectList id='ddlUserRole' dataSource={arrUserRoles} onChangeHandler={userRoleChangeHandler} />
            </Col>
            <Col md={3}>
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}

export default ManageUserList;