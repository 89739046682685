import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import CRFSelection from "../CRFSelection/CRFSelection";
import Card from '../UI/Card/Card';
import customStyles from '../customStyles';
import ReadDetailsCRF from "../CRFSelection/ReadDetailsCRF";
import OutcomeInfoCRF from "../CRFSelection/OutcomeInfoCRF";
import ParadigmReads from "../CRFSelection/ParadigmReads";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import EDCProjectModal from '../Designer/EDCProjectModal';
import CopyReadSettingsModal from "../CRFSelection/CopyReadSettingsModal";
import classes from './../TrialstatNoto.module.css';

let studyProID;
const ReadSettings = (props) => {
    studyProID = useSelector(state => state.globaldata.gblDt01);
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [cntRecords, setCntRecords] = useState(0);
    const [cntParadSubmit, setCntParadSubmit] = useState(0);
    const [isEDCProjExist, setIsEDCProjExist] = useState('No');
    const [isTaskInProd, setIsTaskInProd] = useState('No');
    const [arrCustomViewer, setArrCustomViewer] = useState([]);  
    const [isOutComeExists, setsOutComeExists] = useState('no');
    const columns = [
        {
            name: "Visit",
            selector: "VisitShort",
            center: true,
            sortable: true,
            width: '13%'
        },
        {
            name: "Type",
            selector: "ReadType",
            center: true,
            sortable: true,
            width: '7%'
        },
        {
            name: "Read Paradigm",
            selector: "ReadParadigm",
            center: true,
            sortable: true,
            width: '14%'
        },
        {
            name: "Variability",
            selector: "Variability",
            sortable: true,
            center: true,
            width: '8%'
           
        },
        //{
        //    name: "Variability Form",
        //    selector: "VariabilityForm",
        //    center: true,
        //    omit: true
        //},
        {
            name: "API LINK",
            selector: "APILink",
            center: true,
            width: '15%',
            cell: (row) => (
                <div className={classes.textWrap}>{row.APILink}</div>
            )
        },
        {
            name: "Read Info",
            selector: "IsReadInfo",
            center: true,
            width: '8%',
            cell: (d) => (
                <ReadDetailsCRF action={d.IsReadInfo === "No" ? "add" : "edit"} disabled={props.disabled} text={d.IsReadInfo}
                    header={"Read Info - " + d.ReadType + " - " + d.VisitShort} editDataHandler={editCRFSelectionHandler}
                    IsReadInfo={d.IsReadInfo} studyProtocolID={studyProID} studyReadSettingsID={d.StudyReadSettingsID}
                    fetchReadSettingData={fetchReadSettingData} isDisabled={(props.userRole.toLowerCase() == 'super user' && isTaskInProd.toLowerCase() == 'yes') ? true : false}></ReadDetailsCRF >
            )
        },
        {
            name: "Outcome",
            selector: "IsOutcome",
            center: true,
            width: '7%',
            cell: (d) => (               
                <OutcomeInfoCRF action={d.IsOutcome === "No" ? "add" : "edit"} disabled={props.disabled} text={d.IsOutcome}
                    header={"Outcome - " + d.ReadType + " - " + d.VisitShort}
                    editDataHandler={editCRFSelectionHandler} IsOutcome={d.IsOutcome}                   
                    studyProtocolID={studyProID} studyReadSettingsID={d.StudyReadSettingsID}
                    fetchReadSettingData={fetchReadSettingData} isDisabled={(props.userRole.toLowerCase() == 'super user' && isTaskInProd.toLowerCase() == 'yes') ? true : false}></OutcomeInfoCRF>
            )
        },
        {
            name: "Paradigm Reads",
            selector: "IsParadigmReads",
            center: true,
            width: '12%',
            cell: (d) => (
                <ParadigmReads action={d.IsParadigmReads === "No" ? "add" : "edit" } IsParadigmReads={d.IsParadigmReads}
                    disabled={props.disabled} text={d.IsParadigmReads}
                    header={"Paradigm Reads - " + d.ReadType + " - " + d.VisitShort} editDataHandler={editCRFSelectionHandler}
                    studyProtocolID={studyProID} studyReadSettingsID={d.StudyReadSettingsID}
                    fetchReadSettingData={fetchReadSettingData} paradigmId={d.ParadigmID} isDisabled={(props.userRole.toLowerCase() == 'super user' && isTaskInProd.toLowerCase() == 'yes') ? true : false}></ParadigmReads>
            )
        },
        {
            name: "Action",
            selector: 'Options',
            center: true,
            width: '16%',
            cell: (d) => (
                <div>
                    {!props.disabled && isTaskInProd.toLowerCase() == 'no' && <CopyReadSettingsModal text="Copy" header="Copy Read Details" editDataHandler={editCRFSelectionHandler} studyProtocolID={studyProID}
                        studyReadSettingsID={d.StudyReadSettingsID} fetchReadSettingData={fetchReadSettingData} userRole={props.userRole} IsTaskInProd={isTaskInProd}></CopyReadSettingsModal>}
                    {' '}
                    <CRFSelection action="edit" btn="true" disabled={props.disabled} text={props.disabled == true ? "View" : "Edit"}
                        header="Edit Read Details" editDataHandler={editCRFSelectionHandler} studyProtocolID={studyProID} IsParadigmReads={d.IsParadigmReads}
                        studyReadSettingsID={d.StudyReadSettingsID} fetchReadSettingData={fetchReadSettingData} isDisabled={(props.userRole.toLowerCase() == 'super user' && isTaskInProd.toLowerCase() == 'yes') ? true : false}></CRFSelection>
                    {' '}
                    {!props.disabled && isTaskInProd.toLowerCase() == 'no' && <button className="btn btn-danger" type="button" onClick={e => handleDeleteReadSettings(d.StudyReadSettingsID, e)}>Delete</button>}
                </div>
            ),
        }
    ];

    const editCRFSelectionHandler = (row, e) => {
    }

    const fetchReadSettingData = () => {
        fetch('api/ReadSettings/GetReadSettings?studyProtocolId=' + studyProID)
            .then(response => response.json())
            .then(data => {
                var cnt = 0;
                for (var iCount = 0; iCount < data.length; iCount++) {
                    if (data[iCount].IsParadigmReads.toLowerCase() == 'yes') {
                        cnt++;
                    }
                    if (data[iCount].IsOutcome.toLowerCase() == 'yes') {
                        setsOutComeExists(data[iCount].IsOutcome.toLowerCase());
                    }
                }                                
                setRows(data);
                setCntRecords(data.length);
                setCntParadSubmit(cnt);               
                GetCustomeViewer(studyProID);
            });
    }

    const IsTaskInProd = () => {
        fetch('api/ReadSettings/IsTaskInProd?studyProtocolId=' + studyProID)
            .then(response => response.text())
            .then(data => {               
                let obj = JSON.parse(data);
                setIsEDCProjExist(obj.IsEDCProjExist);
                setIsTaskInProd(obj.IsTaskInProd);
            });
    }
    const GetCustomeViewer = () => {
        fetch('api/ReadSettings/GetCustomeViewer?studyProtocolId=' + studyProID)
            .then(response => response.text())
            .then(data => { 
                let objViewer = JSON.parse(data)
                setArrCustomViewer(objViewer);
            });
    }

    const [rowData, setRows] = useState([]);

    useEffect(() => {
        fetchReadSettingData();
        IsTaskInProd();  
    }, [studyProID]);

    const handleDeleteReadSettings = (studyReadSettingsID, event) => {
        var con = confirm('Are you sure, you want to delete the item?');
        if (con == true) {
            DeleteReadSettings(studyReadSettingsID);
        }
    };

    const DeleteReadSettings = (studyReadSettingsID) => {
        let objReadSettingsModel = {
            StudyReadSettingsID: studyReadSettingsID,
            Username: EDCUserID,
            Alert: 'X'
        };

        fetch('api/ReadSettings/DeleteReadSettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objReadSettingsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1 || res == 0) {
                alert('Read settings not deleted, Something went wrong.');
                return false;
            }
            if (res > 0) {
                fetchReadSettingData();
            }
        })

    };

    return (
        <Card>
            <Row style={{ marginBottom:"5px" }}>
                <Col md={8} className="form-inline mb-2">
                </Col>
                <Col md={4} className="form-inline">
                    {!props.disabled && isTaskInProd.toLowerCase() == 'no' && <CRFSelection action="add" text="Add" disabled={props.disabled} btn="true" header="New Read Details" readID={rowData.length} studyProtocolID={studyProID} studyReadSettingsID="0" fetchReadSettingData={fetchReadSettingData} isDisabled={false} ></CRFSelection>}
                    {!props.disabled && cntRecords > 0 && cntRecords == cntParadSubmit && props.userRole.toLowerCase() == 'super user' && isEDCProjExist.toLowerCase() == 'yes' && isTaskInProd.toLowerCase() == 'no' && <EDCProjectModal action="redeployment" text="Re-Deploy EDC Project"></EDCProjectModal>}
                    {!props.disabled && cntRecords > 0 && isEDCProjExist.toLowerCase() == 'no' && <EDCProjectModal action="new" text="Create EDC Project"></EDCProjectModal>}
                </Col>
            </Row>
            <div className="clientContainer_old" style={{ display: rowData.filter(item => item.ReadType.toLowerCase() === 'cims').length > 0 ? "" : "none" }}>
                <div className="sc-dkzDqf gZWIKX rdt_TableHeadRow" style={{ border: "solid", borderColor: "silver", borderWidth: "thin", padding: "5px", minHeight: "35px", height: "35px" }}>
                    CIMS
                    </div>
                <div className="tableContainer">
                    <DataTable
                        title="Read Settings"
                        columns={columns}
                        data={rowData.filter(item => item.ReadType.toLowerCase() === 'cims')}
                        customStyles={customStyles}
                        striped
                        noHeader
                    />
                </div>
            </div>           
            <div style={{ marginTop :"30px" }}></div>
            <div className="clientContainer_old" style={{ display: rowData.filter(item => item.ReadType.toLowerCase() === 'lucidity').length > 0 ? "" : "none" }}>
                <div className="sc-dkzDqf gZWIKX rdt_TableHeadRow" style={{ border: "solid", borderColor: "silver", borderWidth: "thin", padding: "5px", minHeight:"35px", height:"35px" }}>
                    Lucidity
                    </div>
                <div className="tableContainer">
                    <DataTable
                        title="Read Settings"
                        columns={columns}
                        data={rowData.filter(item => item.ReadType.toLowerCase() === 'lucidity')}
                        className="table table-striped table-bordered table-hover"
                        customStyles={customStyles}
                        striped
                        noHeader
                    />
                </div>
            </div>
            {
                /*Custom viewer*/
                arrCustomViewer.map((studyviewer, index) =>
                    <div className="clientContainer_old" style={{ marginBottom: "15px", display: rowData.filter(item => item.ReadType.toLowerCase() === studyviewer.toLowerCase()).length > 0 ? "" : "none" }}>
                        <div className="sc-dkzDqf gZWIKX rdt_TableHeadRow" style={{ border: "solid", borderColor: "silver", borderWidth: "thin", padding: "5px", minHeight: "35px", height: "35px" }}>
                            {studyviewer}
                         </div>
                        <div className="tableContainer">
                            <DataTable
                                title="Read Settings"
                                columns={columns}
                                data={rowData.filter(item => item.ReadType == studyviewer)}
                                customStyles={customStyles}
                                striped
                                noHeader
                            />
                        </div>
                    </div>                    
                )
            }            
            <div className="mt-2" style={{ "text-align": "center" }}>
                <props.ActionButton component="ReadSettings" {...props} />
            </div>
        </Card>
    );
};

export default ReadSettings;
