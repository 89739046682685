import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Card from '../UI/Card/Card';
import SelectList from '../UI/Control/SelectList';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import classes from './../TrialstatNoto.module.css';
import axios from 'axios'; 

const UploadDoumentModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [arrStudies, setStudies] = useState([]);
    const handleShow = () => {
        setShow(true);
        FillStudyFilesList();
    };


    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        let objStudy = document.getElementById('ddlUploadStudies');
        if (objStudy.value == '-1') {
            alert('Please select study.');
            objStudy.focus();
            return false;
        }

        let objDescription = document.getElementById('txtDescription');
        if (objDescription.value == '') {
            alert('Description should not be empty.');
            objDescription.focus();
            return false;
        }

        if (!file) {
            alert('Please select a file');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('StudyId', objStudy.value);
        formData.append('Description', objDescription.value);

        try {
            const response = await axios.post('api/DocumentsMgmt/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            setShow(false);
            props.fetchDocManagementList(objStudy.value);
        } catch (error) {
            alert('Error uploading file:' + error);
        }
    };

    const FillStudyFilesList = () => {
        fetch('api/Site/GetStudiesByUser')
            .then(response => response.json())
            .then(data => {
                setStudies(data);
            });
    }
    
    return (
        <>
            <Button variant="secondary" className="btn btn-md btn-warning" style={props.style} onClick={handleShow}> Upload </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Ten} className="modal-dialog-top" style={{ "height": "auto" }} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center", display: "block" }}>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <div className="row mt-2" id="divUpload">
                            <div className="col-sm-12">
                                <div style={{ "justify-content": "space-between" }}>
                                    <SelectList id='ddlUploadStudies' dataSource={arrStudies} style={{ "width": "80%" }} />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2" id="divUpload">
                            <div className="col-sm-12">
                                <div style={{ "display": "flex", "justify-content": "space-between" }}>
                                    <textarea id="txtDescription" rows='2' cols='80' maxlength="255" className="form-control" style={{ "width": "80%", resize:"none" }} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row mt-2" id="divUpload">
                            <div className="col-sm-12">
                                <div style={{ "display": "flex", "justify-content": "space-between" }}>
                                    <input type="file" accept = ".txt, .pdf, .doc, .docx" onChange={handleFileChange} className="form-control" style={{ "width": "80%" }} />
                                    <button className="btn btn-success" style={{ "paddingLeft": "15px" }} onClick={handleUpload}>Upload</button>
                                </div>
                            </div>
                        </div>
                    </Card >
                </Modal.Body>
            </Modal>
        </>
    );
};
export default UploadDoumentModal;