import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SelectList from '../UI/Control/SelectList';
import Button from '../UI/Button/Button';
import Card from '../UI/Card/Card';
import InputString from '../UI/Control/InputString';
import NotificationDetailsModel from './NotificationDetailsModel'
import classes from '../TrialstatNoto.module.css'
import customStyles from '../customStyles';
import NoData from '../NoData';
import CopyIcon from '../../Images/Icons/copy.svg';
import DeleteIcon from '../../Images/Icons/delete.svg';
import AssignedUserRoleModal from "./AssignedUserRoleModal";
import AssignStudyModal from "./AssignStudyModal";
import AssignSiteModal from "./AssignSiteModal";

const AssignUserRoleList = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const selectRef = useRef('');
    const navigate = useNavigate();
    const [q, setQ] = useState('');
    const [inputVal, setInputVal] = useState('');
    const [rowData, setRows] = useState([]);
    const [arrAssignedRole, setAssignedRoleList] = useState([]);
    const [userPermission, setUserPermisssion] = useState('RO'); //RO, RW, NA
    const [arrImgServRole, setImgServRole] = useState([]);
    const [arrUserRoles, setUserRoles] = useState([]);
    const [userRoleId, setUserRoleId] = useState(-1);
    const getUserRole = () => {
        fetch('api/Users/GetRoleByID?userID=' + EDCUserID)
            .then(response => response.text())
            .then(role => {
                setCompPermission(role);
            });
    };

    useEffect(() => {
        GetUserPermissionRoles();
        FillAssignedRole();
        fetchImageServiceRoles();
        fetchUsersData();
        getUserRole();
    }, []);

    const setCompPermission = (usrRole) => {
        if (usrRole == "Read-Only")
            setUserPermisssion("RO")
        else if (usrRole == "Database Designer" || usrRole == "Super User")
            setUserPermisssion("RW")
        else if (usrRole == "Tester")
            setUserPermisssion("RW")
        else if (usrRole == "Database Administrator")
            setUserPermisssion("RW")
        else if (usrRole == "Management, Image Services" || usrRole == "Image Services Coordinator")
            setUserPermisssion("RO")
        else if (usrRole == "Clinical Data Manager" || usrRole == "Manager, Clinical Data Management")
            setUserPermisssion("RW")
        else
            setUserPermisssion("NA")
    }

    const columns = [
        {
            name: "UserID",
            selector: "UserID",
            omit: true
        },
        {
            name: "EDCUserID",
            selector: "EDCUserID",
            omit: true
        },
        {
            name: "Name",
            selector: "FullName",
            sortable: true,
            center: true
        },
        {
            name: "Email",
            selector: "Email",
            sortable: true,
            center: true,
        },
        {
            name: "Assigned Role",
            sortable: true,
            center: true,
            cell: (row) => {
                return <div style={{ width: "100%" }}>
                    <input type="hidden" id={"hdnEDCUserId_" + row.UserID} value={row.EDCUserID} />
                    <input type="hidden" id={"hdnRoleName_" + row.UserID} value={row.RoleLabel} />
                    {userPermission == "RW" && <SelectList id={"ddlAssignRole_" + row.UserID} value={row.RoleID} dataSource={arrAssignedRole} onChangeHandler={roleChangeHandler} style={{ margin: "5px", width: "90%" }} />}
                    {userPermission == "RO" && <SelectList id={"ddlAssignRole_" + row.UserID} disabled="disabled" value={row.RoleID} dataSource={arrAssignedRole} style={{ margin: "5px", width: "90%" }} />}
                    {userPermission == "RW" && (row.RoleID == 5 || row.RoleID == 6) && <SelectList id={"ddlImgSerRole_" + row.UserID} value={row.ReadTypes.ReadTypesID} dataSource={arrImgServRole} onChangeHandler={crRoleChangeHandler} style={{ margin: "5px", width: "90%" }} />}
                    
                    </div>   
                }
            },
        {
            name: "Action",
            button: true,
            className: 'headercaption',
            width: userPermission == "RW"  ? '550px' : '0px' ,
            style: {
                'border-right-style': 'solid',
                'border-width': '1px',
                'border-color': 'rgba(0, 0, 0, 0.12)'
            },
            cell: (row) => (
                <div style={{ "padding": "5px" }}>
                    {userPermission == "RW" && row.RoleID == 15 && <AssignSiteModal btn="icon" header="Assign Site" username={row.FullName} edcUserID={row.EDCUserID}></AssignSiteModal>}
                    {userPermission == "RW" && <img style={{ "border": "1px solid #dcdcdc", "cursor": "pointer", marginLeft: "5px", "float": "right" }}
                        height="34px" width="34px" src={DeleteIcon} alt="Delete" onClick={e => DeleteUser(row, e)} />}
                    {userPermission == "RW" &&  <AssignStudyModal btn="icon" header="Assign Study" username={row.FullName} edcUserID={row.EDCUserID}></AssignStudyModal>}
                </div>
            )
        }
    ];

    const filteredColumns = ['FullName', 'Email'];
    function search(rows) {
        let selString = q.toString();
        let searchText = selString.toLowerCase().split(",");
        return rows.filter((row) => {
            return filteredColumns.some((column) => {
                if (searchText.length > 1)
                    return searchText.indexOf(row[column].toString().toLowerCase()) > -1
                else
                    return row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
            })
        })
    }

    const searchChangeHandler = (e) => {
        setQ(e.target.value);
        setInputVal(e.target.value);
    }

    const userRoleChangeHandler = (e) => {
        setUserRoleId(e.target.value);
        fetchUsersData(e.target.value);
    }

    const FillAssignedRole = () => {
        fetch('api/Users/FillAssignedRole')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setAssignedRoleList(arr);
            });
    }

    const GetUserPermissionRoles = () => {
        fetch('api/Users/GetUserPermissionRoles')
            .then(response => response.json())
            .then(data => {
                setUserRoles(data);
            });
    }

    const fetchImageServiceRoles = () => {
        fetch('api/Users/GetImageServiceRoles')
            .then(response => response.json())
            .then(data => {
                let arr = [{ Value: '0', Name: '' }]
                for (var iCount = 0; iCount < data.length; iCount++) {
                    arr.push({ Value: data[iCount].Value, Name: data[iCount].Name });
                }
                setImgServRole(arr);
            });
    }

    const fetchUsersData = (roleId) => {
        if (typeof roleId == 'undefined') {
            roleId = -1;
        }

        fetch('api/Users/GetUsers?roleId=' + roleId, {
            headers: new Headers({
                'Authorization': 'Basic ' + btoa(EDCUserID),
                'Content-Type': 'application/x-www-form-urlencoded'
                //'Content-Type': 'application/json'
            })
        })
            .then(response => response.json())
            .then(data => {
                setRows(data);
            });
    }

    const roleChangeHandler = (e) => {
        var corImgSerId = 5;
        var mgtImgSerId = 6;
        let arr = e.target.id.split('_');
        let userId = arr[1];
        let UserIDTemp = document.getElementById('hdnEDCUserId_' + userId).value;
        let RoleName = document.getElementById('hdnRoleName_' + userId).value;

        let roleId = e.target.value;
        var objSelect = document.getElementById(e.target.id);
        var roleLabel = objSelect.options[objSelect.selectedIndex].text;

        if (roleId == corImgSerId || roleId == mgtImgSerId) {
            if (document.getElementById('ddlImgSerRole_' + userId) != null) {
                document.getElementById('ddlImgSerRole_' + userId).style.display = 'block';
            }
        }
        else {
            if (document.getElementById('ddlImgSerRole_' + userId) != null) {
                document.getElementById('ddlImgSerRole_' + userId).style.display = 'none';
            }
        }


        let objUsersModel = {
            Username: EDCUserID,
            EDCUserID: UserIDTemp,
            RoleID: roleId,
            RoleLabel: roleLabel,
            ReadTypes: { ReadTypesID:0}
        };
        fetch('api/Users/UpdateUserRole', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUsersModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('User role not updated, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchUsersData();
                if (roleId != corImgSerId && roleId != mgtImgSerId) {
                    alert('Updated user role from ' + RoleName + ' to ' + roleLabel);
                }
            }
        })
    }

    const crRoleChangeHandler = (e) => {
        if (e.target.value == 0) {
            alert('Reader type should not be empty.');
            e.target.focus();
            return false;
        }
        let arr = e.target.id.split('_');
        let userId = arr[1];
        let RoleName = document.getElementById('hdnRoleName_' + userId).value;
        let UserIDTemp = document.getElementById('hdnEDCUserId_' + userId).value;

        let readTypesID = e.target.value;
        var objSelect = document.getElementById(e.target.id);
        var roleLabel = objSelect.options[objSelect.selectedIndex].text;
        let roleId = document.getElementById('ddlAssignRole_' + userId).value

        let objUsersModel = {
            Username: EDCUserID,
            EDCUserID: UserIDTemp,
            RoleID: roleId,
            RoleLabel: roleLabel,
            ReadTypes: { ReadTypesID: readTypesID }
        };
        fetch('api/Users/UpdateUserRole', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objUsersModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('User role not updated, Something went wrong.');
                return false;
            }

            if (res > 0) {
                fetchUsersData();
                alert('Updated user role ‘' + RoleName+'’ with reader type: ' + roleLabel+'.');
            }
        })
    }

    const DeleteUser = (row, event) => {
        var con = confirm('Are you sure, you want to delete "' + row.FullName + '" user?');
        if (con == true) {
            let objUsersModel =
            {
                EDCUserID: row.EDCUserID,
                Alert: 'X',
                Username: EDCUserID,
            }

            fetch('api/Users/DeleteUsers', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(objUsersModel)
            }).then(r => r.text()).then(res => {
                if (res == -1 || res == 0) {
                    alert('User not deleted, Something went wrong.');
                    return false;
                }
                if (res == 1) {
                    fetchUsersData(userRoleId);
                }
            })
            return true;
        }
    };

    return <Card>
        <Row style={{ marginBottom: "10px" }}>
            <Col md={6}>
                <div className="input-group">
                    <InputString id="txtSearch" value={inputVal} onChangeHandler={searchChangeHandler} placeholder="Search" />
                </div>
            </Col>
            <Col md={3}>
                <SelectList id='ddlUserRole' dataSource={arrUserRoles} onChangeHandler={userRoleChangeHandler} />
            </Col>
            <Col md={3}>
                {userPermission == "RW" && <AssignedUserRoleModal text="Add" btn="true" header="Assign User Role" style={{ float: "right" }} fetchUsersData={fetchUsersData} userRoleId={userRoleId}></AssignedUserRoleModal>}
            </Col>
        </Row>
        <div className="tableContainer">
            {search(rowData).length > 0 ? <DataTable
                columns={columns}
                data={search(rowData)}
                className="table table-striped table-bordered table-hover"
                customStyles={customStyles}
                striped
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[20, 50, 100]}
            /> : <NoData headers={columns}></NoData>}
        </div>
    </Card>
}

export default AssignUserRoleList;