import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import SelectList from '../UI/Control/SelectList';
import InputNumber from '../UI/Control/InputNumber';
import InputString from '../UI/Control/InputString';
import InputTextarea from '../UI/Control/InputTextarea';
import classes from './../TrialstatNoto.module.css';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import { useSelector } from 'react-redux';
import InputCheckbox from '../UI/Control/InputCheckbox';

const AddStudySiteModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [show, setShow] = useState(false);
    const [siteCodeDisable, setSiteCodeDisable] = useState('');
    const handleClose = () => setShow(false);
    const action = props.action.toLowerCase();
    const isDisabled = (action == 'view' ? true : false);
    const handleShow = () => {
        if (props.studySiteID > 0) {
            setSiteCodeDisable('disable');
            fetchStudySiteDetails();
        }
        setShow(true);
    };

    function ValidateStudySettings() {

        const objSiteCode = document.getElementById('txtSiteCode');
        if (objSiteCode.value.trim() == '') {
            alert('Site code should not be empty.');
            objSiteCode.focus();
            return false;
        }

        const objSiteName = document.getElementById('txtSiteName');
        if (objSiteName.value.trim() == '') {
            alert('Site name should not be empty.');
            objSiteName.focus();
            return false;
        }

        return true;
    }

    const SubmitStudySiteData = (event) => {
        if (props.studySiteID == 0) {
            SaveStudySite();
        }
        else {
            UpdateStudySites();
        }
    };

    const SaveStudySite = (event) => {
        if (ValidateStudySettings() == false)
            return false;

        let objStudySitesModel = {
            StudyID: props.studyId,
            SiteCode: document.getElementById('txtSiteCode').value,
            SiteName: document.getElementById('txtSiteName').value,
            InvestigatorName: '',
            PhysAddress: '',
            CIMSSiteName: document.getElementById('txtCIMSSiteName').value,
            LuciditySiteName: document.getElementById('txtLuciditySiteName').value,
            StudyProtocolID: props.studyProtocolId,
            EnableEmail: document.getElementById('chkEnableEmail').checked == true ? 'Y' : 'N',
            Username: EDCUserID
        };

        fetch('api/StudySites/SaveStudySites', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objStudySitesModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Site not saved, Something went wrong.');
            }
            else if (res == 0) {
                alert('Site code already exist.');
            }
            else if (res == 2) {
                alert('Site name already exist.');
            }
            else if (res = 1) {
                props.fetchData();
                setShow(false);
            }
        })
    };

    const UpdateStudySites = (event) => {
        if (ValidateStudySettings() == false)
            return false;

        let objStudySitesModel = {
            StudySiteID: props.studySiteID,
            StudyID: props.studyId,
            SiteCode: document.getElementById('txtSiteCode').value,
            SiteName: document.getElementById('txtSiteName').value,
            InvestigatorName: '',
            PhysAddress: '',
            CIMSSiteName: document.getElementById('txtCIMSSiteName').value,
            LuciditySiteName: document.getElementById('txtLuciditySiteName').value,
            StudyProtocolID: props.studyProtocolId,
            EnableEmail: document.getElementById('chkEnableEmail').checked == true ? 'Y' : 'N',
            Username: EDCUserID
        };

        fetch('api/StudySites/UpdateStudySites', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objStudySitesModel)
        }).then(r => r.text()).then(res => {
            if (res == -1) {
                alert('Site not saved, Something went wrong.');
            }
            else if (res == 0) {
                alert('Site name already exist.');
            }
            else if (res > 0) {
                props.fetchData();
                setShow(false);
            }
        })
    };

    const fetchStudySiteDetails = () => {
        fetch('api/StudySites/GetStudySiteDetails?studySiteId=' + props.studySiteID)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
    }

    function setData(data) {
        document.getElementById('txtSiteCode').value = data.SiteCode;
        document.getElementById('txtSiteName').value = data.SiteName;
        document.getElementById('txtCIMSSiteName').value = data.CIMSSiteName;
        document.getElementById('txtLuciditySiteName').value = data.LuciditySiteName;
        if (data.EnableEmail == 'Y')
            document.getElementById('chkEnableEmail').checked = true;
        else
            document.getElementById('chkEnableEmail').checked = false;
    }

    return (
        <>
            <Button variant="primary" className="btn btn-sm btn-success" style={props.style} onClick={handleShow}> {props.text}  </Button>

            <Modal centered
                show={show}
                onHide={handleClose}
                dialogClassName={classes.TSModalDailog_Eight} backdrop="static">

                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center", display : "block" }}>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-1" style={{ width: "4%" }}>
                        </div>
                        <div className="col-sm-10" style={{ width: "95%" }}>
                            <div className="row">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label className="mb-1"><b>Site Code</b></label>
                                        <MandatoryLabel></MandatoryLabel>:
                                        <InputString id="txtSiteCode" maxlength="20" disabled={siteCodeDisable}></InputString>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <label className="mb-1"><b>Site Name</b></label>
                                        <MandatoryLabel></MandatoryLabel>:
                                        <InputString id="txtSiteName" maxlength="250" disabled={isDisabled}></InputString>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <label className="mb-1"><b>CIMS Site Name</b>:</label>
                                        <InputString id="txtCIMSSiteName" maxlength="250" disabled={isDisabled}></InputString>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <label className="mb-1"><b>Lucidity Site Name</b>:</label>
                                        <InputString id="txtLuciditySiteName" maxlength="250" disabled={isDisabled}></InputString>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-12">
                                        <InputCheckbox id="chkEnableEmail" text="&ensp;Enable Email" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1" style={{ width: "4%" }}>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSave" className="btn btn-md btn-success" variant="primary" onClick={SubmitStudySiteData} style={{ display: action == "view" ? "none" : "" }}>
                        Save
                        </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AddStudySiteModal;