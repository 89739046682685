import React, { useState, useEffect, useRef } from "react";
import InputContainer from '../UI/Container/InputContainer';
import BorderedContainer from '../UI/Container/BorderedContainer';
import InputNumber from '../UI/Control/InputNumber';
import Card from '../UI/Card/Card';
import PaymentSettings from './PaymentSettings';
import { Button } from 'react-bootstrap';

const GlobalSettings = (props) => {
    let IniReadPayment = [];
    let AdjReadPayment = [];
    let ReReadPayment = [];

    const readIRPData = useRef(IniReadPayment);
    const [IniReadPayment1, setIRP] = useState(readIRPData.current);

    const readARPData = useRef(AdjReadPayment);
    const [AdjReadPayment1, setARP] = useState(readARPData.current);

    const readRRPData = useRef(ReReadPayment);
    const [ReReadPayment1, setRRP] = useState(readRRPData.current);

    const fetchSystemSettingDetail = () => {
        fetch('api/GlobalSettings/GetSystemSettingsDetails')
            .then(response => response.json())
            .then(data => {
                setSystemSettingsData(data);
            });
    }

    function setSystemSettingsData(data) {
        document.getElementById('hdnSettingsID').value = data.SettingsID;
        document.getElementById('txtCIMSMinute').value = data.CIMSUpdateTimer;
        document.getElementById('txtCIMSHour').value = data.CIMSReconTimer;
        document.getElementById('txtLucidityMinute').value = data.LucUpdateTimer;
        document.getElementById('txtLucidityHour').value = data.LucReconTimer;
    }

    const fetchPaymentSettingDetail = (paymentType) => {
        fetch('api/GlobalSettings/GetPaymentSettingsDetails?paymentType=' + paymentType)
            .then(response => response.json())
            .then(data => {
                setPaymentSettingsData(data, paymentType);
            });
    }

    function setPaymentSettingsData(data, paymentType) {
        if (paymentType == 'endo') {
            document.getElementById('hdnEndoPaySettingsID').value = data.PaySettingsID;
            document.getElementById('txtReadPayment').value = data.ReadPayment;
            document.getElementById('txtAdjuReadPayment').value = data.AdjReadPayment;
            document.getElementById('txtReReadPayment').value = data.ReReadPayment;
            document.getElementById('txtOnboardingPayment').value = data.OnboardingPayment;
            document.getElementById('txtTrainingReadPayment').value = data.TrainingPayment;
        }
        else {
            document.getElementById('hdnHistoPaySettingsID').value = data.PaySettingsID;
            document.getElementById('txtHistOnboardingPayment').value = data.OnboardingPayment;
            document.getElementById('txtHistTrainingReadPayment').value = data.TrainingPayment;
        }
    }

    const fetchGetHistoReadPaySettingsDetails = (readPayType) => {
        fetch('api/GlobalSettings/GetHistoReadPaySettingsDetails?readPayType=' + readPayType)
            .then(response => response.json())
            .then(data => {
                setHistoReadPaySettingsData(data, readPayType);
            });
    }

    function setHistoReadPaySettingsData(data, readPayType) {
        let rate = [];
        for (var cnt = 0; cnt < data.length; cnt++) {
            rate.push({ ReadPayID: data[cnt].ReadPayID, ReadRange: data[cnt].Min + "-" + data[cnt].Max, TotalPayment: data[cnt].TotalPayment, ReadPayType: data[cnt].ReadPayType })
        }

        if (readPayType == '') {
            setIRP(rate.filter(item => item.ReadPayType == 'initial'));      
            setARP(rate.filter(item => item.ReadPayType == 'adjudication'));      
            setRRP(rate.filter(item => item.ReadPayType == 're-read'));         
        }
        else {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
            if (readPayType == 'initial') {
                setIRP(rate);
            }

            if (readPayType == 'adjudication') {
                setARP(rate);                                                                                                                                                   
            }
            if (readPayType == 're-read') {
                setRRP(rate);
            }
        }
    }

    const handleSystemSettings = (event) => {
        let objSystemSettingsModel = {
            SettingsID: document.getElementById('hdnSettingsID').value,
            CIMSUpdateTimer: document.getElementById('txtCIMSMinute').value.trim(),
            CIMSReconTimer: document.getElementById('txtCIMSHour').value.trim(),
            LucUpdateTimer: document.getElementById('txtLucidityMinute').value.trim(),
            LucReconTimer: document.getElementById('txtLucidityHour').value.trim(),
        };

        fetch('api/GlobalSettings/SaveSystemSettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objSystemSettingsModel)
        }).then(r => r.text()).then(res => {
            if (res == "1") {
                alert('System settings saved successfully.');
                fetchSystemSettingDetail();
            }
            else {
                alert('System settings not saved, Something went wrong.');
            }
        })
    };

    const handlePaymentSettings = (paymentType) => {
        let objPaymentSettingsModel;
        if (paymentType == 'endo') {
            objPaymentSettingsModel = {
                PaySettingsID: document.getElementById('hdnEndoPaySettingsID').value,
                ReadPayment: document.getElementById('txtReadPayment').value.trim(),
                AdjReadPayment: document.getElementById('txtAdjuReadPayment').value.trim(),
                ReReadPayment: document.getElementById('txtReReadPayment').value.trim(),
                OnboardingPayment: document.getElementById('txtOnboardingPayment').value.trim(),
                TrainingPayment: document.getElementById('txtTrainingReadPayment').value.trim(),
                PaymentType: paymentType
            };
        }
        else {
            objPaymentSettingsModel = {
                PaySettingsID: document.getElementById('hdnHistoPaySettingsID').value,
                ReadPayment: 0,
                AdjReadPayment: 0,
                ReReadPayment: 0,
                OnboardingPayment: document.getElementById('txtHistOnboardingPayment').value.trim(),
                TrainingPayment: document.getElementById('txtHistTrainingReadPayment').value.trim(),
                PaymentType: paymentType
            };
        }

        fetch('api/GlobalSettings/SavePaymentSettings', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objPaymentSettingsModel)
        }).then(r => r.text()).then(res => {
            if (res == "1") {
                if (paymentType == 'endo') {
                    alert('Endo payment settings saved successfully.');
                    fetchPaymentSettingDetail('endo');
                }
                else {
                    alert('Histo payment settings saved successfully.');
                    fetchPaymentSettingDetail('histo');
                }
            }
            else {
                alert('Payment settings not saved, Something went wrong.');
            }
        })
    };

    useEffect(() => {
        fetchSystemSettingDetail();
        fetchPaymentSettingDetail('endo');
        fetchPaymentSettingDetail('histo');
        fetchGetHistoReadPaySettingsDetails('');
        setIRP(readIRPData.current);
    }, []);

    return (
        <Card>
            <div className="row">
                <div className="col-sm-4">
                    <InputContainer border='true' header="System Settings" Expended="true" style={{ textAlign: "center" }}>
                        <div className="row">
                            <div className="col-sm-1">
                            </div>
                            <div className="col-sm-10">
                                <div className="row mb-1 mt-2">
                                    <div className="col-sm-7">
                                        <input type="hidden" id="hdnSettingsID" value="0" />
                                        <label className="mb-1 mt-2" for="txtCIMSMinute">CIMS Update Timer (minutes):</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <InputNumber id="txtCIMSMinute" maxlength="2" style={{ width: "70px" }}></InputNumber>
                                    </div>
                                </div>
                                <div className="row mb-1 mt-2">
                                    <div className="col-sm-7">
                                        <label className="mb-1 mt-2" for="txtCIMSHour">CIMS Reconcillation Timer (hours):</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <InputNumber id="txtCIMSHour" maxlength="2" style={{ width: "70px" }}></InputNumber>
                                    </div>
                                </div>
                                <div className="row mb-1 mt-2">
                                    <div className="col-sm-7">
                                        <label className="mb-1 mt-2" for="txtLucidityMinute">Lucidity Update Timer (minutes):</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <InputNumber id="txtLucidityMinute" maxlength="2" style={{ width: "70px" }}></InputNumber>
                                    </div>
                                </div>
                                <div className="row mb-1 mt-2">
                                    <div className="col-sm-7">
                                        <label className="mb-1 mt-2" for="txtLucidityHour">Lucidity Reconcillation Timer (hours):</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <InputNumber id="txtLucidityHour" maxlength="2" style={{ width: "70px" }}></InputNumber>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-1">
                            </div>
                            <div>
                                <Button className="btn btn-success" onClick={handleSystemSettings} style={{ marginRight: "5px", marginBottom: "5px", float: "right" }}>
                                    Save
                                </Button>
                            </div>
                        </div>
                       
                    </InputContainer>
                </div>   
                <div className="col-sm-8">
                    <InputContainer border='true' header="Endoscopy Payment Settings" Expended="true" style={{ textAlign: "center" }} >
                        <div className="row" style={{ height:"230px" }}>
                            <div className="col-sm-6">
                                <div className="row mb-4">
                                    <div className="col-sm-1">
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="row mb-1 mt-2">
                                            <div className="col-sm-7">
                                                <input type="hidden" id="hdnEndoPaySettingsID" value="0" />
                                                <label className="mb-1 mt-2" for="txtReadPayment">Read Payment:</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <InputNumber id="txtReadPayment" maxlength="9" style={{ width: "110px" }}></InputNumber>
                                            </div>
                                        </div>
                                        <div className="row mb-1 mt-2">
                                            <div className="col-sm-7">
                                                <label className="mb-1 mt-2" for="txtAdjuReadPayment">Adjudication Read Payment:</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <InputNumber id="txtAdjuReadPayment" maxlength="9" style={{ width: "110px" }}></InputNumber>
                                            </div>
                                        </div>
                                        <div className="row mb-1 mt-2">
                                            <div className="col-sm-7">
                                                <label className="mb-1 mt-2" for="txtReReadPayment">Re-Read Payment:</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <InputNumber id="txtReReadPayment" maxlength="9" style={{ width: "110px" }}></InputNumber>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="row mb-4">
                                    <div className="col-sm-1">
                                    </div>
                                    <div className="col-sm-10">
                                        <div className="row mb-1 mt-2">
                                            <div className="col-sm-7">
                                                <label className="mb-1 mt-2" for="txtOnboardingPayment">Onboarding Payment:</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <InputNumber id="txtOnboardingPayment" maxlength="9" style={{ width: "110px" }}></InputNumber>
                                            </div>
                                        </div>
                                        <div className="row mb-1 mt-2">
                                            <div className="col-sm-7">
                                                <label className="mb-1 mt-2" for="txtTrainingReadPayment">Training Per Read Payment:</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <InputNumber id="txtTrainingReadPayment" maxlength="9" style={{ width: "110px" }}></InputNumber>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Button className="btn btn-success" onClick={e => handlePaymentSettings("endo")} style={{ marginRight: "5px", marginBottom: "5px", float: "right" }}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </InputContainer>
                </div>
            </div>
            <div className="row" style={{marginTop:"20px"}}>
                <div className="col-sm-12">
                    <InputContainer border='true' header="Histopathology Payment Settings" Expended="true" style={{ textAlign: "center" }}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <BorderedContainer style={{ width: "98%", height: "350px" }}>
                                    <PaymentSettings paymentType="initial" ReadPayment={IniReadPayment1} fetchGetHistoReadPaySettingsDetails={fetchGetHistoReadPaySettingsDetails}></PaymentSettings>
                                </BorderedContainer>
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <BorderedContainer style={{ width: "98%", height: "350px" }}>
                                    <PaymentSettings paymentType="adjudication" ReadPayment={AdjReadPayment1} fetchGetHistoReadPaySettingsDetails={fetchGetHistoReadPaySettingsDetails}></PaymentSettings>
                                </BorderedContainer>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <BorderedContainer style={{ width: "98%", height: "350px" }}>
                                    <PaymentSettings paymentType="re-read" ReadPayment={ReReadPayment1} fetchGetHistoReadPaySettingsDetails={fetchGetHistoReadPaySettingsDetails}></PaymentSettings>
                                </BorderedContainer>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <BorderedContainer style={{ width: "95%", height: "350px"  }}>
                                    <div className="row">
                                        <div className="col-sm-1">
                                        </div>
                                        <div className="col-sm-10">
                                            <div className="row mb-1 mt-2">
                                                <div className="col-sm-8">
                                                    <input type="hidden" id="hdnHistoPaySettingsID" value="0" />
                                                    <label className="mb-1 mt-2" for="txtHistOnboardingPayment">Onboarding Payment:</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputNumber id="txtHistOnboardingPayment" maxlength="9"></InputNumber>
                                                </div>
                                            </div>
                                            <div className="row mb-1 mt-2">
                                                <div className="col-sm-8">
                                                    <label className="mb-1 mt-2" for="txtHistTrainingReadPayment">Training Per Read Payment:</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputNumber id="txtHistTrainingReadPayment" maxlength="9"></InputNumber>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-1">
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop:"10px" }}>
                                        <div className="col-sm-8">
                                        </div>
                                        <div className="col-sm-3">
                                            <Button className="btn btn-success" onClick={e => handlePaymentSettings("histo")} style={{ marginRight: "5px", marginBottom: "5px", float: "right" }}>
                                                Save
                                            </Button>
                                        </div>
                                        <div className="col-sm-1">
                                        </div>
                                    </div>
                                </BorderedContainer>
                            </div>
                        </div>
                    </InputContainer>
                </div>
                <div>
                </div>
            </div>
        </Card>
    );
};

export default GlobalSettings;
