import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Button from '..//UI/Button/Button';
import InputString from '../UI/Control/InputString';
import InputTextarea from '../UI/Control/InputTextarea';
import SelectList from '../UI/Control/SelectList';
import Card from '../UI/Card/Card';
import classes from '../TrialstatNoto.module.css';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import HTMLEditor from '../UI/HTMLEditor/HTMLEditor';

const EmailContentDesigner = (props) => {
    const [currentEditorContent, setCurrentEditorContent] = useState("<p></p>\n");  
    const [modifiedEditorContent, setModifiedEditorContent] = useState('');
    const [contentId, setContentId] = useState(0);     
    const arrEmailType = [
        {
            Name: "--Select Type--",
            Value: ""
        },
        {
            Name: "Read Assigned",
            Value: "ReadAssigned"
        },
        {
            Name: "Mark Urgent",
            Value: "MarkUrgent"
        }
    ];

    const fetchEmailContentDetails = () => {      
        if (document.getElementById('selectEmailType').value != '') {
            fetch('api/Users/GetEmailContentDetails?emailType=' + document.getElementById('selectEmailType').value)
                .then(response => response.json())
                .then(data => {
                    setData(data);                
                });
        }
        else {            
            setContentId(0);
            document.getElementById('txtSubject').value = '';
            document.getElementById('txtDescription').value = '';
            setCurrentEditorContent("<p></p>\n");            
        }
    };

    function setData(data) {
        if (data.ContentID != 0) {
            setContentId(data.ContentID);
            document.getElementById('txtSubject').value = data.Subject;
            document.getElementById('txtDescription').value = data.Description;
            document.getElementById('selectEmailType').value = data.EmailType;
            setCurrentEditorContent(data.EmailContent);           
        }
        else {
            setContentId(0);
            document.getElementById('txtSubject').value = '';
            document.getElementById('txtDescription').value = '';
            setCurrentEditorContent("<p></p>\n");           
        }
    }

    const SelectEmailTypeChangeHandler = (e) => {       
        fetchEmailContentDetails();
    };

    function ValidateEmailContent() {
        const objEmailType = document.getElementById('selectEmailType');
        if (objEmailType.value == '') {
            alert('Please select email type.');
            objEmailType.focus();
            return false;
        }

        const objSubject = document.getElementById('txtSubject');
        if (objSubject.value.trim() == '') {
            alert('Subject should not be empty.');
            objSubject.focus();
            return false;
        }

        if (modifiedEditorContent == '<p></p>\n')
        {
            alert('Email content should not be empty.');
            return false;
        }
        return true;
    }

    const SubmitEmailContent = (event) => {
        SaveEmailContent();
    }  

    const SaveEmailContent = () => {
        const retVal = ValidateEmailContent();
        if (retVal == false)
            return false;
        let emailContentDesignerModel =
            {
            ContentID: contentId,
            Subject: document.getElementById('txtSubject').value,
            Description: document.getElementById('txtDescription').value,
            EmailType: document.getElementById('selectEmailType').value,
            VariableID: 0,
            EmailContent: modifiedEditorContent == '<p></p>\n' ? "" : modifiedEditorContent
        }

        fetch('api/Users/SaveEmailContentDesigner', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(emailContentDesignerModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Email content not saved, Something went wrong.');
                return false;
            }
            if (res == 0) {
                alert('Subject already exist.');
                return false;
            }
            if (res == 1) {
                alert('Email content saved successfully.');
            }
        })
        return true;
    };
    useEffect(() => {
        fetchEmailContentDetails();
    }, [contentId])

    return (
        <Card>
            <Row style={{ "margin-top": "15px" }}>
                <Col md={12} className={classes.verticalLine}>
                    <Row style={{ "margin-bottom": "15px" }}>
                        <Col md={3}>
                            <Row style={{ "margin-bottom": "15px" }}>
                                <Col md={12}><SelectList id="selectEmailType" dataSource={arrEmailType} onChangeHandler={SelectEmailTypeChangeHandler} style={{ width: "95%" }} /></Col>
                                <Col md={12}><InputString id="txtSubject" maxlength="150" placeholder="Subject" style={{ display: "inline", width: "95%", "margin-top": "28px" }} /><MandatoryLabel></MandatoryLabel></Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <InputTextarea id="txtDescription" rows="3" maxlength="250" placeholder="Description" style={{ width: "95%" }} />
                        </Col>
                        <Col md={5}>
                            <div className="p-3 bg-secondary text-white rounded">
                                
                                <Row>
                                    <Col md={12}><h5>Variable Placeholders</h5> </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>Reader Name : </Col>
                                    <Col md={3}>{'<<ReaderName>>'}</Col>
                                    <Col md={3}>Note : </Col>
                                    <Col md={3}>{'<<Note>>'}</Col>
                                </Row>
                                <Row>
                                    <Col md={3}>Study Name : </Col>
                                    <Col md={3}>{'<<StudyName>>'}</Col>
                                    <Col md={3}>Procedure ID : </Col>
                                    <Col md={3}>{'<<ProcedureID>>'}</Col>
                                </Row>
                                <Row>
                                    <Col md={3}>Task ID : </Col>
                                    <Col md={3}>{'<<TaskID>>'}</Col>
                                    <Col md={3}>Email Sender : </Col>
                                    <Col md={3}>{'<<EmailSender>>'}</Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ "margin-bottom": "5px" }}>
                        <Col md={1} style={{ width: "100%" }}>Email Content Designer <MandatoryLabel></MandatoryLabel>:</Col>
                    </Row>
                    <Row>
                        <Col md={12}><HTMLEditor id="txtEmailContent" currentEditorContent={currentEditorContent}
                            modifiedEditorContent={setModifiedEditorContent}/></Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{ "textAlign": "center", "margin-top": "10px" }}>
                                {props.action != "view" && <Button id="btnSave" className="btn btn-success" style={{ "margin-right": "10px" }} onClick={SubmitEmailContent}> Save </Button>}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};
export default EmailContentDesigner;
