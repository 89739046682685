import React, { useState, useEffect } from 'react';
import InputString from '../UI/Control/InputString';
import SelectList from '../UI/Control/SelectList';
import MultiSelectList from '../UI/Control/MultiSelectList';
import ArrowLeft from '../../Images/Icons/ArrowLeftBorder.svg';
import ArrowRight from '../../Images/Icons/ArrowRightBorder.svg';

const AdjudicationRead = (props) => {
    // Add to selected list.
    function hanldleLeftShift(ctrl_avl, ctrl_sel) {
        let avlList = document.getElementById(ctrl_avl);
        let selList = document.getElementById(ctrl_sel);
        let options = avlList.selectedOptions;
        if (options.length > 0) {
            Array.from(options).map((option) => {
                selList.appendChild(option);
            });
            Array.from(options).map((option) => {
                avlList.removeChild(option);
            });
        }
        else
            alert('Select form to assign.')
    }

    //Add item(s) to available list.
    function handleRightShift(ctrl_avl, ctrl_sel) {
        let avlList = document.getElementById(ctrl_avl);
        let selList = document.getElementById(ctrl_sel);
        let options = selList.selectedOptions;
        if (options.length > 0) {
            Array.from(options).map((option) => {
                avlList.appendChild(option);
            });
            Array.from(options).map((option) => {
                selList.removeChild(option);
            });
        }
        else
            alert('Select form to revoke.')
    }

    useEffect(() => {
        for (var index = 0; index < props.noOfAdj.length; index++) {
            if (props.noOfAdj[index].selectedReads.length > 0) {

                let avlList = document.getElementById("multiSelectAdj_" + props.sequence + (index + 1));
                let selList = document.getElementById("multiSelectAdjSel_" + props.sequence + (index + 1));

                for (var iCount = 0; iCount < props.noOfAdj[index].selectedReads.length; iCount++) {
                    for (var i = 0; i < avlList.options.length; i++) {
                        if (props.noOfAdj[index].selectedReads[iCount].FormID == avlList.options[i].value) {
                            avlList.options[i].selected = (props.noOfAdj[index].selectedReads[iCount].FormID = avlList.options[i].value);
                        }
                    }
                }

                let options = avlList.selectedOptions;
                if (options.length > 0) {
                    Array.from(options).map((option) => {
                        selList.appendChild(option);
                    });
                    Array.from(options).map((option) => {
                        avlList.removeChild(option);
                    });
                }
            }
        }

    }, []);

    return (
    <div>
            {
                props.noOfAdj.map((item, index) =>
                    <div className="row">
                        <input type="hidden" id={"hdnADJCRFID_" + index} value={item.StudyParadigmCRFID} />
                        <div className="col-sm-12">
                            <div className="row" style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <div className="col-sm-5" style={{ width: "46%", paddingLeft: "25px", paddingRight: "25px" }}>
                                    <InputString id={"txtAdjSearch_" + props.sequence + (index + 1)} onChangeHandler={props.searchChangeHandlerAdjRead} placeholder="Search" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }}></InputString>
                                </div>
                                <div className="col-sm-2" style={{ width: "8%" }}>
                                </div>
                                <div className="col-sm-5" style={{ width: "46%", paddingLeft: "25px", paddingRight: "25px" }}>
                                    <InputString id={"txtAdjSearchSelected_" + props.sequence + (index + 1)} onChangeHandler={props.searchChangeHandlerAdjReadSelected} placeholder="Search" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }}></InputString>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <div className="col-sm-5" style={{ width: "46%", paddingLeft: "25px", paddingRight: "25px" }}>
                                    <MultiSelectList id={"multiSelectAdj_" + props.sequence + (index + 1)} dataSource={props.multiSelectDataSource} selectedData={item.selectedReads} isMultiSelect="true" size="8" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></MultiSelectList>
                                </div>
                                <div className="col-sm-2 d-flex align-items-center justify-content-center" style={{ width: "8%" }}>
                                    <table style={{ border: "none", marginTop: "5px" }}>
                                        <tr>
                                            <td>
                                                {!props.isDisabled && <img src={ArrowRight} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt=">>"
                                                    onClick={() => hanldleLeftShift("multiSelectAdj_" + props.sequence + (index + 1), "multiSelectAdjSel_" + props.sequence + (index + 1))} />}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {!props.isDisabled && <img src={ArrowLeft} style={{ width: "55px", height: "55px", cursor: "pointer" }} alt="<<"
                                                    onClick={() => handleRightShift("multiSelectAdj_" + props.sequence + (index + 1), "multiSelectAdjSel_" + props.sequence + (index + 1))} />}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-sm-5" style={{ width: "46%", paddingLeft: "25px", paddingRight: "25px" }}>
                                    <MultiSelectList id={"multiSelectAdjSel_" + props.sequence + (index + 1)} isMultiSelect="true" size="8" style={{ marginTop: "5px", lineHeight: "1.8rem", fontSize: "18px" }} disabled={props.isDisabled}></MultiSelectList>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            </div>
    );
};

export default AdjudicationRead;
