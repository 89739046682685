import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputString from '../UI/Control/InputString';

const InputTextModal = (props) => {
    const EDCUserID = useSelector(state => state.auth.gValue01);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [filterName, setFilterName] = useState('');
    const handleShow = () => {
        if (props.filterId != -1)
        {
            setFilterName(props.filterName);
        }
        setShow(true);
    }

    const SaveValue = (event) => {
        let objFilterName = document.getElementById('txtFilterName');
        if (objFilterName.value.trim() == '') {
            alert('Filter name should not be empty.')
            objFilterName.focus();
            return false;
        }

        let objFilterOptionsModel = {
            FilterId: props.filterId,
            Name: filterName,
            StudyId: props.studyId,
            StageId: props.taskStageId,
            SourceTypeId: props.sourceTypeId,
            SearchText: props.searchText
        };

        fetch('api/ImageService/SaveFilterOptions', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objFilterOptionsModel)
        }).then(r => r.json()).then(res => {
            if (res == -1) {
                alert('Filter options not saved, Something went wrong.');
            }
            if (res == -2) {
                alert('Filter name already exists, Please try with another name.');
            }
            if (res > 0) {
                props.fillFilterOptionList(res);
                setShow(false);
            }
        })
    };

    const filterNameChangeHandler = (e) => {
        setFilterName(e.target.value);
    }

    return (
        <>
            {props.btn === "true" && <Button variant="primary" className="btn btn-success" onClick={handleShow}> {props.text}  </Button>}

            <Modal centered
                show={show}
                onHide={handleClose}
                backdrop="static">
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <InputString id="txtFilterName" placeholder="Filter name" value={filterName} onChangeHandler={filterNameChangeHandler} style={{ width: '100%' }} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-md btn-success" variant="primary" onClick={SaveValue}> Save </Button>
                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default InputTextModal;