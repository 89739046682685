import React, { useState } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputCheckbox from '../UI/Control/InputCheckbox';
import InputTextarea from '../UI/Control/InputTextarea';
import SelectList from '../UI/Control/SelectList';
import MandatoryLabel from '../UI/Control/MandatoryLabel';
import classes from './../TrialstatNoto.module.css';
import QueryDetails from './QueryDetails';

const QueryAddEditModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);    
    const [arrCommentType, setCommentType] = useState([]);
    const [arrQueryStatus, setQueryStatus] = useState([]);
    const [arrAutoResp, setArrAutoResp] = useState([]);

    const handleShow = () => {
        FillAutoQueryResponse();
        FillCommentType();
        setShow(true);
    }
    
    const FillCommentType = () => {
        fetch('api/TaskQuery/QueryCommentByRole?UserRole=' + props.UserRole)
            .then(response => response.json())
            .then(data => {
                setCommentType(data);                
                FillQueryStatus();
                document.getElementById("divQueryComment").hidden = true;                
            });
    }     
    const FillQueryStatus = () => {
        fetch('api/TaskQuery/FillQueryStatus')
            .then(response => response.json())
            .then(data => {              
                setQueryStatus(data);
            });
    }
    const onChangeHandler = (e) => {
        if (e.target.value > 0)
            document.getElementById("divQueryComment").hidden = false;
        else
            document.getElementById("divQueryComment").hidden = true;
    }   
    const UpdateQueryByISCUser = (event) => {
        var querycomment = "";
        var queryCommentType = document.getElementById("ddlCommentType").value;       
        var queryStatusId = document.getElementById("ddlQryStatus").value;
       
        if (queryCommentType < 1 && queryStatusId < 1) {
            let objdllcommenttype = document.getElementById("ddlCommentType");
            alert('Please select either comment type or query status.');
            objdllcommenttype.focus();
            return false;
        }
        if (queryCommentType > 0) {
            let objComment = document.getElementById('txtComment');
            if (objComment.value.trim() == '') {
                alert('Query Comment should not be empty.');
                objComment.focus();
                return false;
            }
            else
                querycomment = objComment.value.trim();
        }  
        let objQuery = {
            QueryId: props.queryid,
            QueryStatusId: queryStatusId,            
            CommentTypeId: queryCommentType,          
            ModifiedByUser: '',
            QueryComment: querycomment
        };       
        fetch('api/TaskQuery/UpdateQueryStatus', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objQuery)
        }).then(r => r.text()).then(res => {

            if (res == "success") {
                setShow(false);
                props.refreshsata();
            }
            else if (res == "notsaved") {
                alert('Query not added, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })
    };
    const UpdateQueryBySiteUser = (event) => {
        var querycomment = "";
        var queryCommentType = document.getElementById("ddlCommentType").value;

        if (queryCommentType < 1 ) {
            let objdllcommenttype = document.getElementById("ddlCommentType");
            alert('Please select comment type.');
            objdllcommenttype.focus();
            return false;
        }
        if (queryCommentType > 0) {
            let objComment = document.getElementById('txtComment');
            if (objComment.value.trim() == '') {
                alert('Query Comment should not be empty.');
                objComment.focus();
                return false;
            }
            else
                querycomment = objComment.value.trim();
        }
        let objQuery = {
            QueryId: props.queryid,
            QueryStatusId: -1,
            CommentTypeId: queryCommentType,
            ModifiedByUser: '',
            QueryComment: querycomment
        };
        fetch('api/TaskQuery/UpdateQueryStatus', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(objQuery)
        }).then(r => r.text()).then(res => {

            if (res == "success") {
                setShow(false);
                props.refreshsata();
            }
            else if (res == "notsaved") {
                alert('Query not added, Something went wrong.');
                return false;
            }
            else if (res.startsWith("Error:")) {
                alert(res);
                return false;
            }
        })
    };

    const FillAutoQueryResponse = () => {
        fetch('api/TaskQuery/GetAutoQueryResponse')
            .then(response => response.json())
            .then(data => {
                setArrAutoResp(data);
            });
    }

    const onAutoRespChangeHandler = (e) => {
        if (e.target.value == -1) {
            document.getElementById('txtComment').value = '';
        }
        else
        {
            //let objAutoResp = document.getElementById("ddlAutoResponse");
            var selectedText = objAutoResp.options[objAutoResp.selectedIndex].text;
            document.getElementById('txtComment').value = selectedText;
        }
    }

    return (
        <>
            <Button variant="primary" className="btn btn-success" onClick={handleShow}>{props.btnText}</Button>
            <Modal centered
                show={show}
                onHide={handleClose}               
                dialogClassName={classes.TSModalDailog_95pc} className="modal-dialog-top" style={{ "height": "auto" }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{ "text-align": "center" }}>{props.Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <div className="row mt-2">
                        <div className="col-sm-4"> 
                            {props.action != "view" &&
                                <div className="row mt-2" id="divCommentType">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3">
                                        <label>Comment Type<MandatoryLabel />:</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <SelectList id='ddlCommentType' onChangeHandler={onChangeHandler} dataSource={arrCommentType} style={{ marginBottom: "10px" }} />
                                    </div>
                                    <div className="col-sm-1" />
                                </div>
                            }
                            {props.UserRole == "ImageService" &&
                                <div className="row mt-2" id="divQueryStatus">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3">
                                        <label>Query Status<MandatoryLabel />:</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <SelectList id='ddlQryStatus' dataSource={arrQueryStatus} style={{ marginBottom: "10px" }} />
                                    </div>
                                    <div className="col-sm-1" />
                                </div>}
                         </div>
                        <div className="col-sm-8" id="divQueryComment">
                            <div class="form-group" style={{ marginTop: "10px" }}>
                                {/*<SelectList id="ddlAutoResponse" dataSource={arrAutoResp} onChangeHandler={onAutoRespChangeHandler} style={{marginBottom:"5px"}}></SelectList>*/}
                                <InputTextarea id="txtComment" rows="4" cols="30" />
                            </div>
                        </div>
                    </div>
                    <QueryDetails queryid={props.queryid} userRole={props.UserRole}/>
                </Modal.Body>
                <Modal.Footer>
                    {props.action != "view" && <Button className="btn btn-md btn-success" variant="primary"
                        onClick={props.UserRole == "ImageService" ? UpdateQueryByISCUser : UpdateQueryBySiteUser}>Save</Button>}

                    <Button className="btn btn-md btn-danger" variant="secondary" onClick={handleClose}>
                        Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default QueryAddEditModal;