import React from 'react';
import InputContainer from '../UI/Container/InputContainer';
import { Button } from 'react-bootstrap';
import MandatoryLabel from '../UI/Control/MandatoryLabel';

const NoReadAssigned = (props) => {    
    return (
        <InputContainer border='true' header={props.dataSource.ReadHeader} style={{ "text-align": "center", marginTop: "10px" }} Expended="true" >
            <div className="row" style={{ padding: "15px" }}>
            <div className="col-sm-2">
                    <strong> {props.dataSource.Label}</strong><MandatoryLabel />
            </div>
            <div className="col-sm-2" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    {props.dataSource.ReaderStatus}
            </div>
                <div className="col-sm-2">
                    <Button Id={props.dataSource.ReadID} variant="primary" className="btn btn-success" onClick={props.onAssignReader} readStage={props.readStage}> Assign Reader  </Button>
            </div>
            <div className="col-sm-1">
            </div>
        </div>
        </InputContainer>
    );
}
export default NoReadAssigned;
