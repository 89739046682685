import React from 'react';
const InputRadio = (props) => {
    return (
        <div style={(props.orientation && props.orientation === 'horizontal') && {display:"inline-flex"}}>
            <input type="radio"
                className="form-check-input"
                id={props.id}
                name={props.name}
                value={props.value}
                style={props.style}
                onChange={props.onChange}
                disabled={props.disabled}
                checked={props.checked}
            />
            <label for={props.id}> {props.text} </label>
        </div>
    );
};
export default InputRadio;
